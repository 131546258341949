import React from 'react'
import { default as MUITextfield } from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import ErrorBoundary from '../ErrorBoundary'

const useStyles = makeStyles({
  label: {
    top: -3,
    textTransform: 'capitalize',
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    color: '#9F9E9B',
    '&$focused' : {
      color: '#F3DBC7',
      top: 0
    }
  },

  outlinedInput: {
    height: props => props.height ? props.height : 'auto',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    '&$focused $notchedOutline': {
      borderColor: '#F3DBC7'
    }
  },

  textField: {
    borderRadius: 3,
    fontFamily: 'Hind',
    fontSize: props => props.fontSize ? props.fontSize : 16,
    fontWeight: 300,
    height: props => props.height ? props.height : 'auto',
    resize: 'vertical'
  },

  focused: {},

  filled: {
    top: 0
  },

  notchedOutline: {
    borderRadius: 3,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)'
  }
})

const TextField = (props) => {
  const { id, name, label, value, onChange, style, placeholder, disabled, multiline, rows, onBlur, showError, endAdornment, fullWidth, onClick } = props
  const classes = useStyles(props)
  
  return (
    <ErrorBoundary>
      <MUITextfield
        onClick={onClick}
        id={id}
        name={name}
        value={value}
        label={label}
        error={showError}
        onChange={onChange}
        placeholder={placeholder}
        className={classes.textField}
        fullWidth={fullWidth}
        multiline={multiline}
        disabled={disabled}
        rows={rows}
        style={style}
        onBlur={onBlur}
        variant='outlined'
        InputLabelProps={{
          classes: {
            root: classes.label,
            focused: classes.focused,
            shrink: classes.filled
          }
        }}
        InputProps={{
          autoComplete: 'off',
          endAdornment: endAdornment,
          classes: {
            input: classes.textField,
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          }
        }}
      />
    </ErrorBoundary>
  )
}

export default TextField
