import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import ErrorBoundary from '../ErrorBoundary'

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: props => props.value ? '#F9EFE6' : '#FAFAFA'
  },
  barColorPrimary: {
    backgroundColor: props => props.value ? '#ECB595' : '#FAFAFA'
  }
})(LinearProgress)

const ProgressBar = ({ value }) => {
  return (
    <ErrorBoundary>
      <ColorLinearProgress
        variant="determinate"
        value={value}
      />
    </ErrorBoundary>
  )
}

export default ProgressBar