import React, { useState } from 'react'
import styled from 'styled-components'
import { Close } from '../../../../../../components/Icons'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'

const ErrorContainer = styled.div`
  width: 100%
  display: ${props => props.hide ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
`;

const Text = styled.span`
  font-family: Hind;
  font-weight: ${props => props.bold ? 400 : 300};
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  margin-right: 5px;
  cursor: ${props => props.bold ? 'pointer' : 'initial'}
`

const UploadError = ({ error, image, text, onHover}) => {
  const [hide, setHide] = useState(false)

  return (
    <ErrorBoundary>
      <ErrorContainer hide={hide}>
        <Close color='#F3DBC7' scale={3} onClick={() => setHide(true)} style={{marginRight: 10, cursor: 'pointer'}} />
        <Text>{text}</Text>
        <Text
          
          bold
          onMouseEnter={() => onHover(image.preview)}
          onMouseLeave={() => onHover(null)}
        >
          {image.original_filename || image.name}
        </Text>
        <Text>{error}</Text>
      </ErrorContainer>
    </ErrorBoundary>
  )
}

export default UploadError
