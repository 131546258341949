import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'
import Modal from '../../../../../../components/Modal'


const VideoModalContainer = styled.div`
  width: 600px;
  padding: 30px;
`

const Video = styled.iframe`
  width: 600px;
  height: 338px;
`




const VideoModal = ({ video, open, handleClose }) => {
  return (
    <ErrorBoundary>
      <Modal 
        open={open}
        handleClose={handleClose}
      >
        <VideoModalContainer>
          <Video width="600" height="338" src={get(video, 'embed', '').replace('http://', 'https://')} />
        </VideoModalContainer>
      </Modal>
    </ErrorBoundary>
  )
}

export default VideoModal