import React, { useState } from 'react'
import styled from 'styled-components'
import { map, isEmpty } from 'lodash'
import { Formik } from 'formik'
import Add from '@stylemepretty/smp-react-components/Icons/Plus'
import Video from './components/Video'
import TextField from '../../../../components/TextField'
import VideoModal from './components/VideoModal'


const VideoSectionContainer = styled.div`
  width: 100%;
  margin-top: 42px;
`;

const VideosHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: capitalize;
  margin: 20px 0 30px;
`

const Form = styled.form`
  width: 750px;
  display: flex;
  flex-direction: column;
`

const VideoField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const VideosContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
	flex-direction: row;
  width: 735px;
  margin-left: -15px;
  align-items: center;
`

const Error = styled.div`
  margin-right: 30px;
  margin-top: 5px;
  font-family: Hind;
  font-size: 16px;
  font-weight: 300;
  color: #f44336;
  display: flex;
  align-self: flex-end;
`



const Videos = ({ videos, addVideo, deleteVideo, videoError }) => {
  const [open, setOpen] = useState(false)
  const [clickedVideo, setClickedVideo] = useState(null)

  const validate = values => {
    let errors = {}
    let yt = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    let vimeo = /(?:.*vimeo.com\/)(?:video\/)?([0-9]+)/
    let match = yt.exec(values.video) || vimeo.exec(values.video)
    if (!match) errors.video = 'Invalid video url'
    return errors
  }
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }
  return (
    <VideoSectionContainer>
      <VideosHeader>Video</VideosHeader>
      <Formik
        initialValues={{ video: '' }}
        onSubmit={(values, { resetForm, errors }) => {
          addVideo(values.video)
        }}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form onKeyDown={onKeyDown}>
            <VideoField>
              <TextField
                name='video'
                label='Youtube or Vimeo Link'
                onChange={handleChange}
                height={46}
                style={{ width: 720 }}
                value={values.video}
                showError={!isEmpty(errors) ? true : false}
              />
              <Add
                onClick={handleSubmit}
                style={{cursor: 'pointer'}}
              />
            </VideoField>
            <Error>{videoError && 'There was an error embedding your video. Please Check the privacy settings and ensure the video is public.'}</Error>
          </Form>
          
        )}
      </Formik>
      <VideosContainer>
        {map(videos, (video, index) => (
          <Video 
            onClick={() => { 
              setOpen(true)
              setClickedVideo(video)
            }}
            key={index} 
            index={index} 
            video={video} 
            style={{backgroundImage: `url(${video.thumb})`}} 
            deleteVideo={() => deleteVideo(video.id)} 
          />
        ))}
      </VideosContainer>
      {open && (
        <VideoModal 
          open={open} 
          handleClose={() => {
            setOpen(false)
            setClickedVideo(null)
          }}
          video={clickedVideo} 
        />
      )}
    </VideoSectionContainer>
  )
}

export default Videos
