import React from 'react'
import styled from 'styled-components'
import { map, get, isNull, find } from 'lodash'
import { Formik } from 'formik'
import TextField from '../../../../../../components/TextField'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'
import RichText from '../../../../../../components/RichText'
import { defaultSectionData } from '../../constants'

const InfoContainer = styled.div`
  width: 100%;
`

const InfoHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const InfoDetailsContainer = styled.div`
  padding: 20px;
  background-color: rgb(246, 229, 213);
  border-radius: 5px;
  margin-top: 20px;
`

const InfoDetails = styled.div`
  font-family: hind;
  font-size: 14px;
  color: #55575A;
  margin-top: 20px;
`

const InfoDetailsList = styled.ul`
`

const InfoDetailsListItem = styled.li`
  font-family: hind;
  font-size: 14px;
  color: #55575A;
`

const InfoFields = styled.div`
  width: 100%;
  margin-top: 30px;
`

const SectionContainer = styled.div`
  border: 3px solid #f3dbc7;
  margin-bottom: 50px;
  min-height: 50px;
`

const SectionHeaderContainer = styled.div`
  text-align: center;
  margin: -17px auto;
`

const SectionHeader = styled.div`
  background-color: white;
  text-align: center;
  display: inline-block;
  padding: 0 50px;
  font-family: hind;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
`

const SectionFields = styled.div`
  padding: 40px 40px 20px;
`

const FieldName = styled.div`
  font-family: hind;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
`

const getInitialValues = (additionaInfo) => {
  let defaults = {}
  map(get(additionaInfo, 'sections'), (section) => map(get(section, 'fields'), (field) => defaults[get(field, 'name')] = get(field, 'text')))
  return defaults
}

const updateInfoForSave = (additionalInfo, sectionIndex, fieldIndex, value, saveSubmission) => {
  let savedValue = value
  if (value === '<p><br></p>') savedValue = '' 
  let info = additionalInfo
  info.sections[sectionIndex].fields[fieldIndex].text = savedValue
  saveSubmission(info)
}

const AdditionalInfoDefault = ({ additionalInfo, saveSubmission, isAdmin, credits, isUserView, submission }) => (
  <ErrorBoundary>
    <InfoContainer>
      <InfoHeader>{get(find(defaultSectionData, ['type', submission.type]), 'title')}</InfoHeader>
      <InfoDetails>{get(find(defaultSectionData, ['type', submission.type]), 'subtitle')}</InfoDetails>
      {(!isAdmin || isUserView) && (
        <InfoDetailsContainer>
          <InfoDetails><b style={{ fontSize: 20 }}>PLEASE READ CAREFULLY |</b> Note we will not be able to move forward with publishing if prompts are not filled out correctly!</InfoDetails>
          <InfoDetails><b style={{ fontSize: 20 }}>DO:</b></InfoDetails>
          <InfoDetailsList>
            <InfoDetailsListItem>{`Write in full, complete sentences as this exact text will be published on the blog! The more details the better. We'd like to see at LEAST 2-3 sentences per prompt (besides 'colors', you can just list them!)`}</InfoDetailsListItem>
            <InfoDetailsListItem>Add any vendors above that you mention in your description.</InfoDetailsListItem>
            <InfoDetailsListItem>{`Write about the details that are relevant to the prompt subject (i.e. Food & Drinks - talk about the signature drinks and menu, not the floral details and decor)`}</InfoDetailsListItem>
            <InfoDetailsListItem>Respond to all required prompts.</InfoDetailsListItem>
          </InfoDetailsList>
          <InfoDetails><b style={{ fontSize: 20 }}>DON'T:</b></InfoDetails>
          <InfoDetailsList>
            <InfoDetailsListItem>Use one word answers to respond to a prompt or simply note a vendor.</InfoDetailsListItem>
            <InfoDetailsListItem>Copy and paste the same response from one prompt to another.</InfoDetailsListItem>
            <InfoDetailsListItem>Create your own prompts/questions</InfoDetailsListItem>
          </InfoDetailsList>
        </InfoDetailsContainer>
      )}
      <InfoFields>
        <Formik initialValues={getInitialValues(additionalInfo)}>
          {({ values, handleChange, setFieldValue }) => (
            <form>
              {map(get(additionalInfo, 'sections'), (section, index) => {
                return (
                  <SectionContainer key={index}>
                    <SectionHeaderContainer>
                      <SectionHeader>{section.name}</SectionHeader>
                    </SectionHeaderContainer>
                    <SectionFields>
                      {map(section.fields, (field, i) => {
                        if (isAdmin) {
                          return (
                            <div key={i}>
                              <FieldName>{`${field.name}   (${field.type})`}</FieldName>
                              <RichText
                                key={i}
                                style={{ marginBottom: 72, height: 100 }}
                                id={`${index}-${i}`}
                                value={values[field.name]}
                                name={field.name}
                                placeholder={field.name}
                                onChange={handleChange}
                                onBlur={(type, value) => {
                                  if (type !== 'silent' && value !== get(additionalInfo, `sections[${index}].fields[${i}].text`, '')) {
                                    setFieldValue(field.name, value)
                                    updateInfoForSave(additionalInfo, index, i, value, saveSubmission)
                                  } 
                                }}
                                credits={credits}
                              />
                            </div>
                          )
                        }
                        return (
                          <TextField
                            key={i}
                            value={values[field.name].replace(/<[^>]*>?/gm, '')}
                            fullWidth
                            name={field.name}
                            label={`${field.name}   (${field.type})`}
                            onChange={handleChange}
                            onBlur={(e) => {
                              if (e.target.value !== get(additionalInfo, `sections[${index}].fields[${i}].text`, '')) {
                                updateInfoForSave(additionalInfo, index, i, e.target.value, saveSubmission)
                              } 
                            }}
                            style={{marginBottom: 20}}
                            multiline
                            rows={3}
                            disabled={!isNull(submission.gallery_id)}
                          />
                        )
                      })}
                    </SectionFields>
                  </SectionContainer>
                )
              })}
            </form>
          )}
        </Formik>
      </InfoFields>
    </InfoContainer>
  </ErrorBoundary>
)

export default AdditionalInfoDefault