import React from 'react'

function scaleProps (n) {
  return {
    width: n * 5,
    height: n * 5
  }
}

const Close = ({ color, scale, style, className, onClick }) => (
  <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Tags" transform="translate(-1204.000000, -684.000000)" fill={color}>
        <g id="Group-11" transform="translate(1120.000000, 673.000000)">
          <g id="add" transform="translate(88.606602, 15.606602) rotate(-45.000000) translate(-88.606602, -15.606602) translate(81.106602, 8.106602)">
            <path d="M11.1248772,3.15014733 C11.3250737,2.94995089 11.6496562,2.94995089 11.8498527,3.15014733 C12.0500491,3.35034377 12.0500491,3.67492633 11.8498527,3.87512278 L8.22497544,7.5 L11.8498527,11.1248772 C12.0500491,11.3250737 12.0500491,11.6496562 11.8498527,11.8498527 C11.6496562,12.0500491 11.3250737,12.0500491 11.1248772,11.8498527 L7.5,8.22497544 L3.87512278,11.8498527 C3.67492633,12.0500491 3.35034377,12.0500491 3.15014733,11.8498527 C2.94995089,11.6496562 2.94995089,11.3250737 3.15014733,11.1248772 L6.77502456,7.5 L3.15014733,3.87512278 C2.94995089,3.67492633 2.94995089,3.35034377 3.15014733,3.15014733 C3.35034377,2.94995089 3.67492633,2.94995089 3.87512278,3.15014733 L7.5,6.77502456 L11.1248772,3.15014733 Z" id="Path" transform="translate(7.500000, 7.500000) rotate(-45.000000) translate(-7.500000, -7.500000) "></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Close