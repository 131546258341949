import React from 'react'

function scaleProps (n) {
  return {
    width: n * 12,
    height: n * 12
  }
}

const Delete = ({ color, scale, style, className, onClick }) => (
  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g>
        <g>
          <circle fill={color} cx='12' cy='12' r='12' />
          <g transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)' fill='#FFFFFF'>
            <path d='M11.8665357,3.36015715 C12.0800786,3.14661428 12.4263,3.14661428 12.6398428,3.36015715 C12.8533857,3.57370002 12.8533857,3.91992142 12.6398428,4.13346429 L8.77330714,8 L12.6398428,11.8665357 C12.8533857,12.0800786 12.8533857,12.4263 12.6398428,12.6398428 C12.4263,12.8533857 12.0800786,12.8533857 11.8665357,12.6398428 L8,8.77330714 L4.13346429,12.6398428 C3.91992142,12.8533857 3.57370002,12.8533857 3.36015715,12.6398428 C3.14661428,12.4263 3.14661428,12.0800786 3.36015715,11.8665357 L7.22669286,8 L3.36015715,4.13346429 C3.14661428,3.91992142 3.14661428,3.57370002 3.36015715,3.36015715 C3.57370002,3.14661428 3.91992142,3.14661428 4.13346429,3.36015715 L8,7.22669286 L11.8665357,3.36015715 Z' transform='translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000)' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Delete