import React from 'react'
import styled from 'styled-components'
import TextField from '../../../../../../components/TextField'
import RichText from '../../../../../../components/RichText'
import { Trash } from '../../../../../../components/Icons'

const CustomFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  position: relative;
`

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 45px;
`

const updateField = (additionalInfo, sectionIndex, fieldIndex, value, saveSubmission, type) => {
  let info = additionalInfo
  info.sections[sectionIndex].fields[fieldIndex][type] = value
  saveSubmission(info)
}

const removeField = (additionalInfo, sectionIndex, fieldIndex, saveSubmission) => {
  let info = additionalInfo
  info.sections[sectionIndex].fields.splice(fieldIndex, 1)
  saveSubmission(info)
}

const CustomField = ({ additionalInfo, sectionIndex, fieldIndex, saveSubmission, dragHandle, values, handleChange, credits }) => {
  return (
    <CustomFieldContainer>
      <TextField
        height={45}
        style={{ width: '40%' }}
        value={values[`field-${fieldIndex}-name`] || ''}
        name={`field-${fieldIndex}-name`}
        label='Field Name'
        onChange={handleChange}
        onBlur={(e) => { 
          if (e.target.value !== additionalInfo.sections[sectionIndex].fields[fieldIndex].name) {
            updateField(additionalInfo, sectionIndex, fieldIndex, e.target.value, saveSubmission, 'name')
          }
        }}
      />
      <RichText
        id={`${sectionIndex}-${fieldIndex}`}
        style={{ width: '50%', flexGrow: 1, margin: '0 10px 42px 20px', height: 100 }}
        value={values[`field-${fieldIndex}-text`] || ''}
        name={`field-${fieldIndex}-text`}
        label='Field Name'
        onChange={handleChange}
        onBlur={(type, value) => {
          if (type !== 'silent' && value !== additionalInfo.sections[sectionIndex].fields[fieldIndex].text) {
            updateField(additionalInfo, sectionIndex, fieldIndex, value, saveSubmission, 'text')
          }
        }}
        credits={credits}
      />
      <ControlsContainer>
        {dragHandle}
        <Trash 
          style={{ height: 31, cursor: 'pointer' }}
          scale={1}
          color='#A0A0A0' 
          onClick={() => removeField(additionalInfo, sectionIndex, fieldIndex, saveSubmission)}
        />
      </ControlsContainer>
    </CustomFieldContainer>
  )
}

export default CustomField



