import React from 'react'
import { default as MUICheckbox } from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from '../ErrorBoundary'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  }
})

const Checkbox = ({ checked, color, onChange, name }) => {
  const classes = useStyles()

  return (
    <ErrorBoundary>
      <MUICheckbox
        classes={{
          root: classes.root,
          checked: classes.checked
        }}
        disableRipple
        name={name}
        color='default'
        checked={checked}
        onChange={onChange}
      />
    </ErrorBoundary>
  )
}

export default Checkbox