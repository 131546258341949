import React, { Component } from 'react'
import canUseDom from 'can-use-dom'
import { Header, Images } from './components'
import { AdminSidebar } from './components/Sidebar'
import { isValid, lastSaved } from './utils'
import ErrorBoundary from '../../components/ErrorBoundary'
import { Typography } from '@material-ui/core';
import { Formik } from 'formik'
import { map, includes, isUndefined, find } from 'lodash'
import TextArea from '@stylemepretty/smp-react-components/textarea'
import SubmissionThemeProvider from '@stylemepretty/smp-react-components/submissionThemeProvider'

if (canUseDom) require('./single-submission.scss')

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUserView: !this.props.isAdmin
    }
    this.changeView = this.changeView.bind(this)
  }

  changeView () {
    this.setState({ isUserView: !this.state.isUserView })
  }

  setFavoritedImages () {
    let images = map(this.props.images, (image, index) => {
      image.isFavorite = includes(this.props.instagramFavorites, image.id)
      image.igType = !isUndefined(find(this.props.instagramTypes, { object_id: image.id })) ? find(this.props.instagramTypes, { object_id: image.id }).meta_value : ''
      return image
    })
    return images
  }

  render () {
    const { 
      user,
      saveSubmission,
      isLoading,
      submission,
      credits,
      images,
      options,
      isAdmin,
      downloadSubmission,
      emailSubmitter,
      updateSubmissionImageIsFavorite,
      updateSubmissionImageMetadata,
      updateSubmissionImageDescription
    } = this.props

    return (
      <ErrorBoundary>
        <SubmissionThemeProvider>
          <div className='single-submission'>
            {!isLoading ? (
              <div>
                <div className='single-submission-header-container'>
                  <Header
                    submission={submission}
                    isValid={isValid(submission, credits, images)}
                    lastSaved={lastSaved(submission.updated)}
                    isAdmin={isAdmin}
                    isUserView={this.state.isUserView}
                    userViewClick={this.changeView}
                    deleteSubmission={this.props.deleteSubmission}
                    downloadClick={() => downloadSubmission(submission.id)}
                    images={images}
                    user={user}
                    progress={this.props.loadingValue}
                  />
                </div>
                <div className='single-submission-body'>
                  <div className='single-submission-body-container'>
                    <div className='single-submission-general-info-container'>
                      <Formik
                        initialValues={{description: submission.description || ''}}
                      >
                        {({values, handleChange}) => (
                          <form>
                            <Typography variant="h6">Instagram Post goals</Typography>
                            <TextArea 
                              value={values.description}
                              id="goals"
                              name="description"
                              label="Goals"
                              rows={5}
                              fullWidth
                              onChange={handleChange}
                              onBlur={(e) => saveSubmission(e.target.name, e.target.value)}
                            />
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className='single-submission-images-section'>
                      <Images
                        upload={this.props.uploadImageToS3}
                        text="Drag and drop to upload or click to select files"
                        onDrop={this.props.onDrop}
                        files={this.props.acceptedFiles}
                        denied={this.props.deniedFiles}
                        images={this.setFavoritedImages()}
                        submissionId={submission.id}
                        deleteImage={this.props.deleteImage}
                        updateImageOrder={this.props.updateImageOrder}
                        user={user}
                        instagram
                        showControls={isAdmin && !this.state.isUserView}
                        updateSubmissionImageIsFavorite={updateSubmissionImageIsFavorite}
                        updateSubmissionImageMetadata={updateSubmissionImageMetadata}
                        updateSubmissionImageDescription={updateSubmissionImageDescription}
                      />
                    </div>
                  </div>
                  <div className='single-submission-sidebar'>
                    {isAdmin && !this.state.isUserView &&
                      <AdminSidebar 
                        submission={submission}
                        options={options}
                        saveSubmission={saveSubmission}
                        notes={this.props.notes}
                        addNote={this.props.addNote}
                        colors={this.props.colors}
                        styles={this.props.styles}
                        settings={this.props.settings}
                        fieldHeight={46}
                        importOnClick={this.props.importSubmission}
                        emailSubmitter={emailSubmitter}
                      />
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </SubmissionThemeProvider>
      </ErrorBoundary>
    )
  }
}


