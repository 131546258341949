import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import Button from '../../../../components/Button'
import HeaderText from '../../../../components/HeaderText'

const HeaderContainer = styled.div`
  width: 1220px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const SubmitButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  font-size: 14px;
  color: #404040;
  margin-top: 5px;
  margin-left: 20px;
  font-family: Hind;
`


const Header = ({ setOpen, vendorUser, canQuickSubmit, totalQuickSubmissions }) => {
  return (      
    <HeaderContainer>
      <HeaderText text='Submissions' />
      <SubmitButtonsContainer>
        <SubmitButtonContainer>
          <Button width={180} text='New Submission' onClick={() => setOpen('new')} />
          {canQuickSubmit && (
            <ButtonContainer>
              <Button style={{ marginLeft: 20 }} width={200} text='New Quick Submission' onClick={() => setOpen('quick')} />
              {totalQuickSubmissions < 10 && <Text>{`(${totalQuickSubmissions} Remaining)`}</Text>}
            </ButtonContainer>
          )}
        </SubmitButtonContainer>
        <SubmitButtonContainer style={{marginTop: 20}}>
          {get(vendorUser, 'is_active', 0) > 0 && get(vendorUser, 'guaranteed_posts', 0) > 0 && <Button width={250} text='LBB Purchased Blog Post' onClick={() => setOpen('guaranteed')} />}
        </SubmitButtonContainer>
      </SubmitButtonsContainer>
    </HeaderContainer>
  )
}

export default Header
