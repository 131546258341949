import React, { Component } from 'react'
import { filter, map, get, omit, each, find, isUndefined, findIndex, includes, isNull, isEmpty } from 'lodash'
import arrayMove from 'array-move'
import axios from 'axios'
import AWS from 'aws-sdk'
import { eachLimit } from 'async'
import moment from 'moment'
import MD5 from 'md5-es'
import { isAdmin, getImageWidth } from '../../utils'
import SingleSubmission from './single-submission'
import SingleSubmissionInstagram from './single-submission-instagram'
import { verifyImage, uniqueFilename } from './utils'
import axiosInstance from '../../config/axiosResolver'
import ConfigResolver from '../../config/ConfigResolver'
import { statusToEmail } from '../../constants'
import { defaultSectionData } from './components/AdditionalInfo/constants'

const config = ConfigResolver.config()

class SingleSubmissionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadingValue: 0,
      isValid: true,
      isAdmin: isAdmin(this.props.user),
      message: null,
      variant: null,
      submission: {},
      additionalInfo: {},
      galleryImages: [],
      credits: [],
      images: [],
      videos: [],
      videoError: false,
      gallery: {},
      galleryVendor: {},
      notes: [],
      colors: [],
      settings: [],
      styles: [],
      options: [],
      acceptedFiles: [],
      deniedFiles: [],
      instagramFavorites: [],
      instagramTypes: [],
      credentials: {},
      vendor: { name: '', type: '', url: '', instagram: '', email: '' },
      venue: { name: '', type: '', url: '', instagram: '', email: '' },
      path: 'https://sharp.stylemepretty.com',
      isLbb: false,
      isProfile: false,
      post: [],
      nextSubmission: null,
      statistics: {},
      workshopData: {}
    }

    this.id = this.props.match.params.id
    this.deleteCredit = this.deleteCredit.bind(this)
    this.addCredit = this.addCredit.bind(this)
    this.addVideo = this.addVideo.bind(this)
    this.saveSubmission = this.saveSubmission.bind(this)
    this.saveSubmissionDetails = this.saveSubmissionDetails.bind(this)
    this.refreshPreview = this.refreshPreview.bind(this)
    this.deleteSubmission = this.deleteSubmission.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.addNote = this.addNote.bind(this)
    this.submitForReview = this.submitForReview.bind(this)
    this.importSubmission = this.importSubmission.bind(this)
    this.createPost = this.createPost.bind(this)
    this.updatePost = this.updatePost.bind(this)
    this.emailSubmitter = this.emailSubmitter.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteAllImages = this.deleteAllImages.bind(this)
    this.deleteVideo = this.deleteVideo.bind(this)
    this.updateImageOrder = this.updateImageOrder.bind(this)
    this.updateSubmissionImageIsFavorite = this.updateSubmissionImageIsFavorite.bind(this)
    this.updateSubmissionImageDescription = this.updateSubmissionImageDescription.bind(this)
    this.updateImageCredit = this.updateImageCredit.bind(this)
    this.acceptSocial = this.acceptSocial.bind(this)
    this.declineSocial = this.declineSocial.bind(this)
  }

  componentDidMount () {
    const hash = get(this.props, 'match.params.publicHash', '')
    const isReviewing = window.location.search === '?review=review'
    const isQuickReviewing = window.location.search === '?review=quick'
    this.startTimer()
    Promise.all([
      axiosInstance.get(`/submissions/${this.id}/`),
      this.id > 358705 ? axios.get(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/get-additional-info`) : null,
      axiosInstance.get(`/submissions/${this.id}/credits/`),
      axiosInstance.get(`/submissions/${this.id}/images/`),
      axiosInstance.get(`/submissions/${this.id}/videos/`),
      axiosInstance.get(`/submissions/${this.id}/notes/`),
      config.smpCognito ? axios.get(`https://www.stylemepretty.com/api/v2/submissions/credentials/${this.id}${hash ? '/public/' + hash : ''}`) : axiosInstance.get(`/submissions/${this.id}/cognito/`),
      axiosInstance.get(`/term-taxonomies/?taxonomy=color`),
      axiosInstance.get(`/term-taxonomies/?taxonomy=style`),
      axiosInstance.get(`/term-taxonomies/?taxonomy=setting`),
      axiosInstance.get(`/submission-options/`),
      axios.get(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/get-post`),
      axios.get(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/workshop-data`)
    ])
      .then(([submission, additionalInfo, credits, images, videos, notes, credentials, colors, styles, settings, options, post, workshopData]) => {
        this.setState({ 
          submission: this.formatSubmission(submission.data),
          additionalInfo: this.id > 358705 ? additionalInfo.data.data : {},
          credits: credits.data || [],
          images: images.data,
          videos: videos.data,
          notes: notes.data,
          credentials: config.smpCognito ? credentials.data.data.credentials : credentials.data,
          colors: colors.data || [],
          styles: styles.data || [],
          settings: settings.data || [],
          options: options.data,
          post: post.data.data || [],
          workshopData: workshopData.data.data || {}
        })
      })
      .finally(() => {
        this.getStatistics()
        this.setState({ isLoading: false, path: this.state.submission.image_server ? `https://${this.state.submission.image_server}.stylemepretty.com` : this.state.path })
        if (this.state.credits.length) this.getIsLBB()
        if (this.state.submission.status === 'collection') this.getGallery(this.state.submission.gallery_id)
        if (this.state.submission.type === 'lbb-instagram') this.getFavorites(this.state.images)
        if (isReviewing || isQuickReviewing) this.getNextSubmission(this.state.submission.id, isReviewing ? 'review' : 'quick')
        this.getSubmitterIsLbb()
        if (this.state.submission.gallery_id) {
          axios.get(`https://www.stylemepretty.com/api/v2/gallery/${this.state.submission.gallery_id}`)
            .then((res) => {
              this.setState({ galleryImages: res.data.data })
            })
        }
        if (isEmpty(this.state.additionalInfo) && !this.state.isAdmin && this.id > 358705 && this.state.submission.type !== 'other') {
          const defaults = find(defaultSectionData, ['type', this.state.submission.type])
          this.saveSubmissionDetails({ type: 'default', leadImage: null, sections: get(defaults, 'sections', []), title: get(defaults, 'title', ''), subtitle: get(defaults, 'subtitle', '') })
        }
      })
      .catch(() => {
        if(get(this.props, 'user.email')) this.props.history.push(`/submissions/dashboard/${this.props.user.email}`)
        else window.location.href = `${config.siteUrl}/login?redirect_to=/submissions/&msg=You+must+have+an+account+to+create+a+submission.`
      })
  }

  refreshPreview () {
    axios.get(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/get-additional-info`)
      .then((res) => {
        this.setState({ additionalInfo: res.data.data })
      })
  }

  getNextSubmission (id, type) {
    axios.get(`https://www.stylemepretty.com/api/v2/submissions/${id}/get-next-submission/${type}`)
      .then((res) => {
        this.setState({ nextSubmission: res.data.data })
      })
  }

  startTimer () {
    this.timer = setInterval(() => {
      config.smpCognito ? axios.get(`https://www.stylemepretty.com/api/v2/submissions/credentials/${this.id}`) : axiosInstance.get(`/submissions/${this.id}/cognito/`)
        .then((res) => {
          this.setState({ credentials: config.smpCognito ? res.data.data.credentials : res.data })
        })
    }, 1800000)
  }

  getSubmitterIsLbb () {
    axios.get(`https://www.stylemepretty.com/api/v2/vendor/user/${this.state.submission.submit_email}`)
      .then((res) => {
        if (get(res, 'data.data.vendor_profile.is_active', 0) === '1') this.setState({ isProfile: true })
        if (get(res, 'data.data.lbb', false) === '1' && get(res, 'data.data.is_active', false) === '1') this.setState({ isLbb: true })
      })
  }

  getFavorites (images) {
    map(images, (image) => {
      axiosInstance.get(`/favorites/?object_type=lbb_instagram&object_id=${image.id}&user_id=${image.id}`)
        .then((result) => {
          axiosInstance.get(`/metadata/?object_type=image&object_id=${image.id}&meta_key=instagram_type`)
            .then((res) => {
              if (result.data.count) this.setState({ instagramFavorites: [image.id, ...this.state.instagramFavorites]})
              if (res.data.results.length) this.setState({ instagramTypes: [res.data.results[0], ...this.state.instagramTypes] })
            })
        })
    })
  }

  declineSocial () {
    this.saveSubmission('status', 'social-denied')
  }

  acceptSocial () {
    this.saveSubmission('status', 'social')
  }

  getGallery (id) {
    axios
      .get(`https://www.stylemepretty.com/api/portfolio-gallery/${id}`)
      .then((res) => {
        this.setState({ gallery: res.data })
        this.getVendor(get(res, 'data.vendor_id'))
          .then((res) => {
            this.setState({ galleryVendor: res.data })
          })
      })
  }

  getIsLBB (newCredit = false, newLocation = false) {
    let credits = this.state.credits
    if(newCredit) {
      if (newCredit.vendor) {
        axios
          .get(`https://www.stylemepretty.com/api/v2/vendor/${newCredit.vendor}/submission-credit`)
          .then((res) => {
            if(get(res, 'data.data.lbb')) credits[newLocation].isLbb = true
            else credits[newLocation].isLbb = false
            if(get(res, 'data.data.profile')) credits[newLocation].isProfile = true
            else credits[newLocation].isProfile = false
            if(get(res, 'data.data.instagram', '')) credits[newLocation].instagram = get(res, 'data.data.instagram', '')
            if(get(res, 'data.email', '')) credits[newLocation].email = get(res, 'data.email', '')
          })
          .finally(() => {
            this.setState({ credits: credits })
          })
      }
    } else {
      map(credits, (credit, key) => {
        if (credit.vendor) {
          axios
            .get(`https://www.stylemepretty.com/api/v2/vendor/${credit.vendor}/submission-credit`)
            .then((res) => {
              if(get(res, 'data.data.lbb')) credits[key].isLbb = true
              else credits[key].isLbb = false
              if(get(res, 'data.data.profile')) credits[key].isProfile = true
              else credits[key].isProfile = false
              if(get(res, 'data.data.instagram', '')) credits[key].instagram = get(res, 'data.data.instagram', '')
              if(get(res, 'data.email', '')) credits[key].email = get(res, 'data.email', '')
            })
            .finally(() => {
              this.setState({ credits: credits })
            })
        }
      })
    }
  }

  getStatistics () {
    axios.get(`https://www.stylemepretty.com/api/v2/submissions/vendor/${this.state.submission.submit_email}/submissions-data`)
      .then((res) => this.setState({ statistics: res.data.data }))
      .catch((err) => console.log(err))
  }

  getVendor (vendorId) {
    return axios
      .get(`https://www.stylemepretty.com/api/vendor/dashboard/${vendorId}`)
  }
  
  formatSubmission (submission) {
    const fields = ['order', 'credits', 'colors', 'settings', 'styles']
    let sub = submission
    if(!submission.json) sub.json = '{}'
    each(fields, (field) => {
      if(!submission[field]) sub[field] = []
    })
    return sub
    
  }

  onSortEnd = ({oldIndex, newIndex, collection}) => {
    let vendors = filter(this.state.credits, ['category', 't:vendor'])
    let venues = filter(this.state.credits, ['category', 't:venue'])
    if(collection === 0) vendors = arrayMove(vendors, oldIndex, newIndex)
    else venues = arrayMove(venues, oldIndex, newIndex)
    Array.prototype.push.apply(vendors, venues)
    this.setState({ credits:  vendors}, () => {
      this.updateCreditOrder(this.props.match.params.id)
    })
  }

  updateCreditOrder (submissionId) {
    axiosInstance
      .put(`/submissions/${submissionId}/`, { 
        ...this.state.submission,
        credits: map(this.state.credits, 'id')
      })
  }

  updateImageOrder (oldIndex, newIndex) {
    if (oldIndex !== newIndex) {
      let images = arrayMove(this.state.images, oldIndex, newIndex)
      this.setState({ images: images })
      axiosInstance
        .put(`/submissions/${this.id}/`, {
          ...this.state.submission,
          order: map(images, 'id')
        })
        .then(() => {
          if (this.state.submission.gallery_id && this.state.submission.status === 'collection') {
            this.updateGalleryOrder(this.state.gallery, images)
            this.setCoverPhoto(this.state.gallery, images)
          }
        })
        .finally(() => {
          this.saveGallery()
        })
    }
  }

  updateImageCredit (values, imageId) {
    const image = find(this.state.images, { 'id': imageId })
    if (!isUndefined(image)) {
      axiosInstance
        .put(`/submission-images/${imageId}/`, {
          ...image,
          credit_name: values.vendorName,
          credit_url: values.vendorUrl
        })
        .then((res) => {
          const index = findIndex(this.state.images, { id: imageId })
          let images = this.state.images
          images[index] = res.data
          this.setState({ images: images })
        })
    }
  }

  setCoverPhoto(gallery, images) {
    axios
      .put(`https://www.stylemepretty.com/api/vendor/${gallery.vendor_id}/gallery/${this.state.submission.gallery_id}/set-main`, {
        id: find(gallery.images, { filename: images[0].filename }).id
      })
  }

  updateGalleryOrder (gallery, images) {
    axios
      .put(`https://www.stylemepretty.com/api/portfolio-gallery/${this.state.submission.gallery_id}/images`, {
        images: map(get(gallery, 'images'), (image, i) => {
          return { 
            id: image.id,
            position: findIndex(images, { filename: image.filename })
          }
        })
      })
  }

  deleteCredit (values) {
    if (this.state.submission.status === 'collection') {
      this.deleteGalleryCredit(values)
    } else {
      axiosInstance
        .delete(`/submission-credits/${values.id}/`)
        .then(() => {
          let credits = filter(this.state.credits, function(c) { return get(c, 'id') !== values.id})
          this.setState({ credits: credits }, () => {
            this.updateCreditOrder(this.props.match.params.id)
          })
        })
    }
  }

  deleteGalleryCredit (values) {
    axios
      .delete(`https://www.stylemepretty.com/api/portfolio-credit/${values.id}`)
      .then(() => {
        let credits = filter(this.state.credits, function(c) { return get(c, 'id') !== values.id})
        this.saveGallery()
        this.setState({ credits: credits }, () => {
          this.updateCreditOrder(this.props.match.params.id)
        })
      })
  }

  addCredit (credit) {
    const type = credit.category.slice(2)
    this.setState({ [type]: { name: '', type: '', url: '', instagram: '', email: '' } })
    let c = credit
    let url = get(c, 'url')
    if(!url.startsWith('http://') && !url.startsWith('https://') && url.length !== 0) url = `http://${get(c, 'url')}`
    c.url = url
    if (this.state.submission.status === 'collection') {
      this.addCreditToGallery(c)
    } else {
      axiosInstance
        .post(`/submissions/${c.submission}/credits/`, {
          ...omit(c, ['id']) 
        })
        .then((result) => {
          let credits = this.state.credits
          credits.push(result.data)
          this.setState({ credits: credits }, () => {
            this.updateCreditOrder(c.submission)
            this.getIsLBB(c, credits.length - 1)
            axios.post(`https://www.stylemepretty.com/api/v2/submissions/vendor/${result.data.id}`, { instagram : get(c, 'instagram', ''), email: get(c, 'email', '') })
          })
        })
    }
  }
  
  addCreditToGallery (credit) {
    axios
      .post(`https://www.stylemepretty.com/api/portfolio-gallery/${this.state.submission.gallery_id}/credits`, {
        name: credit.name,
        type: credit.type,
        url: get(credit, 'url', ''),
        url_path_level: (get(credit, 'url', '').replace(/(^\w+:|^)\/\//, '').split('/').length - 1).toString()
      })
      .then((result) => {
        result.data.id = result.data.cid
        let credits = this.state.credits
        credits.push(result.data)
        this.saveGallery()
        this.setState({ credits: credits }, () => {
          this.updateCreditOrder(credit.submission)
        })
      })
  }

  submitForReview () {
    if(this.state.isValid) {
      axiosInstance
        .put(`/submissions/${this.props.match.params.id}/`, { 
          ...this.state.submission,
          status: this.state.submission.status === 'accepted-quick' ? 'completed-quick' : 'review',
          submit_date: moment().format('YYYY-MM-DD HH:mm:ss')
        })
        .then((result) => {
          this.setState({ submission: result.data })
        })
    }
  }

  importSubmission (createPost) {
    this.setState({ submission: { ...this.state.submission, isImporting: true }})
    
    if (this.props.match.params.id > 358705) {
      axios
        .post(`https://www.stylemepretty.com/wp-admin/admin-ajax.php?action=smp_import_submission&id=${this.props.match.params.id}`, { sub_order: map(this.state.images, 'id') })
        .then((res) => {
          this.setState({ submission: { ...this.state.submission, ...res.data }}, () => {
            if (!this.state.submission.post && createPost) this.createPost()
            else if (this.state.submission.post && createPost) this.updatePost()
          })})
        .catch((error) => this.importSubmission(createPost))
    } else {
      axios
        .post(`https://www.stylemepretty.com/wp-admin/admin-ajax.php?action=smp_import_submission&id=${this.props.match.params.id}${createPost ? '&post' : ''}`, { sub_order: map(this.state.images, 'id') })
        .then((res) => this.setState({ submission: { ...this.state.submission, ...res.data, isImporting: false }}))
        .catch((error) => this.importSubmission(createPost))
    }
  }

  createPost () {
    axios
      .post(`https://www.stylemepretty.com/wp-admin/admin-ajax.php?action=smp_import_post_submission&id=${this.props.match.params.id}&user_id=${this.props.user.id}`)
      .then((res) => this.setState({ submission: { ...this.state.submission, ...res.data }}, () => { this.updatePost() }))
      .catch((error) => this.createPost())
  }

  updatePost () {
    axios
      .post(`https://www.stylemepretty.com/wp-admin/admin-ajax.php?action=smp_import_post_submission&id=${this.props.match.params.id}&user_id=${this.props.user.id}`)
      .then((res) => this.setState({ submission: { ...this.state.submission, ...res.data, isImporting: false }}))
      .catch((error) => this.updatePost())
  }

  getYoutubeData = async (videoId) => {
    const data = {
      embed: `https://www.youtube.com/embed/${videoId}`,
      thumb: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
    }
    return data
  }

  getVimeoData = async (videoId) => {
    let result
    
    await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`)
      .then((res) => {
        result = {
          embed: `http://player.vimeo.com/video/${videoId}`,
          thumb: res.data[0].thumbnail_large
        }
      })
      .catch((err) => {
        console.log(err)
      })

    return result
  }

  async addVideo (video) {
    let result
    let yt = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    let vimeo = /(?:.*vimeo.com\/)(?:video\/)?([0-9]+)/
    let match = yt.exec(video)
    
    if (match && match[1]) {
      result = await this.getYoutubeData(match[1])
    } else {
      match = vimeo.exec(video)
      result = await this.getVimeoData(match[1])
    }
    if(get(result, 'thumb')) {
      axiosInstance
        .post(`/submissions/${this.props.match.params.id}/videos/`, {
          url: video,
          submission: this.props.match.params.id,
          thumb: result.thumb,
          embed: result.embed
        })
        .then((result) => {
          this.setState({ videos: [result.data, ...this.state.videos], videoError: false })
        })
    } else {
      this.setState({ videoError: true })
    }
  }

  deleteVideo (videoId) {
    const url = `/submission-videos/${videoId}/`
    axiosInstance
      .delete(url)
      .then(() => {
        let videos = filter(this.state.videos, function(v) { return get(v, 'id') !== videoId})
        this.setState({ videos: videos })
      })
  }

  saveSubmissionDetails (data) {
    const url = `https://www.stylemepretty.com/api/v2/submissions/${this.state.submission.id}/save-additional-info`
    axios
      .post(url, {
        data: data
      })
      .then((res) => {
        this.setState({ additionalInfo: res.data.data })
      })
  }

  saveSubmission (name, value) {
    let val = value
    if (includes(['settings', 'styles', 'colors'], name)) val = map(val, (v) => v.toString())
    const url = `/submissions/${this.props.match.params.id}/`
    axiosInstance
      .put(url, { 
        ...this.state.submission,
        [name]: val
      })
      .then((result) => {
        this.setState({ submission: result.data }, () => {
          if(name === 'status' && val !== 'scheduled') this.addNote(`I changed the status to ${val}`)
          if(name === 'status' && (val === 'scheduled' || val === 'denied')) {
            axios
              .post('https://www.stylemepretty.com/api/v2/submissions/recruit', {
                credits: this.state.credits,
                submission: this.state.submission
              })
          }
          if (this.state.submission.status === 'collection' && name === 'title') this.saveGallery()
          if(name === 'status' && this.state.submission.status === 'denied' && isNull(this.state.submission.denied_date)) this.saveSubmission('denied_date', moment().format('YYYY-MM-DD HH:mm:ss'))
          if(name === 'status' && this.state.submission.status !== 'denied' && !isNull(this.state.submission.denied_date)) this.saveSubmission('denied_date', null)
          if(name === 'status' && includes(['accepted', 'written', 'prepped', 'scheduled', 'accepted-quick', 'social-awaiting-permission'], this.state.submission.status) && isNull(this.state.submission.accepted_date)) this.saveSubmission('accepted_date', moment().format('YYYY-MM-DD HH:mm:ss'))
          if(name === 'publication_date' && !isNull(val)) {
            this.addNote(`I scheduled the post for ${moment(val).format('MMMM Do, YYYY')}`)
            this.saveSubmission('status', 'scheduled')
          }
          if(name === 'status' && !isUndefined(find(statusToEmail, ['status', val]))) this.sendEmailOnStatusChange(val)
        })
      })
  }

  sendEmailOnStatusChange (status) {
    const template = find(statusToEmail, ['status', status]).template
    let message = find(JSON.parse(find(this.state.options, ['option_name', 'submission_email']).option_value), ['name', template]).value
    message = message.replace(/{year}/g, moment().format('YYYY')).replace(/{public_link}/g, `https://www.stylemepretty.com/submissions/submission/${this.id}/${MD5.hash('9cjJhA8' + this.id)}`).replace(/{submissionUrl}/g, `https://www.stylemepretty.com/submissions/submission/${this.id}`)
    const params = {
      emailFrom: 'submissions-help@stylemepretty.com',
      title: `Submission #${this.id}`,
      message: message,
      emailTo: this.state.submission.submit_email
    }
    this.emailSubmitter(params, template)
  }

  saveGallery () {
    if (!isNull(this.state.submission.gallery_id)) {
      axios
        .put(`https://www.stylemepretty.com/api/portfolio-gallery/${this.state.submission.gallery_id}`, {
          title: this.state.submission.title,
          type: this.state.submission.type,
          description: this.state.submission.description,
          location: this.state.submission.location
        })
        .then((res) => {
          this.saveVendorGallery()
        })
    }
  }

  saveVendorGallery () {
    if (get(this.state, 'gallery.vendor_id')) {
      axios
        .put(`https://www.stylemepretty.com/api/vendor/${this.state.gallery.vendor_id}/vendor_galleries`, {
          vendor_galleries: [{
            gallery_id: this.state.gallery.id,
            portfolio_show: 0,
            portfolio_sort: 0
          }]
        })
        .then((res) => {
          axios
            .post(`https://www.stylemepretty.com/api/portfolio-gallery/${this.state.gallery.id}/cache`)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  deleteSubmission() {
    const url = `/submissions/${this.props.match.params.id}/`
    axiosInstance
      .put(url, {
        ...this.state.submission,
        status: 'trash'
      })
      .then(() => {
        this.props.history.push(`/submissions/dashboard/${this.props.user.email}`);
      })
  }

  uploadImagesToS3 (files) {
    let credentials = this.state.credentials
    if (!config.smpCognito) {
      credentials = {
        AccessKeyId: credentials.aws_access_key_id,
        SecretAccessKey: credentials.aws_secret_access_key,
        SessionToken: credentials.session_token
      }
    }

    AWS.config.update({
      region: config.region,
      credentials: {
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken
      }
    })

    const s3 = new AWS.S3({
      params: { 
        Bucket: config.bucket
      }
    })

    eachLimit(files, 5, (file, callback) => {
      const url = `${this.state.submission.path}/${file.name}`
      s3.upload({
        Key: url,
        Body: file,
        ACL: 'public-read'
      }, (error) => {
        if(error) {
          // sendDiscordError('Submissions Server', error, this.props.user, url)
        } else {
          this.addImageToSubmission(file, this.props.match.params.id)
            .then(file => {
              let accepted = this.state.acceptedFiles
              let index = accepted.findIndex(object => object.name === file.data.filename)
              if (this.state.submission.status === 'collection') this.addImageToGallery(get(this.state, 'submission.gallery_id'), file.data.filename, index)
              accepted[index].uploaded = true
              Object.assign(accepted[index], { id: file.data.id })
              accepted[index].path = `${this.state.submission.path}/${file.data.filename}`
              this.setState({ acceptedFiles: accepted })
              if (this.state.submission.type === 'lbb-instagram' && this.state.submission.status === 'started') this.saveSubmission('status', 'review')
              callback()
            })
        }
      })
    })
  }

  onDrop (acceptedFiles, rejectedFiles) {
    const uploads = map(acceptedFiles, async (file) => {
      let newFile = new File([file], uniqueFilename(file.name), { type: file.type })
      Object.assign(newFile, { original_filename: file.name })
      const preview = URL.createObjectURL(newFile)
      return await getImageWidth(preview).then((width) => {
        Object.assign(newFile, { preview: preview, width: width })
        return verifyImage(newFile, this.state.isAdmin)
      })
    })

    map(rejectedFiles, (fileObject) => {
      let file = fileObject.file
      let preview = null
      if (file.size <= 10485760) preview = URL.createObjectURL(file)
      let reason = ''
      if(file.size > 10485760) reason = 'is too large. Images must be smaller than 10 MB in file size.'
      else if (!file.type.includes('image')) reason = 'is not a valid format. Please upload images only.'
      else reason = 'was not uploaded due to an unknown error.'
      this.setState({ deniedFiles: [...this.state.deniedFiles, Object.assign(file, { reason: reason, preview: preview })] })
    })
    Promise.all(uploads).then((results) => {
      this.setState({
        acceptedFiles: [...filter(results, 'valid'), ...this.state.acceptedFiles],
        deniedFiles: [...this.state.deniedFiles, ...filter(results, (r) => { return !r.valid })],
        images: [...filter(results, 'valid'), ...this.state.images]
      }, () => {
        this.uploadImagesToS3(filter(results, 'valid'))
      })
    })
  }

  addImageToSubmission (file, submissionId) {
    return axiosInstance
      .post(`/submission-images/`, { 
        filename: file.name,
        original_filename: file.original_filename,
        submission: submissionId,
        exclude: 0
      })
  }

  addImageToGallery (galleryId, filename, index) {
    return axios
      .post(`https://www.stylemepretty.com/api/portfolio-gallery/${galleryId}/images`, {
        filename: filename,
        position: index,
        skip: true
      })
      .then((res) => {
        this.saveGallery()
      })
  }

  updateSubmissionImageDescription (imageId, text) {
    const image = find(this.state.images, { 'id': imageId })
    if (!isUndefined(image)) {
      axiosInstance
        .put(`/submission-images/${imageId}/`, {
          ...image,
          description: text
        })
    }
  }

  updateSubmissionImageIsFavorite (imageId, isFavorite) {
    if(!isFavorite) {
      axiosInstance
        .post(`/favorites/`, { 
          object_type: 'lbb_instagram',
          object_id: imageId,
          video_id: null,
          user_id: imageId
        })
        .then((res) => {
          this.setState({ instagramFavorites: [imageId, ...this.state.instagramFavorites]})
        })
    } else {
      axiosInstance
        .delete(`/favorites/delete/`, { data: { user_id: imageId } } )
        .then((res) => {
          let favorites = filter(this.state.instagramFavorites, function(i) { return i !== imageId})
          this.setState({ instagramFavorites: favorites})
        })
    }
  }

  updateSubmissionImageMetadata (imageId, value) {
    return axiosInstance
      .post(`/metadata/`, {
        object_type: 'image',
        meta_value: value,
        object_id: imageId,
        meta_key: 'instagram_type'
      })
      .catch((error) => {
        return axiosInstance
          .put(`/metadata/put?object_id=${imageId}`, {
            object_type: 'image',
            meta_value: value,
            object_id: imageId,
            meta_key: 'instagram_type'
          }).catch((err) => {
            throw err
          })
      })
  }

  deleteImage (image) {
    const url = `/submission-images/${image.id}/`
    const imageId = get(find(get(this.state.gallery, 'images', []), { filename: image.filename }), 'id')
    let images = filter(this.state.images, function(i) { return get(i, 'id') !== image.id})
    this.setState({ images: images })
    
    if (this.state.submission.status === 'collection') {
      axios
        .delete(`https://www.stylemepretty.com/api/portfolio-image/${imageId}`)
    }
    axiosInstance
      .delete(url)
  }

  deleteAllImages () {
    const url = `/submission-images/`
    const images = this.state.images
    this.setState({ images: [] })
    map(images, (image) => {
      const imageId = get(find(get(this.state.gallery, 'images', []), { filename: image.filename }), 'id')
      if (this.state.submission.status === 'collection') {
        axios
          .delete(`https://www.stylemepretty.com/api/portfolio-image/${imageId}`)
      }   
      axiosInstance
        .delete(url + image.id + '/')
    })
  }

  downloadSubmission = (submissionId) => {
    const url = `/submissions/${submissionId}/download/`
    axiosInstance
      .get(url, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
          if (totalLength !== null) this.setState({ loadingValue: Math.round((progressEvent.loaded * 100) / totalLength ) })
          if (this.state.loadingValue === 100) this.setState({ loadingValue: 0 })
        }
      })
      .then((result) => {
        let blob = new Blob([result.data], {type: 'application/zip'})
        const downloadUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = downloadUrl
        a.setAttribute('download', `smp-submission-${submissionId}.zip`)
        document.body.appendChild(a)
        a.click()
      })
  }

  addNote (note) {
    const url = `/submission-notes/`
    axiosInstance
      .post(url, {
        note: note,
        username: this.props.user.username,
        submission: this.props.match.params.id,
        assigned: null
      })
      .then((result) => {
        let notes = this.state.notes
        notes.unshift(result.data)
        this.setState({ notes: notes })
      })
  }

  emailSubmitter (params, templateName) {
    const url = 'https://www.stylemepretty.com/api/v2/submissions/email'
    axios.post(url, {
      from: params.emailFrom,
      subject: params.title,
      message: params.message.replace(/\n/g, "<br />"),
      to: params.emailTo
    })
      .then((res) => {
        if (templateName) this.addNote(`I sent the submitter the ${templateName} email`)
        else this.addNote(`I emailed the submitter: ${params.message}`)
      })
  }

  render () {
    let Component = this.state.submission.type !== 'lbb-instagram' ? SingleSubmission : SingleSubmissionInstagram

    return (
      <Component
        {...this.state}
        addCredit={this.addCredit}
        deleteCredit={this.deleteCredit}
        onSortEnd={this.onSortEnd}
        saveSubmission={this.saveSubmission}
        saveSubmissionDetails={this.saveSubmissionDetails}
        deleteSubmission={this.deleteSubmission}
        onDrop={this.onDrop}
        addNote={this.addNote}
        submitForReview={this.submitForReview}
        importSubmission={this.importSubmission}
        createPost={this.createPost}
        updatePost={this.updatePost}
        user={this.props.user}
        addVideo={this.addVideo}
        downloadSubmission={this.downloadSubmission}
        emailSubmitter={this.emailSubmitter}
        deleteImage={this.deleteImage}
        deleteAllImages={this.deleteAllImages}
        deleteVideo={this.deleteVideo}
        updateImageOrder={this.updateImageOrder}
        updateSubmissionImageMetadata={this.updateSubmissionImageMetadata}
        updateSubmissionImageIsFavorite={this.updateSubmissionImageIsFavorite}
        updateSubmissionImageDescription={this.updateSubmissionImageDescription}
        updateImageCredit={this.updateImageCredit}
        acceptSocial={this.acceptSocial}
        declineSocial={this.declineSocial}
        refreshPreview={this.refreshPreview}
      />
    )
  }
}

export default SingleSubmissionContainer

