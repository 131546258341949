import React from 'react'
import styled from 'styled-components'
import { formatDate } from '../../utils/index'
import ErrorBoundary from '../ErrorBoundary'

const NoteContainer = styled.div`
  width: 100%;
  border-top: ${props => props.index > 0 ? '1px solid #D9D9D9' : 'initial'};
  padding-bottom: 25px;
`;

const NoteDate = styled.div`
  font-family: Hind;
  float: left;
  width: 105px;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  padding-top: 25px;
`;

const NoteTextContainer = styled.div`
  width: 265px;
  float: right;
  padding-top: 25px;
`;

const NoteAuthor = styled.div`
  font-family: Hind;
  font-size: 18px;
  color: #ECB595;
  letter-spacing: 0;
`;

const NoteText = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 21px;
  word-break: break-word;
`;

const Clear = styled.div`
  clear: both;
`;

const Note = ({ note, index }) => {
  return (
    <ErrorBoundary>
      <NoteContainer index={index}>
        <NoteDate>{formatDate(new Date(note.created), 'MMM dd')}</NoteDate>
        <NoteTextContainer>
          <NoteAuthor>{note.username}</NoteAuthor>
          <NoteText>{note.note}</NoteText>
        </NoteTextContainer>
        <Clear />
      </NoteContainer>
    </ErrorBoundary>
  )
}

export default Note
