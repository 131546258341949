import React from 'react'
import styled from 'styled-components'
import { map, get } from 'lodash'
import arrayMove from 'array-move'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import Sort from '@stylemepretty/smp-react-components/Icons/Sort'
import CustomSection from '../CustomSection/CustomSection'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'
import { generateId } from '../../constants'

const InfoContainer = styled.div`
  width: 100%;
`

const InfoHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const ButtonContainer = styled.div`
  width: 205px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3dbc7;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
`

const ButtonText = styled.div`
  font-size: 18px;
  text-align: center;
  font-family: Hind;
  letter-spacing: 0px;
  text-transform: none;
  color: #404040;
`

const SectionsContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`

const addSection = (additionalInfo, saveSubmission) => {
  let info = additionalInfo
  info.sections.push({ id: generateId(), name: '', fields: [{ name: '', text: ''}], images: [], carousel: true })
  saveSubmission(info)
}

const DragHandle = sortableHandle(() => <Sort style={{ marginBottom: 10, cursor: 'move' }} />)

const SortableItem = sortableElement(({additionalInfo, sectionIndex, saveSubmission, credits}) => {
  return (
    <CustomSection
      dragHandle={<DragHandle />}
      additionalInfo={additionalInfo}
      sectionIndex={sectionIndex}
      saveSubmission={saveSubmission}
      credits={credits}
    />
  )
})

const SortableList = sortableContainer(({additionalInfo, saveSubmission, sections, credits}) => {
  return (
    <SectionsContainer>
      {map(sections, (section, index) => (
        <SortableItem
          key={index}
          index={index}
          additionalInfo={additionalInfo}
          sectionIndex={index}
          saveSubmission={saveSubmission}
          credits={credits}
        />
      ))}
    </SectionsContainer>
  )
})

const sortField = (additionalInfo, newInfo, saveSubmission) => {
  let info = additionalInfo
  info.sections = newInfo
  saveSubmission(info)
}

const AdditionalInfoCustom = ({ additionalInfo, saveSubmission, credits }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      sortField(additionalInfo, arrayMove(additionalInfo.sections, oldIndex, newIndex), saveSubmission)
    }
  }

  return (
    <ErrorBoundary>
      <InfoContainer>
        <InfoHeader>{get(additionalInfo, 'title')}</InfoHeader>
        <ButtonContainer 
          onClick={() => {
            addSection(additionalInfo, saveSubmission)
          }}
          style={{ marginTop: 20 }}
        >
          <ButtonText>Add Section</ButtonText>
        </ButtonContainer>
        <SortableList
          useDragHandle
          pressDelay={100}
          axis='y'
          onSortEnd={onSortEnd}
          sections={get(additionalInfo, 'sections')}
          additionalInfo={additionalInfo}
          saveSubmission={saveSubmission}
          credits={credits}
        />
      </InfoContainer>
    </ErrorBoundary>
  )
}

export default AdditionalInfoCustom