import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { map, includes, get, isNull, isUndefined, find } from 'lodash'
import moment from 'moment'
import MD5 from 'md5-es'
import StickyBox from 'react-sticky-box'
import Button from '../../../../../components/Button'
import { Checkmark } from '../../../../../components/Icons'
import TextField from '../../../../../components/TextField'
import { imageRequirements } from '../../../../../constants'

const SidebarContainer = styled.div`
  width: 380px;
  height: auto;
  background-color: #FAFAFA;
  padding: 30px;
  float: right;
`

const SubmissionStatus = styled.span`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
`

const PinkStatus = styled.span`
  color: #ECB595;
`
const Link = styled.a`
  color: rgb(236, 182, 151);
  text-decoration: none;
`

const SideBarPublicLinkContainer = styled.div`
  margin-bottom: 21px;
`

const SidebarChecklistContainer = styled.div`
  margin-top: 20px;
`

const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  letter-spacing: 1px;
  font-family: Hind;
`

const CopiedText = styled.div`
  font-size: 12px;
  color: #ECB595;
  text-align: right;
  height: 14px;
  font-family: Hind;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`

const ChecklistItemDescription = styled.div`
`

const SubmissionStatusContainer = styled.div`
`

const SubmissionStatusDescription = styled.div`
  margin-top: 20px;
  font-family: Hind;
  letter-spacing: 1px;
`

const SubmissionStatusLinks = styled.a`
  font-family: Hind;
  letter-spacing: 1px;
  text-decoration: none;
  color: #ECB595;
`

const SubmitContainer = styled.div`
  width: 180px;
  margin: 20px auto 0;
`

const UserSidebar = ({ submission, images, videos, credits, additionalInfo, isValid, submitClick, vendor, post, acceptSocial, declineSocial }) => {
  const [isCopied, setIsCopied] = useState(false)
  const delay = ms => new Promise(res => setTimeout(res, ms))
  const PublicLink = ({ url, submission }) => {
    return (
      <Fragment>
        <SubmissionStatus>Collaboration Link <PinkStatus>(Click to Copy)</PinkStatus></SubmissionStatus>
        <TextField
          disabled
          value={`https://www.stylemepretty.com/submissions/submission/${submission.id}/${url}`}
          height={45}
          style={{ width: 370, marginTop: 10, fontSize: 10 }}
          onClick={async () => {
            navigator.clipboard.writeText(`https://www.stylemepretty.com/submissions/submission/${submission.id}/${url}`)
            setIsCopied(true)
            await delay(3000)
            setIsCopied(false)
          }}
        />
        <CopiedText>{isCopied ? 'Copied!' : ''}</CopiedText>
      </Fragment>
    )
  }

  const statusDescription = {
    started: 'Please complete the following checklist in order to submit for review.',
    review: 'Your submission is being reviewed. Please allow 2 weeks to hear back with a response from our editorial team.',
    accepted: `Your submission has been accepted for publication on ${!isNull(get(submission, 'use', null)) ? submission.use.replace('-', ' ') : 'SMP'}! ${get(submission, 'publication_date') ? 'Your post has been tentatively scheduled for ' + moment(submission.publication_date).format('MMMM Do, YYYY') + '.' : ''} Once your feature goes live, please feel free to share the content of this submission with other publications, as we have no exclusivity period on SMP features.`,
    scheduled: `Your submission has been accepted for publication on ${!isNull(get(submission, 'use', null)) ? submission.use.replace('-', ' ') : 'SMP'}! ${get(submission, 'publication_date') ? 'Your post has been tentatively scheduled for ' + moment(submission.publication_date).format('MMMM Do, YYYY') + '.' : ''} Once your feature goes live, please feel free to share the content of this submission with other publications, as we have no exclusivity period on SMP features.`,
    written: `Your submission has been accepted for publication on ${!isNull(get(submission, 'use', null)) ? submission.use.replace('-', ' ') : 'SMP'}! ${get(submission, 'publication_date') ? 'Your post has been tentatively scheduled for ' + moment(submission.publication_date).format('MMMM Do, YYYY') + '.' : ''} Once your feature goes live, please feel free to share the content of this submission with other publications, as we have no exclusivity period on SMP features.`,
    prepped: `Your submission has been accepted for publication on ${!isNull(get(submission, 'use', null)) ? submission.use.replace('-', ' ') : 'SMP'}! ${get(submission, 'publication_date') ? 'Your post has been tentatively scheduled for ' + moment(submission.publication_date).format('MMMM Do, YYYY') + '.' : ''} Once your feature goes live, please feel free to share the content of this submission with other publications, as we have no exclusivity period on SMP features.`,
    published: `Your submission has been published!`,
    quick: 'Your submission is being reviewed. Please allow 2 weeks to hear back with a response from our editorial team.',
    'accepted-quick': 'Your submission has been accepted for publication! Please complete the following checklist and click submit. We won\'t be able to schedule your feature until we have all of your information!',
    'completed-quick': 'Thank you for completing your submission! We will schedule your feature shortly.',
    'social-awaiting-permission': 'Your submission has been accepted for a social only feature that includes an image carousel on Instagram with all tagged vendors. If you would like to go ahead with a social post please click Accept. If not, please click Decline. If you have any questions, please contact us at submissions-help@stylemepretty.com',
    'social-denied': 'Thank you for your response. As you have declined a social feature, we have moved your submission to the denied status.',
    social: 'Thank you for confirming that you would like a social only feature! We will be in touch to let you know when we post!',
    denied: 'Unfortunately your submission has been denied.',
    hold: 'Your submission is currently on hold. Please contact submissions-help@stylemepretty.com if you have any questions!',
    unpublished: 'Your submission is currently on hold. Please contact submissions-help@stylemepretty.com if you have any questions!',
    purchased: 'Thank you for submitting for your LBB guaranteed post! Please email lbb@stylemepretty.com to let us know.'
  }

  const statusToShow = {
    'started': 'started',
    'review': 'review',
    'accepted': 'accepted',
    'scheduled': 'scheduled',
    'written': 'scheduled',
    'prepped': 'scheduled',
    'published': 'published',
    'completed': 'published',
    'quick': 'review',
    'accepted-quick': 'accepted',
    'completed-quick': 'accepted',
    'social-awaiting-permission': 'accepted for social',
    'social-denied': 'denied',
    'social': 'accepted',
    'denied': 'denied',
    'hold': 'hold',
    'unpublished': 'hold',
    'purchased': 'purchased'
  }

  const Collection = ({ submission, vendor }) => {
    return (
      <Fragment>
        <SubmissionStatusDescription>Your gallery is autosaved and ready to go live!</SubmissionStatusDescription>
        <SubmissionStatusDescription>View and rearrange your galleries <Link href={`https://www.stylemepretty.com/vendor-profile/${vendor.slug}`} rel="noopener noreferrer" target='_blank'>here</Link> and click the edit profile button.</SubmissionStatusDescription>
        <SubmissionStatusDescription>Edit (add images, remove images, etc) existing galleries <Link href={`https://www.stylemepretty.com/submissions/dashboard/${submission.submit_email}?status=collection`} rel="noopener noreferrer" target='_blank'>here</Link></SubmissionStatusDescription>
        <SubmissionStatusDescription>For tips on setting up your LBB profile, including pointers on galleries, click <Link href='https://www.stylemepretty.com/2020/05/28/setting-up-your-lbb-portfolio/' rel="noopener noreferrer" target='_blank'>here</Link></SubmissionStatusDescription>
      </Fragment>
    )
  }
  
  const Started = ({ submission, images, credits, additionalInfo }) => {
    let numImages = find(imageRequirements, ['type', submission.type])
    if (isUndefined(numImages)) numImages = find(imageRequirements, ['type', 'default'])
    let checklist = {
      title: 'A Title For Your Submission',
      location: 'The Event Location',
      description: submission.id > 358705 ? 'All Required Details' : 'A Detailed Description of the Day',
      images: `At Least ${numImages.count} Image${numImages.count > 1 ? 's (MAX 300)' : ''}`,
      credits: 'Credits for All Vendors Involved'
    }

    return (
      <SidebarChecklistContainer>
        {map(Object.keys(checklist), (item, index) => {
          let valid = false
          if (includes(['title', 'location'], item) && submission[item] && submission[item].length) valid = true
          if (submission.id > 358705 && item === 'description' && get(additionalInfo, 'data.sections', false)) {
            valid = true
            map(additionalInfo.data.sections, (section) => {
              map(section.fields, (dataPoint) => {
                if (dataPoint.type === 'Required' && dataPoint.text.length === 0) valid = false
              })
            })
          } else {
            if (item === 'description' && submission[item] && submission[item].length) valid = true
          }
          if (includes('images', item) && images && images.length >= numImages.count && images.length <= 300) valid = true
          if (includes('credits', item) && credits && credits.length) valid = true
          return (
            <ChecklistItem key={index}>
              {valid && <Checkmark color='#ECB595' scale={2} style={{ marginRight: 10}} />}
              <ChecklistItemDescription style={{ opacity: valid ? '1' : '0.5', marginLeft: valid ? 0 : 34 }}>{checklist[item]}</ChecklistItemDescription>
            </ChecklistItem>
          )
        })}
        <SubmitContainer>
          <Button
            text='Submit to SMP'
            onClick={submitClick}
            disabled={!isValid}
          />
        </SubmitContainer>
      </SidebarChecklistContainer>
    )
  }

  return (
    <StickyBox offsetTop={20} offsetBottom={20}>
      <SidebarContainer>
        <SideBarPublicLinkContainer>
          <PublicLink
            url={MD5.hash(`9cjJhA8${submission.id}`)}
            submission={submission}
          />
        </SideBarPublicLinkContainer>
        <SubmissionStatus>Submission Status: <PinkStatus>{statusToShow[submission.status]}</PinkStatus></SubmissionStatus>
        <SubmissionStatusContainer>
          <SubmissionStatusDescription>{statusDescription[get(submission, 'status')]}</SubmissionStatusDescription>
          {submission.status === 'published' && get(post, 'status', '') === 'publish' && (
            <SubmissionStatusDescription>To View Your Post, <SubmissionStatusLinks target='_blank' href={`https://www.stylemepretty.com${get(post, 'url')}`}>Click Here!</SubmissionStatusLinks></SubmissionStatusDescription>
          )}
          {submission.status === 'social-awaiting-permission' && (
            <ButtonContainer>
              <Button
                text='Accept'
                onClick={acceptSocial}
              />
              <Button
                text='Decline'
                onClick={declineSocial}
              />
            </ButtonContainer>
          )}
          {(submission.status === 'started' || submission.status === 'accepted-quick') && (
            <Started
              submission={submission}
              images={images}
              credits={credits}
              additionalInfo={additionalInfo}
            />
          )}
          {submission.status === 'collection' && (
            <Collection
              submission={submission}
              vendor={vendor}
            />
          )}
        </SubmissionStatusContainer>
      </SidebarContainer>
    </StickyBox>
  )
}

export default UserSidebar