import React from 'react'

function scaleProps (n) {
  return {
    width: n * 12,
    height: n * 12
  }
}

const Checkmark = ({ color, scale, style, className, onClick }) => (
  <svg width='24' height='24' xmlns="http://www.w3.org/2000/svg" fillRule='evenodd' clipRule='evenodd' className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <path fill={color} d='M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z'></path>
  </svg>
)

export default Checkmark