import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Sort from '@stylemepretty/smp-react-components/Icons/Sort'
import Add from '@stylemepretty/smp-react-components/Icons/Plus'
import CustomField from '../CustomField/CustomField'
import TextField from '../../../../../../components/TextField'
import { Trash } from '../../../../../../components/Icons'
import { map, get } from 'lodash'
import { generateId } from '../../constants'

const CustomSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`

const CustomSectionInnerContainer = styled.div`
  border: 3px solid #f3dbc7;
  min-height: 50px;
  flex-grow: 1;
  margin-right: 10px;
  position: relative;
`

const AddFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #f3dbc7;
  width: 30px;
  height: 30px;
`

const CustomSectionHandles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionHeaderContainer = styled.div`
  text-align: center;
  margin: -17px auto;
`

const SectionHeader = styled.div`
  background-color: white;
  text-align: center;
  display: inline-block;
  padding: 0 50px;
  font-family: hind;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
`

const SectionFields = styled.div`
  padding: 40px;
`

const DragHandle = sortableHandle(() => <Sort style={{ cursor: 'move' }} />)

const SortableItem = sortableElement(({additionalInfo, sectionIndex, fieldIndex, saveSubmission, values, handleChange, credits}) => {
  return (
    <CustomField
      dragHandle={<DragHandle />}
      additionalInfo={additionalInfo}
      sectionIndex={sectionIndex}
      fieldIndex={fieldIndex}
      saveSubmission={saveSubmission}
      values={values}
      handleChange={handleChange}
      credits={credits}
    />
  )
})

const SortableList = sortableContainer(({additionalInfo, value, handleChange, sectionIndex, saveSubmission, dragHandle, values, credits}) => {
  return (
    <CustomSectionContainer>
      <CustomSectionInnerContainer>
        <SectionHeaderContainer>
          <SectionHeader>{value || 'Section Name'}</SectionHeader>
        </SectionHeaderContainer>
        <SectionFields>
          <TextField
            style={{ width: 'calc(100% - 26px)' }}
            height={45}
            value={value}
            name='sectionName'
            label='Section Name'
            onChange={handleChange}
            onBlur={(e) => { 
              if (e.target.value !== additionalInfo.sections[sectionIndex].name) {
                updateSection(additionalInfo, sectionIndex, e.target.value, saveSubmission)
              }
            }}
          />
          {map(additionalInfo.sections[sectionIndex].fields, (field, i) => (
            <SortableItem
              additionalInfo={additionalInfo}
              sectionIndex={sectionIndex}
              values={values}
              handleChange={handleChange}
              index={i}
              key={i}
              fieldIndex={i}
              saveSubmission={saveSubmission}
              credits={credits}
            />
          ))}
        </SectionFields>
        <AddFieldContainer>
          <Add
            onClick={() => addField(additionalInfo, sectionIndex, saveSubmission)}
            style={{cursor: 'pointer', color: 'white', marginLeft: '1.5px', marginTop: '1.5px'}}
          />
        </AddFieldContainer>
      </CustomSectionInnerContainer>
      <CustomSectionHandles>
        {dragHandle}
        <Trash 
          style={{ cursor: 'pointer' }}
          scale={1}
          color='#A0A0A0' 
          onClick={() => removeSection(additionalInfo, sectionIndex, saveSubmission)}
        />
      </CustomSectionHandles>
    </CustomSectionContainer>
  )
})

const getInitialValues = (additionalInfo, sectionIndex) => {
  let initial = {}
  map(get(additionalInfo, `sections[${sectionIndex}].fields`, []), (field, i) => {
    initial[`field-${i}-name`] = field.name || ''
    initial[`field-${i}-text`] = field.text || ''
  })
  initial['sectionName'] = get(additionalInfo, `sections[${sectionIndex}].name`, '')
  initial['sectionId'] = get(additionalInfo, `sections[${sectionIndex}].id`, '')
  return initial
}

const updateSection = (additionalInfo, sectionIndex, value, saveSubmission) => {
  let info = additionalInfo
  info.sections[sectionIndex].name = value
  info.sections[sectionIndex].id = generateId()
  saveSubmission(info)
}

const removeSection = (additionalInfo, sectionIndex, saveSubmission) => {
  let info = additionalInfo
  info.sections.splice(sectionIndex, 1)
  saveSubmission(info)
}

const sortField = (info, saveSubmission, additionalInfo, sectionIndex) => {
  let additional = additionalInfo
  additional.sections[sectionIndex].fields = info
  saveSubmission(additional)
}

const addField = (additionalInfo, sectionIndex, saveSubmission) => {
  let info = additionalInfo
  info.sections[sectionIndex].fields.push({ name: '', text: ''})
  saveSubmission(info)
}

const CustomSection = ({ additionalInfo, sectionIndex, saveSubmission, dragHandle, credits }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      sortField(arrayMove(additionalInfo.sections[sectionIndex].fields, oldIndex, newIndex), saveSubmission, additionalInfo, sectionIndex)
    }
  }

  return (
    <Formik enableReinitialize initialValues={getInitialValues(additionalInfo, sectionIndex)}>
      {({ values, handleChange }) => (
        <form>
          <SortableList
            useDragHandle
            pressDelay={100}
            axis='y'
            onSortEnd={onSortEnd}
            additionalInfo={additionalInfo}
            value={values.sectionName}
            handleChange={handleChange}
            sectionIndex={sectionIndex}
            saveSubmission={saveSubmission}
            dragHandle={dragHandle}
            values={values}
            credits={credits}
          />
        </form>
      )}
    </Formik>
  )
}

export default CustomSection



