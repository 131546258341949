import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../../../components/Button'
import HeaderText from '../../../../components/HeaderText'
import ProgressBar from '../../../../components/ProgressBar'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const HeaderContainer = styled.div`
  height: 405px;
  width: 100%;
  background-color: #FAFAFA;
`

const HeaderInner = styled.div`
  width: 1220px;
  height: calc(100% - 80px);
  padding: 40px 0 40px;
  font-family: 'Hind';
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const HeaderInfo = styled.div`
  margin-top: 40px;
`

const HeaderInfoNumber = styled.div`
  font-family: 'Hind';
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
  line-height: 41px;
  font-weight: 300;
`

const HeaderInfoBy = styled.div`
  font-family: 'Hind';
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 300;
`

const Bold = styled(Link)`
  font-weight: 400;
  color: rgb(236, 182, 151);
`

const HeaderButtons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LastSaved = styled.div`
  font-family: Hind;
  font-weight: 300;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  margin-left: 25px;
`

const Delete = styled.span`
  font-family: 'Hind';
  width: 130px;
  font-size: 16px;
  color: #ECB595;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
  cursor: pointer;
`


const Header = ({ user, submission, images, lastSaved, isLbb, isProfile, search, isValid, submitClick, downloadClick, deleteSubmission, progress, userViewClick, isUserView, isAdmin, nextSubmission, nextSubmissionType }) => {
  const saved = lastSaved === '0 seconds' ? 'just now' : `${lastSaved} ago` 

  const goToNext = (id, type) => {
    window.location.href = `/submissions/submission/${id}?review=${type}`
  }

  return (
    <ErrorBoundary>
      <HeaderContainer>
        <ProgressBar value={progress || 0} />
        <HeaderInner>
          <HeaderText text='Submissions' />
          <HeaderInfo>
            <HeaderInfoNumber>{`Submission No. ${submission.id}`}</HeaderInfoNumber>
            <HeaderInfoBy>by:
              <Bold to={`/submissions/dashboard/${submission.submit_email}`}>{` ${submission.submit_email}\u00A0\u00A0\u00A0${isLbb ? '(LBB Vendor)' : (isProfile ? '(Profile Vendor)' : '')}`}</Bold>
            </HeaderInfoBy>
          </HeaderInfo>
          <HeaderButtons>
            <HeaderButtonsContainer>
              {submission.type !== 'lbb-instagram' && (submission.status === 'started' || submission.status === 'accepted-quick') && <Button onClick={submitClick} style={{ marginRight: 25 }} disabled={!isValid} text='Submit to SMP' />}
              {images.length > 0 && <Button text='Download' onClick={downloadClick} />}
              <LastSaved>{`last saved: ${saved}`}</LastSaved>
            </HeaderButtonsContainer>
            <HeaderButtonsContainer>
              <Link style={{marginRight: 25}} to={`/submissions/dashboard/${user.email}`}>
                <Button text='My Submissions' />
              </Link>
              {isAdmin && <Button onClick={userViewClick} text={`${isUserView ? 'Admin' : 'User'} View`} />}
              {isAdmin && nextSubmission && nextSubmissionType ? <Button style={{marginLeft: 25}} text='Next Submission' onClick={() => goToNext(nextSubmission, nextSubmissionType)} /> : null}
            </HeaderButtonsContainer>
          </HeaderButtons>
          {isAdmin && <Delete onClick={deleteSubmission}>Delete Submission</Delete>}
        </HeaderInner>
      </HeaderContainer>
    </ErrorBoundary>
  )
}

export default Header
