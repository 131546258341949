import React, { useState, useEffect } from 'react'
import axiosInstance from '../../config/axiosResolver'
import axios from 'axios'
import { pickBy, get } from 'lodash'

import SubmissionsAdmin from './submissions-admin'
import { isAdmin } from '../../utils'

const SubmissionsAdminContainer = (props) => {
  const { user, history } = props
  const [isLoading, setIsLoading] = useState(true)
  const [submissions, setSubmissions] = useState([])
  const [totalSubmissions, setTotalSubmissions] = useState(0)
  const [filters, setFilters] = useState({ page: 1, ordering: '-id' })
  // const [notFilters, setNotFilters] = useState({ status: 'collection', type: 'lbb-instagram' })
  const perPage = get(filters, 'size', 15)
  if (!isAdmin(user)) props.history.push(`/submissions/dashboard/${user.email}`)
	  
	
  useEffect(() => {
    const fetchData = () => {
      const query = Object.keys(pickBy(filters)).map(key => key + '=' + filters[key]).join('&');
      // const notQuery = Object.keys(pickBy(notFilters)).map(key => `${key}!=${notFilters[key]}`).join('&');
      if(get(filters, 'type')) {
        axiosInstance
          .get(`/submissions/?${query}`) //&${notQuery}`)
          .then((result) => {
            setSubmissions(result.data.results)
            setIsLoading(false)
            setTotalSubmissions(result.data.count)
          })
      } else {
        axios
          .get(`https://www.stylemepretty.com/api/v2/submissions/submissions?${query}`)
          .then((result) => {
            setSubmissions(result.data.data.results)
            setIsLoading(false)
            setTotalSubmissions(result.data.data.count)
          })
      }
    }

    fetchData()
  }, [filters]) //, notFilters])
	
  return (
    <SubmissionsAdmin
      isLoading={isLoading}
      submissions={submissions}
      totalSubmissions={totalSubmissions}
      filters={filters}
      setFilters={setFilters}
      perPage={perPage}
      user={user}
      history={history}
    />
  )
}

export default SubmissionsAdminContainer

