import React from 'react'
import styled from 'styled-components'
import { default as MUIButton } from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import ErrorBoundary from '../ErrorBoundary'

const useStyles = makeStyles({
  root: props => ({
    width: props.width ? props.width : 180,
    height: props.height ? props.height : 48,
    border: props.color ? `1px solid ${props.color}` : '1px solid #F3DBC7',
    background: props.color ? props.color : '#F3DBC7',
    color: '#404040',
    
    '&:hover': {
      background: props.hoverColor ? props.hoverColor : '#F6E5D5',
      border: props.HoverColor ? `1px solid ${props.HoverColor}` : '1px solid #F6E5D5'
    },
    
    '&:disabled': {
      background: props.disabledColor ? props.disabledColor : '#F9EFE6',
      border: props.disabledColor ? `1px solid ${props.disabledColor}` : '1px solid #F9EFE6',
      color: '#B4B4B4'
    }
  }),

  label: props => ({
    fontFamily: 'Hind',
    fontSize: 18,
    letterSpacing: 0,
    textAlign: 'center',
    textTransform: 'none',
  })
})

const Loader = styled.div`
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  :before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    border: 3px solid #c8c8c8;
    border-top-color: #81807b;
    animation: spinner .7s linear infinite;
  }
`

const Button = ({ disabled, text, color, hoverColor, disabledColor, width, height, style, onClick, showLoader }) => {
  const props = { width: width, height: height, color: color, hoverColor: hoverColor, disabledColor: disabledColor }
  const classes = useStyles(props)

  return (
    <ErrorBoundary>
      <MUIButton
        style={style}
        classes={{
          root: classes.root,
          label: classes.label
        }}
        disableRipple
        disabled={disabled}
        onClick={onClick}
      >
        {showLoader ? <Loader /> : text}
      </MUIButton>
    </ErrorBoundary>
  )
}

export default Button