import React from 'react'
import { default as MUIModal } from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from '../ErrorBoundary'

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '1px 1px 8px 0 rgba(0,0,0,0.50)',
    borderRadius: 3,
    outline: 'none'
  }
})

const Modal = ({ children, open, handleClose, onKeyDown }) => {
  const classes = useStyles()

  return (
    <ErrorBoundary>
      <MUIModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onKeyDown={onKeyDown}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {children}
          </div>
        </Fade>
      </MUIModal>
    </ErrorBoundary>
  )
}

export default Modal
