import React from 'react'

function scaleProps (n) {
  return {
    width: n * 12,
    height: n * 12
  }
}

const Invalid = ({ color, scale, style, className, onClick }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <path d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z'></path>
  </svg>
)

export default Invalid