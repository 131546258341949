import React from 'react'

function scaleProps (n) {
  return {
    width: n * 10,
    height: n * 10
  }
}

const DefaultAvatar = ({ stroke, fill, scale, style, className, onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMax meet" viewBox="0 0 40 40" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <circle stroke={stroke} fill={fill} cx="20" cy="20" r="14.5"></circle>
    <path fill={stroke} d="M16.57 18.74a2.85 2.85 0 1 1 2.85-2.85 2.85 2.85 0 0 1-2.85 2.85zm0-4.7a1.85 1.85 0 1 0 1.85 1.85A1.85 1.85 0 0 0 16.57 14zM23.43 17.33a2.85 2.85 0 1 1 2.85-2.85 2.85 2.85 0 0 1-2.85 2.85zm0-4.7a1.85 1.85 0 1 0 1.85 1.85 1.85 1.85 0 0 0-1.85-1.85z"></path>
    <path fill={stroke} d="M23.53 18h-.15a4.34 4.34 0 0 0-3.79 2 4.87 4.87 0 0 0-3.15-1A4.15 4.15 0 0 0 12 23v3h16v-3.95A4.23 4.23 0 0 0 23.53 18zM13 25v-2c0-1.72 1.48-3 3.5-3a3.62 3.62 0 0 1 2.66 1 4 4 0 0 0-.16 1.05V25zm14 0h-7v-2.95A3.16 3.16 0 0 1 23.38 19h.15A3.26 3.26 0 0 1 27 22.05z"></path>
  </svg>
)

export default DefaultAvatar