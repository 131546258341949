import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { map, get, find, toNumber } from 'lodash'
import { Hero } from './components/Hero'
import { Image } from './components/Image'
import { Carousel } from './components/Carousel'


const PostPreviewContainer = styled.div`
  width: 430px;
  margin-top: 20px;
`

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-family: linotype-didot-roman;
  font-size: 24px;
  margin-bottom: 10px;
  letter-spacing: 1px;
`

const ImageSectionHeader = styled.div`
  font-family: linotype-didot-roman;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`

const Byline = styled.div`
  width: 100%;
  text-align: center;
  font-family: hind;
  font-size: 13px;
  margin-bottom: 20px;
  color: #221f1f;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
`

const PostPreviewSection = styled.div`
  margin-top: 50px;
`

const SectionTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`

const SectionTextHeader = styled.span`
  font-family: Hind;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 3px;
  font-weight: 500
`

const SectionText = styled.span`
  font-family: Hind;
  font-size: 14px;
  text-transform: none;
  margin-left: 7.5px;
  > p {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: #ECB595;
  }
`

const getLeadImage = (additionalInfo, images) => {
  return find(get(images, 'images'), ['id', toNumber(get(additionalInfo, 'leadImage', 0))])
}

const getImages = (imageGroup, images) => {
  let result = []
  if(get(imageGroup, 'images', []).length > 0) {
    map(imageGroup.images, (image) => {
      let galleryImage = find(get(images, 'images', []), ['id', toNumber(image)])
      if(galleryImage) result.push(galleryImage)
    })
  }
  return result
}

const hasDetails = (sectionIndex, additionalInfo) => {
  let response = false
  if (get(additionalInfo, `sections[${sectionIndex}].fields`, []).length > 0) {
    map(get(additionalInfo, `sections[${sectionIndex}].fields`, []), (field) => {
      if (get(field, 'text', '').length > 0) response = true
    })
  }
  return response
}


const PostPreview = ({ additionalInfo, submission, images }) => {
  return (
    <PostPreviewContainer>
      <Header>{submission.title}</Header>
      <Byline>{`Lauren Megerdichian • ${moment(submission.publication_date || moment()).format('LL')}`}</Byline>
      {getLeadImage(additionalInfo, images) ? <Image image={getLeadImage(additionalInfo, images)} /> : null}
      {map(get(additionalInfo, 'sections', []), (section, i) => (
        <PostPreviewSection key={i}>
          {hasDetails(i, additionalInfo) && (
            <Hero style={{ padding: '30px 0 0', marginBottom: 50 }} header={get(section, 'name', '')}>
              {map(section.fields, (field, index) => {
                if (get(field, 'text', '').length > 0 && get(field, 'name') !== 'Wedding Colors') { 
                  return (
                    <SectionTextContainer key={index}>
                      <SectionTextHeader>{get(field, 'name')}<SectionText dangerouslySetInnerHTML={{ __html: get(field, 'text').replace('<p>', '').replace('</p>', '') }} /></SectionTextHeader>
                    </SectionTextContainer>
                  )
                }
              })}
            </Hero>
          )}
          {map(section.images, (imageGroup) => (
            <>
              {getImages(imageGroup, images).length > 0 && get(imageGroup, 'carousel', false) &&
                <>
                  <ImageSectionHeader>{get(imageGroup, 'name')}</ImageSectionHeader>
                  <Carousel images={getImages(imageGroup, images)} />
                </>
              }
              {getImages(imageGroup, images).length > 0 && !get(imageGroup, 'carousel', false) && (
                <>
                  <ImageSectionHeader>{get(imageGroup, 'name')}</ImageSectionHeader>
                  {map(getImages(imageGroup, images), (image, index) => (
                    <Image key={index} image={image} />
                  ))}
                </>
              )}
            </>
          ))}
        </PostPreviewSection>
      ))}
    </PostPreviewContainer>
  )
}

export default PostPreview
