import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { filter } from 'lodash'
import TextField from '../../../../components/TextField'
import Select from '../../../../components/Select'
import { submissionTypes, seasonTypes } from '../../../../constants'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const InfoContainer = styled.div`
  width: 100%;
`

const InfoHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const InfoDetails = styled.div`
  font-family: hind;
  font-size: 14px;
  color: #55575A;
  margin-top: 20px;
`

const InfoFields = styled.div`
  width: 100%;
  margin-top: 30px;
`

const GeneralInfo = ({ submission, saveSubmission, isUserView, isAdmin, workshopData }) => {
  const [location, setLocation] = useState(submission.location || '')
  let types = filter(submissionTypes, function (t) { return t.name !== 'Living' })

  useEffect(() => {
    const createAutocomplete = async () => {
      let placesAPI = await new window.google.maps.places.Autocomplete( document.getElementById('location'), { types: ['(cities)'] })
      await placesAPI.setFields(['formatted_address'])
      await placesAPI.addListener('place_changed', () => {
        let place = placesAPI.getPlace()
        place = place.formatted_address
        if (place) {
          setLocation(place)
          saveSubmission('location', place)
        }
      })
    }
    createAutocomplete()
  }, [saveSubmission])

  return (
    <ErrorBoundary>
      <InfoContainer>
        <InfoHeader>Basic Information</InfoHeader>
        <InfoDetails>Please fill in the following details for your submission. The submission will not be considered without this information.</InfoDetails>
        <InfoFields>
          <Formik
            initialValues={{ 
              title: submission.title || '',
              type: submission.type || '',
              location: submission.location || '',
              season: submission.season || '',
              description: submission.description || '',
              workshopHost: workshopData.host || ''
            }}
          >
            {({ values, handleChange }) => (
              <form>
                <TextField
                  value={values.title}
                  fullWidth
                  height={45}
                  name='title'
                  label='Title'
                  onChange={handleChange}
                  onBlur={(e) => { if (e.target.value !== submission.title) saveSubmission(e.target.name, e.target.value)} }
                  style={{marginBottom: 20}}
                  disabled={submission.status === 'quick' || submission.status === 'denied'}
                />
                {!isUserView ? <Select
                  options={types}
                  onChange={handleChange}
                  onBlur={(e) => { if (e.target.value !== submission.type) saveSubmission(e.target.name, e.target.value)} }
                  label='Type'
                  name='type'
                  fullWidth
                  labelWidth={35}
                  value={values.type}
                  style={{marginBottom: 20}}
                /> : null}
                {!isUserView && values.workshopHost !== '' ? <TextField
                  value={values.workshopHost}
                  fullWidth
                  height={45}
                  name='workshopHost'
                  label='Workshop Host'
                  style={{marginBottom: 20}}
                  disabled
                /> : null}
                <TextField
                  value={location}
                  fullWidth
                  height={45}
                  placeholder=''
                  name='location'
                  id='location'
                  label='Location'
                  onChange={(e) => setLocation(e.target.value)}
                  onBlur={(e) => { if (e.target.value !== submission.location) saveSubmission(e.target.name, e.target.value)} }
                  style={{marginBottom: 20}}
                />
                <Select
                  options={seasonTypes}
                  onChange={handleChange}
                  onBlur={(e) => { if (e.target.value !== submission.season) saveSubmission(e.target.name, e.target.value)} }
                  label='Season'
                  name='season'
                  fullWidth
                  labelWidth={49}
                  value={values.season}
                  style={{marginBottom: 20}}
                />
                {(submission.id <= 358705 || submission.type === 'other' || submission.status === 'quick' || submission.status === 'accepted-quick' || (isAdmin && submission.description)) && (
                  <TextField
                    fullWidth
                    value={values.description}
                    id='description'
                    name='description'
                    label={submission.id > 358705 && submission.type !== 'other' ? 'Quick Link' : 'Description'}
                    disabled={submission.id > 358705 && submission.status !== 'quick' && submission.type !== 'other'}
                    multiline
                    rows={7}
                    onChange={handleChange}
                    onBlur={(e) => { if (e.target.value !== submission.description) saveSubmission(e.target.name, e.target.value)} }
                  />
                )}
              </form>
            )}
          </Formik>
        </InfoFields>
      </InfoContainer>
    </ErrorBoundary>
  )
}

export default GeneralInfo
