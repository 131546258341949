import config_prod from './ConfigProd'
import config_dev from './ConfigDev'

const ConfigResolver = {
  config: () => {
    let stageName = 'production'
    return ['dev', 'development'].includes(process.env.NODE_ENV) || stageName === 'dev' ? config_dev : config_prod
  }
}

export default ConfigResolver
