import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { default as MUITable } from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { map, find } from 'lodash'
import { Arrow } from '../../../../components/Icons'
import Pagination from '../../../../components/Pagination'

const useStyles1 = makeStyles({
  tableCell: {
    fontFamily: 'Hind',
    fontSize: 16,
    color: '#000000',
    borderBottom: 'unset'
  },
  tableSortLabel: {
    overflow: 'unset',
    paddingTop: 4
  },
  tableRow: {
    height: 40
  }
})

function TableHeader(props) {
  const classes = useStyles1()
  const { order, orderBy, columns, filters, setFilters  } = props

  return (
    <TableHead className={classes.tableHead}>
      <TableRow
        className={classes.tableRow}
      >
        {columns.map(row => (
          <TableCell
            className={classes.tableCell}
            style={{minWidth:row.width, maxWidth:row.width}}
            key={row.id}
            align='left'
            padding='none'
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableSortLabel}
              active={orderBy === row.id}
              direction={order}
              IconComponent={props => (
                <Arrow color='#000000' scale={0.75} style={{ transform: order === 'asc' ? 'rotate(180deg)' : 'none', padding: '0 5px' }} className='MuiSvgIcon-root MuiTableSortLabel-icon' />
              )}
              onClick={() => setFilters({...filters, ordering: `${order === 'asc' ? '-' : ''}${[row.id]}`})}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles2 = makeStyles(theme => ({
  root: {
    width: 1220,
    marginTop: theme.spacing(3),
    margin: '0 auto'
  },
  paper: {
    width: 1220,
    marginBottom: theme.spacing(2),
    maxHeight: 550,
    boxShadow: 'unset'
  },
  table: {
    minWidth: 750,
    maxHeight: 492
  },
  tableWrapper: {
    overflowX: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 55,
    width: '100vw',
    '&:nth-child(even)': {
      background: '#FAFAFA'
    }
  },
  tableCell: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Hind',
    fontSize: 14,
    color: '#404040',
    borderBottom: 'unset',
    marginLeft: 10,
  }
}));

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Table = (props) => {
  const classes = useStyles2();
  const { rowsPerPage, columns, rows, total, filters, setFilters } = props
  const getOrder = (filters) => {
    return filters.ordering.charAt(0) === '-' ? 'desc' : 'asc'
  }

  const getOrderBy = (filters) => {
    return getOrder(filters) === 'desc' ? filters.ordering.substring(1) : filters.ordering
  }
  
  return (
    <div className={classes.root}>
      <PaginationContainer>
        <Pagination
          currentPage={filters.page}
          totalPages={Math.ceil(total / rowsPerPage)}
          totalSubmissions={total}
          prevOnClick={() => filters.page !== 1 ? setFilters({...filters, page: filters.page - 1}) : null}
          nextOnClick={() => filters.page !== Math.ceil(total / rowsPerPage) ? setFilters({...filters, page: filters.page + 1}) : null}
        />
      </PaginationContainer>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <MUITable
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
          >
            <TableHeader
              classes={classes}
              order={getOrder(filters)}
              orderBy={getOrderBy(filters)}
              rowCount={rows.length}
              columns={columns}
              filters={filters}
              setFilters={setFilters}
            />
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}        
                    key={row.id}
                    className={classes.tableRow}
                  >
                    {map(Object.keys(row), (r, index) => (
                      <TableCell
                        key={index}
                        className={classes.tableCell}
                        padding='none'
                        style={{ maxWidth: find(columns, { id: r }).width, minWidth: find(columns, { id: r }).width }}
                        align="left"
                      >
                        <Link
                          style={{color: '#000000', textDecoration: 'none'}}
                          to={`/submissions/submission/${row.id}`}
                        >
                          {row[r]}
                        </Link>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
              }
            </TableBody>
          </MUITable>
        </div>
      </Paper>
    </div>
  )
}

export default Table