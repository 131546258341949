import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { map } from 'lodash'
import { ShopItem } from './components/ShopItem'
import { isAdmin } from '../../utils'

const Page = styled.div`
  width: 1190px;
  margin: 0 auto;
`

const Header = styled.div`
  font-size: 43px;
  font-family: 'Hind';
  color: #000000;
  letter-spacing: 4.78px;
  text-transform: uppercase;
  line-height: 43px;
  text-align: center;
  margin: 50px auto;
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const ShopBuilder = (props) => {
  const [isLoading, setLoading] = useState(true)
  const [shopData, setShopData] = useState({})
  const admin = isAdmin(props.user)
  
  if(!admin) this.props.history.push(`/submissions/dashboard/${props.user.email}`)

  useEffect(() => {
    const fetchData = () => {
      axios.get(`https://www.stylemepretty.com/api/v2/submissions/${props.match.params.id}/get-shop-data`)
        .then((res) => {
          setShopData(res.data.data)
          setLoading(false)
        })
    }
    fetchData()
  }, [props.match.params.id])

  if (isLoading) return <div />
  
  return (
    <Page>
      <Header>Shop Builder</Header>
      <Body>
        {map(shopData, (item, index) => {
          return (
            <ShopItem
              key={index}
              item={item}
              submissionId={props.match.params.id}
            />
          )
        })}
      </Body>
    </Page>
  )
}