import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../../../../../../components/Modal'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'
import Button from '../../../../../../components/Button'

const Container = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`

const Header = styled.div`
  font-family: Hind;
  font-size: 20px;
  font-weight: bold;
`

const Text = styled.div`
  font-family: Hind;
  font-size: 14px;
  text-align: center;
  margin: 30px auto;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const DeleteImagesModal = ({ handleClose, deleteAllImages, open }) => {
  const [clicked, setClicked] = useState(false)

  return (
    <ErrorBoundary>
      <Modal open={open} handleClose={handleClose}>
        <Container>
          <Header>Delete All Images</Header>
          <Text>Are you sure you wish to remove all images from this submission?</Text>
          <ButtonsContainer>
            <Button 
              showLoader={clicked} 
              width={180} 
              text='Delete' 
              onClick={() => {
                setClicked(true)
                deleteAllImages()
              }} />
            <Button width={180} text='Cancel' onClick={() => handleClose()} />
          </ButtonsContainer>
        </Container>
      </Modal>
    </ErrorBoundary>
  )
}

export default DeleteImagesModal