import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import axios from 'axios'
import { resizeImage } from '../../../../utils/index'
import TextField from '../../../../components/TextField/TextField'
import TextArea from '@stylemepretty/smp-react-components/textarea'
import Button from '../../../../components/Button'

const Item = styled.div`
  width: 535px;
  margin-bottom: 50px;
  border: 3px solid #f8efeb;
  padding: 15px;
  height: auto;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 175px;
`

const Image = styled.div`
  width: 175px;
  height: 175px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const TopTextFields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`


export const ShopItem = ({item, submissionId}) => {
  const { id, image, name, url, description, price } = item

  const onSubmit = (values) => {
    axios.post(`https://www.stylemepretty.com/api/v2/submissions/set-shop-data`, {
      submission_id: submissionId,
      id: values.id,
      name: values.name,
      url: values.url,
      description: values.description,
      price: values.price
    })
  }

  return (
    <Item>
      <Formik
        initialValues={{
          id: id,
          name: name || '',
          url: url || '',
          description: description || '',
          price: price || ''
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values)
          resetForm(values)
        }}
      >
        {({ values, handleChange, handleSubmit, dirty, resetForm }) => (
          <form>
            <TopContainer>
              <Image style={{backgroundImage: `url(${resizeImage(image, 400)})`}} />
              <TopTextFields>
                <TextField
                  name='name'
                  label='Name'
                  onChange={handleChange}
                  height={45}
                  style={{ width: 350 }}
                  value={values.name}
                />
                <TextField
                  name='url'
                  label='URL'
                  onChange={handleChange}
                  height={45}
                  style={{ width: 350 }}
                  value={values.url}
                />
                <TextField
                  name='price'
                  label='Price'
                  onChange={handleChange}
                  height={45}
                  style={{ width: 350 }}
                  value={values.price}
                />
              </TopTextFields>
            </TopContainer>
            <TextArea
              name='description'
              label='Description'
              onChange={handleChange}
              rows={3}
              value={values.description}
              fullWidth
              style={{ marginTop: 20, resize: 'none!important' }}
            />
            <Button
              disabled={!dirty}
              text='Save'
              onClick={handleSubmit}
              style={{ float: 'right', marginTop: 13 }}
            />
          </form>
        )}
      </Formik>
    </Item>
  )        
}