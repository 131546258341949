import React from 'react'

const StarFull = ({ color, size, style, onClick, className, variant }) => (
  <svg
    className={className}
    style={style}
    onClick={onClick}
    size={size}
    color={color}
    variant={variant}
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
  >
    <g id="Search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="search-/-results-/-products-/-desktop"
        transform="translate(-602.000000, -322.000000)"
        fill="currentColor"
      >
        <g id="table-/-item-row" transform="translate(436.000000, 270.000000)">
          <g
            id="indicator-/-item-rating"
            transform="translate(136.000000, 51.000000)"
          >
            <g id="icon-/-star-icon-/-star-/-filled">
              <g transform="translate(30.000000, 1.000000)">
                <path
                  d="M9.27584183,7.59712065 L9.9576101,11.4079605 C10.0176954,11.7438159 9.66458181,12.0014104 9.3631105,11.8416449 L6.07944165,10.1014558 L2.84755476,11.945282 C2.55086648,12.1145458 2.1896188,11.8673312 2.23996587,11.5294874 L2.8108057,7.69898157 L0.121824484,5.03862293 C-0.115312344,4.80401028 0.012783252,4.39947567 0.341740134,4.34411433 L3.98125785,3.73160678 L5.56561534,0.240141973 C5.70856605,-0.0748799665 6.15363564,-0.0814500635 6.30582281,0.229215064 L7.99040737,3.66801797 L11.6453636,4.16454035 C11.9761591,4.20947861 12.1168862,4.61023828 11.8868097,4.85212523 L9.27584183,7.59712065 Z"
                  id="icon-/-star-/-filled"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default StarFull
