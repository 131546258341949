import * as Yup from 'yup'

export function adminHeaderOptions (user) {
  return [
    {
      name: 'Sign Out',
      href: '//www.stylemepretty.com/login?action=logout&redirect_to=https://www.stylemepretty.com'
    }, {
      name: 'Favorites',
      href: `//www.stylemepretty.com/dashboard/${user.username}/favorites/`
    }, {
      name: 'Boards',
      href: `//www.stylemepretty.com/dashboard/${user.username}/boards/`
    }, {
      name: 'Settings',
      href: `//www.stylemepretty.com/dashboard/${user.username}/settings/`
    }, {
      name: 'Wordpress',
      href: '//www.stylemepretty.com/wp-admin'
    }
  ]
}

export function userHeaderOptions (user) {
  return [
    {
      name: 'Sign Out',
      href: '//www.stylemepretty.com/login?action=logout&redirect_to=https://www.stylemepretty.com'
    }, {
      name: 'Favorites',
      href: `//www.stylemepretty.com/dashboard/${user.username}/favorites/`
    }, {
      name: 'Settings',
      href: `//www.stylemepretty.com/dashboard/${user.username}/settings/`
    }
  ]
}

export const seasonTypes = [
  {name: 'Fall', value: 1588},
  {name: 'Winter', value: 1162},
  {name: 'Spring', value: 2549},
  {name: 'Summer', value: 722}
]

export const submissionTypes = [
  {name: 'Real Wedding', value: 'real-wedding'},
  {name: 'Living', value: 'living'},
  {name: 'Sponsored', value: 'sponsored'},
  {name: 'Engagement', value: 'engagement'},
  {name: 'Inspiration Shoot', value: 'inspiration-shoot'},
  {name: 'Workshop', value: 'workshop'},
  {name: 'Other', value: 'other'}
]

export const submissionStatus = [
  {name: 'Started', value: 'started'},
  {name: 'Review', value: 'review'},
  {name: 'Denied', value: 'denied'},
  {name: 'Accepted', value: 'accepted'},
  {name: 'Scheduled', value: 'scheduled'},
  {name: 'Written', value: 'written'},
  {name: 'Published', value: 'published'},
  {name: 'Quick', value: 'quick'},
  {name: 'Accepted Quick', value: 'accepted-quick'},
  {name: 'Completed Quick', value: 'completed-quick'},
  {name: 'Social', value: 'social'},
  {name: 'Social Awaiting Permission', value: 'social-awaiting-permission'},
  {name: 'Social Denied', value: 'social-denied'},
  {name: 'Hold', value: 'hold'},
  {name: 'Unpublished', value: 'unpublished'},
  {name: 'Purchased', value: 'purchased'},
  {name: 'Deleted', value: 'trash'}
]

export const userStatus = [
  {name: 'Incomplete', value: 'started'},
  {name: 'Review', value: 'review'},
  {name: 'Denied', value: 'denied'},
  {name: 'Accepted', value: 'accepted'},
  {name: 'Scheduled', value: 'scheduled'},
  {name: 'Published', value: 'published'}
]

export const publications = [
  {name: 'Weddings', value: 'weddings'},
  {name: 'Living', value: 'living'}
]

export const submissionUse = [
  {name: 'Front Page', value: 'front-page'},
  {name: 'Facebook', value: 'facebook'},
  {name: 'Gallery', value: 'gallery'},
  {name: 'Little Black Book Blog', value: 'little-black-book-blog'},
  {name: 'Living', value: 'living'}
]

export const submissionPostType = [
  {name: 'Full', value: 'full'},
  {name: 'Mini', value: 'mini'},
  {name: 'Photoshoot', value: 'photoshoot'},
  {name: 'Engagement', value: 'engagement'},
  {name: 'DIY', value: 'diy'},
  {name: 'Behind the Scene', value: 'behind-the-scene'}
]

export const statusToEmail = [
  {status: 'accepted', template: 'accept-main-page'},
  {status: 'accepted-quick', template: 'accept-quick-submit-front-page'},
  {status: 'social-awaiting-permission', template: 'social-only'}
]

export const creditSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  url: Yup.string().matches(/((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/)
})

export const imageRequirements = [
  {
    type: 'real-wedding',
    count: 100
  }, {
    type: 'sponsored',
    count: 1
  }, {
    type: 'engagement',
    count: 50
  }, {
    type: 'inspiration-shoot',
    count: 75
  }, {
    type: 'workshop',
    count: 75
  }, {
    type: 'other',
    count: 15
  }, {
    type: 'default',
    count: 15
  }
]

export const defaultImage = 'https://is.stylemepretty.com/uploads/frontend/components/assets/default-image.png'
