import React, { useState, useEffect } from 'react'
import { map, indexOf, find, get, isNull } from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Delete } from '../../../../../../components/Icons'
import { resizeImage } from '../../../../../../utils'

const SortableItem = SortableElement(({lead, saveSubmission, sectionIndex, imageIndex, additionalInfo, id, value, style}) => {
  const [isHovered, setIsHovered] = useState(false)
  const deleteImage = (lead, id, additionalInfo, sectionIndex, imageIndex, saveSubmission) => {
    let info = additionalInfo
    if (lead) {
      info.leadImage = null
    } else {
      const images = additionalInfo.sections[sectionIndex].images[imageIndex].images
      let index = indexOf(images, id)
      info.sections[sectionIndex].images[imageIndex].images.splice(index, 1)
    }
    saveSubmission(info)
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ ...style, position: 'relative', display: 'inline-block' }}
    >
      <img draggable={false} style={{ ...style, margin: 0 }} src={resizeImage(value, 300)} alt='' />
      {isHovered && (
        <Delete 
          style={{ cursor: 'pointer', position: 'absolute', top: -10, right: -5 }}
          onClick={() => deleteImage(lead, id, additionalInfo, sectionIndex, imageIndex, saveSubmission)}
          scale={2}
          color='#ECB595'
        />
      )}
    </div>
  )
})

const SortableList = SortableContainer(({lead, saveSubmission, sectionIndex, imageIndex, additionalInfo, images, gallery}) => {
  return (
    <div>
      {map(images, (image, i) => 
        <SortableItem
          lead={lead}
          disabled={lead || false}
          saveSubmission={saveSubmission}
          sectionIndex={sectionIndex}
          imageIndex={imageIndex}
          additionalInfo={additionalInfo}
          id={image}
          value={`https://smp-is.stylemepretty.com/${get(gallery, 'path')}/${find(gallery.images, ['id', image]).filename}`}
          style={{ width: 141.66, marginTop: 10, marginLeft: i % 3 === 1 ? 10 : 0, marginRight: i % 3 === 1 ? 10 : 0 }}
          index={i}
          key={i}
        />
      )}
    </div>
  )
})

const sortImages = (saveSubmission, sectionIndex, imageIndex, additionalInfo, images) => {
  let info = additionalInfo
  info.sections[sectionIndex].images[imageIndex].images = images
  saveSubmission(info)
}

const ImageContainer = ({ sectionIndex, imageIndex, gallery, saveSubmission, additionalInfo, lead }) => {
  const [items, setItems] = useState(lead ? (isNull(additionalInfo.leadImage) ? [] : [get(additionalInfo, 'leadImage')]) : get(additionalInfo, `sections[${sectionIndex}].images[${imageIndex}].images`, []))
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if(oldIndex !== newIndex) {
      setItems(arrayMove(items, oldIndex, newIndex))
      sortImages(saveSubmission, sectionIndex, imageIndex, additionalInfo, arrayMove(items, oldIndex, newIndex))
    }
  }
  useEffect(() => {
    setItems(lead ? (isNull(additionalInfo.leadImage) ? [] : [get(additionalInfo, 'leadImage')]) : get(additionalInfo, `sections[${sectionIndex}].images[${imageIndex}].images`, []))
  }, [lead, additionalInfo, sectionIndex, imageIndex])
  if (items.length) {
    return (
      <SortableList
        lead={lead}
        saveSubmission={saveSubmission}
        sectionIndex={lead ? null : sectionIndex}
        imageIndex={lead ? null : imageIndex}
        additionalInfo={additionalInfo}
        images={items}
        gallery={gallery}
        pressDelay={150}
        axis='xy'
        onSortEnd={onSortEnd}
      />
    )
  }
  return <div />
}

export default ImageContainer