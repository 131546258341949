import React from 'react'
import styled from 'styled-components'
import { Arrow } from '../Icons'
import ErrorBoundary from '../ErrorBoundary'
import { isUndefined } from 'lodash'

const Container = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TotalSubmissions = styled.div`
  font-family: Hind;
  font-size: 14px;
  color: #ecb697;
  letter-spacing: 0;
  text-align: center;
`

const PaginationContainer = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const PaginateContainer = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PageInformation = styled.div`
  font-family: Hind;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
`

const ViewAll = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
`

const Pagination = ({ style, currentPage, totalPages, prevOnClick, nextOnClick, viewAllOnClick, totalSubmissions }) => {
  return (
    <ErrorBoundary>
      <Container>
        {!isUndefined(totalSubmissions) && (
          <TotalSubmissions>{`Total: ${totalSubmissions}`}</TotalSubmissions>
        )}
        <PaginationContainer style={style}>
          <PaginateContainer>
            <Arrow scale={1} color='#000000' onClick={prevOnClick} style={{transform: 'rotate(90deg)', cursor: 'pointer'}} />
            <PageInformation>{`Page ${currentPage} of ${totalPages}`}</PageInformation>
            <Arrow scale={1} color='#000000' onClick={nextOnClick} style={{transform: 'rotate(270deg)', cursor: 'pointer'}} />
          </PaginateContainer>
          {viewAllOnClick && <ViewAll>View All</ViewAll>}
        </PaginationContainer>
      </Container>
    </ErrorBoundary>
  )
}

export default Pagination