import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { imageUrl } from '../../../../../../utils'
import { defaultImage } from '../../../../../../constants'

const SubmissionContainer = styled(Link)`
  width: 100%;
  height: 100px;
  background-color: ${props => !(props.index % 2) ?  '#FAFAFA' : '#FFFFFF'};
  margin-bottom: 0;
  cursor: pointer;
`

const SubmissionInnerContainer = styled.div`
  width: 1220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  margin: 0 auto;
`

const SubmissionImage = styled.div`
  position: relative;
  background-image: ${props => props.image || 'none'};
  width: 72px;
  height: 72px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Value = styled.div`
  font-family: Hind;
  font-size: 18px;
  color: #404040;
  letter-spacing: 0;
  line-height: 30px;
  text-transform: capitalize;
  width: ${props => props.width};
  margin-left: ${props => props.marginLeft || 0};
  padding-right: ${props => props.marginLeft || 0};
`


const SubmissionList = ({ submission, view, index }) => {

  return (
    <SubmissionContainer to={`/submissions/submission/${submission.id}`} view={view} index={index}>
      <SubmissionInnerContainer>
        <SubmissionImage image={submission.image ? `url(${imageUrl(submission.image.path, '555')})` : `url(${defaultImage})`} />    
        <Value marginLeft='80px' width='520px'>{submission.title}</Value>
        { /* {!isNull(submission.order) && get(submission, 'order', []).length > 0 && (
          <Value width='125px'>{submission.image_count}</Value>
        )} */ }
        <Value width='175px'>{submission.type === 'living' ? 'SMP Living' : 'SMP Weddings'}</Value>
        <Value width='165px'>{submission.status}</Value>
      </SubmissionInnerContainer>
    </SubmissionContainer>
  )
}

export default SubmissionList