import React from 'react'
import styled from 'styled-components'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const DeleteModalContainer = styled.div`
  width: 240px;
  height: 155px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
`

const HeaderText = styled.div`
  font-size: 20px;
  font-family: Hind;
`

const BodyText = styled.div`
  font-size: 16px;
  font-family: Hind;
  font-weight: 300;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const CancelText = styled.div`
  font-size: 16px;
  font-family: Hind;
  cursor: pointer;
  font-weight: 300;
  color: #ECB595;
  margin-left: 25px;
`
const DeleteModal = ({ open, handleClose, deleteItem, item, setIsHovered, headerText, bodyText, }) => (
  <ErrorBoundary>
    <Modal open={open} handleClose={handleClose}>
      <DeleteModalContainer>
        <HeaderText>{headerText}</HeaderText>
        <BodyText>{bodyText}</BodyText>
        <ButtonsContainer>
          <CancelText onClick={handleClose}>Cancel</CancelText>
          <Button 
            width={120}
            text='Delete'
            onClick={() => {
              deleteItem(item)
              handleClose()
              setIsHovered(false)
            }} 
          />
        </ButtonsContainer>
      </DeleteModalContainer>
    </Modal>
  </ErrorBoundary>
)

export default DeleteModal