import React, { useState } from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../ErrorBoundary'
import { DefaultAvatar } from '../Icons'


const UserContainer = styled.div`
  display: inline-block;
  float: right;
  height: 65px;
  letter-spacing: .5px;
  position: absolute;
  right: 0px;
  background: #fff;
  transition: width .3s ease-in-out;
`

const AvatarContainer = styled.span`
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  top: 7px;
  cursor: pointer;
  position: relative; 
  vertical-align: sub;
`

const AvatarImage = styled.img`
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #F8F4EE;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const UserMenu = styled.div`
  width: calc(100% - 50px);
  float: right;
  line-height: 65px;
  text-decoration: none;
  color: #221f1f;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: ${(props) => props.isOpen ? 'block' : 'none'};
`

const UserMenuLink = styled.a`
  display: block;
  float: right;
  margin-left: 20px;
  font-family: Hind;
  line-height: 65px;
  text-decoration: none;
  color: #221f1f;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  outline: none;

  &:last-child {
    margin-left: 0px;
  }
`

const AvatarMenu = ({ menuItems, image }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <ErrorBoundary>
      <UserContainer onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} style={{ width: isOpen ? (menuItems.length > 3 ? '446px' : '273px') : '40px' }}>
        <AvatarContainer>
          {image ? (
            <AvatarImage style={{backgroundImage: `url(${image})`}} />
          ) : (
            <DefaultAvatar scale={4} stroke='#ECB697' fill='#FFF' /> 
          )}
        </AvatarContainer>
        <UserMenu isOpen={isOpen}>
          {menuItems.map(function(link, i) {
            return <UserMenuLink key={i} href={link.href}>{link.name}</UserMenuLink>
          })}
        </UserMenu>
      </UserContainer>
    </ErrorBoundary>
  )
}

export default AvatarMenu