import axios from 'axios'
import ConfigResolver from './ConfigResolver'
// import { sendDiscordError } from '../utils'


const config = ConfigResolver.config()

const requestHandler = (request) => {
  request.headers['Authorization'] = `JWT ${sessionStorage.getItem('token')}`
  return request
}

const errorHandler = (error) => {
  console.log(error)
  // sendDiscordError('Submissions Server', error.message, error.url)
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  return response
}

const axiosInstance = axios.create({
  baseURL: `${config.apiEndpoint}/${config.apiVersion}`
})

axiosInstance.interceptors.request.use(
  request => {
    return requestHandler(request)
  }
)

axiosInstance.interceptors.response.use(
  response => {
    return successHandler(response)
  }, 
  error => {
    return errorHandler(error)
  }
)

export default axiosInstance

