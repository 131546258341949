import React, { useState } from 'react'
import styled from 'styled-components'
import Select from '../Select'
import TextField from '../TextField'
import Modal from '../Modal'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Formik } from 'formik'
import Button from '../Button'
import ErrorBoundary from '../ErrorBoundary'
import { find } from 'lodash'
import moment from 'moment'
import MD5 from 'md5-es'

const EmailModalContainer = styled.div`
  width: 1060px;
  padding: 65px 80px 115px;
`

const EmailModalToLabel = styled.div`
  font-family: Hind;
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 35px;
`

const EmailModalToEmail = styled.span`
  color: #ecb697;
`

const EmailModal = ({ vendorEmail, fromEmail, templates, emailSubmitter, open, handleClose, submissionId }) => {
  const [templateName, setTemplateName] = useState('')

  return (
    <ErrorBoundary>
      <Modal open={open} handleClose={handleClose}>
        <EmailModalContainer>
          <Formik
            initialValues={{ template: '', title: `Submission #${submissionId}`, emailTo: vendorEmail, 'emailFrom': fromEmail, message: '' }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <form>
                <EmailModalToLabel>Email To: <EmailModalToEmail>{vendorEmail}</EmailModalToEmail></EmailModalToLabel>
                <Select
                  options={templates}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value)
                    setFieldValue('message', e.target.value.replace(/{year}/g, moment().format('YYYY')).replace(/{public_link}/g, `https://www.stylemepretty.com/submissions/submission/${submissionId}/${MD5.hash('9cjJhA8' + submissionId)}`).replace(/{submissionUrl}/g, `https://www.stylemepretty.com/submissions/submission/${submissionId}`))
                    setTemplateName(find(templates, ['value', e.target.value]).name)
                  }}
                  name='template'
                  style={{marginBottom: 20, width: 420}}
                  value={values.template}
                  label='Template'
                  input={<OutlinedInput labelWidth={45} name="template" id="template" />}
                />
                <TextField
                  id='title'
                  name='title'
                  onChange={handleChange}
                  value={values.title}
                  label='Email Title'
                  variant='outlined'
                  style={{ width: '1060px', marginBottom: 20 }}
                  fullWidth
                  height={46}
                />
                <TextField
                  id='emailTo'
                  name='emailTo'
                  onChange={handleChange}
                  value={values.emailTo}
                  label='Email To'
                  variant='outlined'
                  style={{ width: '1060px', marginBottom: 20 }}
                  fullWidth
                  height={46}
                />
                <TextField
                  id='emailFrom'
                  name='emailFrom'
                  onChange={handleChange}
                  value={values.emailFrom}
                  label='Email From'
                  variant='outlined'
                  style={{ width: '1060px', marginBottom: 20 }}
                  fullWidth
                  height={46}
                />
                <TextField
                  id='message'
                  name='message'
                  onChange={handleChange}
                  value={values.message}
                  label='Message'
                  variant='outlined'
                  style={{ width: '1060px', marginBottom: 20 }}
                  fullWidth
                  multiline
                  rows={14}
                />
                <Button
                  text='Send Email'
                  onClick={() => {
                    emailSubmitter(values, templateName)
                    handleClose()
                  }}
                  disabled={!values.message || !values.emailFrom || !values.emailTo || !values.title}
                  style={{ float: 'right', marginRight: 0 }}
                />
              </form>
            )}
          </Formik>
        </EmailModalContainer>
      </Modal>
    </ErrorBoundary>
  )
}

export default EmailModal