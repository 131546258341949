import { map, includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Image from './components/Image'
import { imageUrl } from '../../../../utils'

const ImageGridContainer = styled.div`
  width: 460px;
  margin-left: -10px;
  display: flex;
  flex-direction: row;
  margin-right: 145px;
  padding-right: 5px;
  max-height: 85vh;
  overflow-y: scroll;
`

const ImageGridRow = styled.div`
  width: 150px;
  margin-left: 10px;
`

const getRows = (images) => {
  let row1 = [], row2 = [], row3 = []
  map(images, (image, index) => {
    if (index % 3 === 0) row1.push(image)
    else if (index % 3 === 1) row2.push(image)
    else row3.push(image)
  })

  return [row1, row2, row3]
}


const ImageGrid = ({ gallery, additionalInfo, usedImages, saveSubmission, images, createBoard }) => {
  const rows = getRows(images)
  return (
    <ImageGridContainer>
      {map(rows, (row, index) => (
        <ImageGridRow key={index}>
          {map(row, (image, i) => (
            <Image
              isUsed={includes(usedImages, image.id)}
              saveSubmission={saveSubmission}
              additionalInfo={additionalInfo}
              image={image}
              key={i}
              src={imageUrl(`/${gallery.path}/${image.filename}`, 250)}
              createBoard={createBoard}
            />
          ))}
        </ImageGridRow>
      ))}
    </ImageGridContainer>
  )
}

export default ImageGrid
