import { map } from 'lodash';
import React, { useRef, useEffect } from 'react'
import ReactQuill from 'react-quill'
import canUseDom from 'can-use-dom'
import styled from 'styled-components'
import 'react-quill/dist/quill.snow.css'

if (canUseDom) require('./style.scss')

const QuillContainer = styled.div`

`

function insertVendor(args) {
  const value = JSON.parse(args);
  const cursorPosition = this.quill.getSelection().index;
  const html = `<a href="${value.url}" rel="noopener noreferrer" target="_blank">${value.name}</a>`
  if (this.quill.getSelection().length > 0) this.quill.deleteText(this.quill.getSelection().index, this.quill.getSelection().length)
  this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, html);
  this.quill.setSelection(cursorPosition + value.name.length);
}

const modules = (id, handler) => {
  return {
    toolbar: {
      container: `#toolbar-${id}`,
      handlers: {
        vendors: handler
      }
    }
  }
}

const formats = [
  'bold','italic','underline',
  'link',
  'vendors'
]

const RichText = ({ value, onChange, onBlur, style, id, credits }) => {
  
  const quillRef = useRef(null)
  const reactQuillRef = useRef(null)
  const unprivilegedEditor = quillRef.current ? reactQuillRef.current.makeUnprivilegedEditor(quillRef.current) : null
  const CustomToolbar = (id, credits) => (
    <div id={`toolbar-${id}`}>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
      </span>
      <span className='ql-formats'>
        <button className='ql-link' />
      </span>
      <span className='ql-formats'>
        <select label='Vendors' className='ql-vendors' defaultValue='' style={{ width: 120 }}>
          {map(credits, (credit, i) => (
            <option key={i} value={JSON.stringify({name: credit.name, url: credit.url})}>{credit.name}</option>
          ))}
        </select>
      </span>
    </div>
  )

  useEffect(() => {
    if(typeof reactQuillRef.current.getEditor !== 'function') return
    quillRef.current = reactQuillRef.current.getEditor()
  }, [credits])

  if(quillRef.current) quillRef.current.getModule('toolbar').container.addEventListener('mousedown', (e) => { e.preventDefault() })

  return (
    <QuillContainer style={{ ...style }}>
      {CustomToolbar(id, credits)}
      <ReactQuill
        placeholder='Field Text'
        theme='snow'
        style={{ width: '100%', height: '100%' }}
        ref={(el) => reactQuillRef.current = el}
        value={value}
        onBlur={(range, source) => onBlur(source, unprivilegedEditor.getHTML())}
        onChange={onChange}
        modules={modules(id, insertVendor)}
        formats={formats}
      />
    </QuillContainer>
  )
}

export default RichText
