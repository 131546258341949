import React from 'react'
import styled from 'styled-components'
import { get, find } from 'lodash'
import { defaultSectionData } from './constants'
import AdditionalInfoCustom from './components/AdditionalInfoCustom/AdditionalInfoCustom'
import AdditionalInfoDefault from './components/AdditionalInfoDefault/AdditionalInfoDefault'
import ErrorBoundary from '../../../../components/ErrorBoundary'


const InfoContainer = styled.div`
  width: 100%;
`

const InfoHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  width: 205px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3dbc7;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
`

const ButtonText = styled.div`
  font-size: 18px;
  text-align: center;
  font-family: Hind;
  letter-spacing: 0px;
  text-transform: none;
  color: #404040;
`

const createAdditionalInfo = (submission, saveSubmission, type) => {
  if(type === 'default') {
    const defaults = find(defaultSectionData, ['type', submission.type])
    saveSubmission({ type: 'default', leadImage: null, sections: get(defaults, 'sections', []), title: get(defaults, 'title', ''), subtitle: get(defaults, 'subtitle', '') })
  } else {
    saveSubmission({ type: 'custom', leadImage: null, sections: [], title: 'Custom Details', subtitle: null })
  }
}

const AdditionalInfo = ({ additionalInfo, saveSubmission, submission, credits, isAdmin, isUserView }) => {
  if (get(additionalInfo, 'type') === 'default') {
    return (
      <AdditionalInfoDefault
        additionalInfo={additionalInfo}
        saveSubmission={saveSubmission}
        submission={submission}
        credits={credits}
        isAdmin={isAdmin}
        isUserView={isUserView}
      />
    )
  } else if (get(additionalInfo, 'type') === 'custom') {
    return (
      <AdditionalInfoCustom
        additionalInfo={additionalInfo}
        saveSubmission={saveSubmission}
        submission={submission}
        credits={credits}
      />
    )
  } else if (isAdmin && !isUserView) {
    return (
      <ErrorBoundary>
        <InfoContainer>
          <InfoHeader>Additional Information</InfoHeader>
          <ButtonsContainer>
            <ButtonContainer>
              <ButtonText onClick={() => createAdditionalInfo(submission, saveSubmission, 'default')}>Use Defaults</ButtonText>
            </ButtonContainer>
            <ButtonContainer>
              <ButtonText onClick={() => createAdditionalInfo(submission, saveSubmission, 'custom')}>Custom Template</ButtonText>
            </ButtonContainer>
          </ButtonsContainer>
        </InfoContainer>
      </ErrorBoundary>
    )
  } else {
    return <div />
  }
}

export default AdditionalInfo
