export const generateId = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for ( var i = 0; i < 5; i++ ) result += characters.charAt(Math.floor(Math.random() * charactersLength))
  return result
}

export const defaultSectionData = [
  {
    type: 'real-wedding',
    sections: [
      {
        name: 'The Planning',
        fields: [
          {name: 'Wedding Colors', 'text': '', type: 'Required'},
          {name: 'Design / Vibe / Vision', 'text': '', type: 'Required'},
          {name: 'Proposal Story', 'text': '', type: 'Highly Encouraged'}
        ],
        images: [
          {name: 'Getting Ready', carousel: true, images: []},
          {name: 'Bridal Portraits', carousel: true, images: []},
          {name: 'Flat Lays', carousel: true, images: []},
          {name: 'First Look', carousel: true, images: []},
        ]
      }, {
        name: 'The Fashion',
        fields: [
          {name: 'Wedding Gown', 'text': '', type: 'Highly Encouraged'},
          {name: 'Bridesmaid Dresses', 'text': '', type: 'Highly Encouraged'},
          {name: 'Groomsman Attire', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Wedding Party', carousel: true, images: []},
          {name: 'Ceremony', carousel: true, images: []},
          {name: 'Cocktail Hour', carousel: true, images: []}
        ]
      }, {
        name: 'The Details',
        fields: [
          {name: 'Food & Drink', 'text': '', type: 'Required'},
          {name: 'Florals & Decor', 'text': '', type: 'Required'},
          {name: 'Wedding Cake', 'text': '', type: 'Required'},
          {name: 'Readings, Ceremony Music, Reception Songs', 'text': '', type: 'Optional'},
          {name: 'Special Detail #1', 'text': '', type: 'Optional'},
          {name: 'Special Detail #2', 'text': '', type: 'Optional'},
          {name: 'Special Detail #3', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Couple Portraits', carousel: true, images: []},
          {name: 'Reception', carousel: true, images: []},
          {name: 'Wedding Weekend Events', carousel: true, images: []}
        ]
      }
    ],
    title: 'Wedding Details',
    subtitle: 'Please fill in as many of the wedding details as possible. If picked for publication, we will use these details to create a full, robust story that our audience can quickly and easily peruse.'
  }, {
    type: 'engagement',
    sections: [
      {
        name: 'The Details',
        fields : [
          {name: 'Proposal Story', 'text': '', type: 'Required'},
          {name: 'Shoot Location', 'text': '', type: 'Required'},
          {name: 'Design / Vibe / Vision', 'text': '', type: 'Required'},
          {name: 'Outfit Details', 'text': '', type: 'Highly Ecouraged'},
          {name: 'Overall Style', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Our Favorites', carousel: true, images: []}
        ]
      }
    ],
    title: 'Engagement Details',
    subtitle: 'Please fill in as many of the engagement details as possible. If picked for publication, we will use these details to create a full, robust story that our audience can quickly and easily peruse.'
  }, {
    type: 'inspiration-shoot',
    sections: [
      {
        name: 'The Planning',
        fields : [
          {name: 'The Inspiration', 'text': '', type: 'Required'},
          {name: 'Wedding Colors', 'text': '', type: 'Required'},
          {name: 'The Venue', 'text': '', type: 'Required'}
        ],
        images: [
          {name: 'Beauty', carousel: true, images: []},
          {name: 'Flat Lays', carousel: true, images: []}
        ]
      }, {
        name: 'The Fashion',
        fields: [
          {name: 'Wedding Gown', 'text': '', type: 'Highly Ecouraged'},
          {name: 'Wedding Party', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Bridal Portraits', carousel: true, images: []},
          {name: 'Fashion', carousel: true, images: []}
        ]
      }, {
        name: 'The Details',
        fields: [
          {name: 'Florals & Decor', 'text': '', type: 'Required'},
          {name: 'Favorite Design Element', 'text': '', type: 'Highly Ecouraged'},
          {name: 'Tips To Pull Off The Look', 'text': '', type: 'Highly Ecouraged'},
          {name: 'DIY Moments', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Details', carousel: true, images: []},
          {name: 'Couple Portraits', carousel: true, images: []}
        ]
      }
    ],
    title: 'Styled Shoot',
    subtitle: 'Please fill in as many of the details as possible. If picked for publication, we will use these details to create a full, robust story that our audience can quickly and easily peruse.'
  }, {
    type: 'workshop',
    sections: [
      {
        name: 'The Planning',
        fields : [
          {name: 'The Inspiration', 'text': '', type: 'Required'},
          {name: 'Wedding Colors', 'text': '', type: 'Required'},
          {name: 'The Venue', 'text': '', type: 'Required'}
        ],
        images: [
          {name: 'Beauty', carousel: true, images: []},
          {name: 'Flat Lays', carousel: true, images: []}
        ]
      }, {
        name: 'The Fashion',
        fields: [
          {name: 'Wedding Gown', 'text': '', type: 'Highly Ecouraged'},
          {name: 'Wedding Party', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Bridal Portraits', carousel: true, images: []},
          {name: 'Fashion', carousel: true, images: []}
        ]
      }, {
        name: 'The Details',
        fields: [
          {name: 'Florals & Decor', 'text': '', type: 'Required'},
          {name: 'Favorite Design Element', 'text': '', type: 'Highly Ecouraged'},
          {name: 'Tips To Pull Off The Look', 'text': '', type: 'Highly Ecouraged'},
          {name: 'DIY Moments', 'text': '', type: 'Optional'}
        ],
        images: [
          {name: 'Details', carousel: true, images: []},
          {name: 'Couple Portraits', carousel: true, images: []}
        ]
      }
    ],
    title: 'Workshop',
    subtitle: 'Please fill in as many of the details as possible. If picked for publication, we will use these details to create a full, robust story that our audience can quickly and easily peruse.'
  }
]