import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import ConfigResolver from '../../../../../../config/ConfigResolver'
import { Trash } from '../../../../../../components/Icons'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'

const config = ConfigResolver.config()

const CreditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`

const CreditInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: 2px;
`
const CreditType = styled.div`
  font-family: Hind;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0;
  margin-right: 5px;
`
const CreditName = styled.a`
  text-decoration: none;
  color: #ECB595;
  font-weight: 300;
  font-family: Hind;
  font-size: 16px;
  letter-spacing: 0;
`

const CreditNameText = styled.div`
  color: #000;
  font-weight: 300;
  font-family: Hind;
  font-size: 16px;
  letter-spacing: 0;
`

const CreditVendor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Divider = styled.div`
  margin: 0 10px;
  font-size: 16px;
  font-weight: 300;
  font-family: Hind;
`

const CreditLink = styled.a`
  font-family: Hind;
  font-weight: 300;
  font-size: 16px;
  color: #ECB595;
`

const Credit = ({ credit, sortIcon, trashOnClick, isAdmin, isUserView}) => {
  const [copied, setCopied] = useState(false)

  return (
    <ErrorBoundary>
      <CreditContainer>
        {sortIcon}
        <Trash style={{height: 31, margin: '0px 15px 0px 12px', cursor: 'pointer'}} scale={1} color='#A0A0A0' onClick={trashOnClick} />
        <CreditInfo>
          <CreditType>{`${credit.type}: `}</CreditType>
          {credit.url && <CreditName target='_blank' href={credit.url}>{credit.name}</CreditName>}
          {!credit.url && <CreditNameText>{credit.name}</CreditNameText>}
          {credit.instagram && isUserView ? (
            <Fragment>
              <Divider>|</Divider>
              <CreditName target='_blank' href={`https://www.instagram.com/${credit.instagram}`}>{`@${credit.instagram}`}</CreditName>
            </Fragment>
          ) : null}
          {credit.vendor && isAdmin && !isUserView ? (
            <Fragment>
              <CreditVendor>
                <Divider>|</Divider>
                <CreditLink href={`${config.siteUrl}/vendor-profile/${credit.vendor}`}>Portfolio</CreditLink>
                <Divider>|</Divider>
                <CreditLink href={`${config.siteUrl}/wp-admin/admin.php?page=lbb_smp%2Flbb-edit-vendor.php&id=${credit.vendor}`}>Edit Vendor</CreditLink>
                {credit.url && (
                  <>
                    <Divider>|</Divider>
                    <CreditLink 
                      style={{ cursor: copied ? 'default' : 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(credit.url)
                        setCopied(true)
                        setTimeout(() => {
                          setCopied(false)
                        }, '2000')
                      }}
                    >
                      {copied ? 'Copied!' : 'Copy'}
                    </CreditLink>
                  </>
                )}
              </CreditVendor>
              {get(credit, 'isLbb', false) && <CreditNameText>&nbsp;{`(LBB)`}</CreditNameText>}
              {get(credit, 'isProfile', false) && <CreditNameText>&nbsp;{`(Profile)`}</CreditNameText>}

            </Fragment>
          ) : null}
        </CreditInfo>
      </CreditContainer>
    </ErrorBoundary>
  )
}

export default Credit