import React from 'react'
import styled from 'styled-components'


const HeaderContainer = styled.div`
  text-align: center;
  font-family: linotype-didot-roman;
  font-size: 30px;
  width: 100%;
`

const HeaderText = styled.span`
  text-align: center;
  padding: 0 20px;
`



const Header = ({ text, style }) => {
  return (
    <HeaderContainer style={{ ...style }}>
      <HeaderText>{text}</HeaderText>
    </HeaderContainer>
  )
}

export default Header
