import React, { useState } from 'react'
import styled from 'styled-components'
import { Delete } from '../../../../../../components/Icons'
import DeleteModal from '../../../DeleteModal'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 25px 15px;
  position: relative;
  width: 225px;
  height: 225px;
`

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 26px;
`;

const ImageInner = styled.div`
  width: 225px;
  height: 225px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 8px;
  position: relative;
  padding-bottom: 10px;
`

const ImageOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 225px;
	height: 225px;
  opacity: 0.4;
  background-color: white;
`

const Loader = styled.div`
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  :before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 42px;
    height: 42px;
    margin-top: -21px;
    margin-left: -21px;
    border-radius: 50%;
    border: 3px solid #c8c8c8;
    border-top-color: #81807b;
    animation: spinner .7s linear infinite;
  }
`

const ImageName = styled.div`
  background-color: #B8B8B8;
  width: 200px;
  border-radius: 75px;
  text-align: center;
  padding: 5px;
  color: #FFFFFF;
  font-family: Hind;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 240px;
`

const Image = ({style, withLoader, onClick, filename, deleteImage, Controls}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <ErrorBoundary>
      <ImageContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <ImageInner style={style} onClick={onClick}>
          {withLoader && <ImageOverlay onClick={onClick} />}
          {withLoader && <Loader />}
        </ImageInner>
        {Controls && (
          <ControlsContainer>
            <Controls isHovered={isHovered} />
          </ControlsContainer>
        )}
        {!withLoader && isHovered && <Delete onClick={() => setOpen(true)} style={{ cursor: 'pointer', position: 'absolute', right: -12, top: -12}} scale={2} color='#ECB595' />}
        {isHovered && !Controls && <ImageName>{filename}</ImageName>}
        {open && 
          <DeleteModal 
            open={open}
            handleClose={() => {
              setOpen(false)
              setIsHovered(false)
            }}
            setIsHovered={setIsHovered}
            deleteItem={deleteImage}
            headerText='Remove Image'
            bodyText='Are you sure you wish to remove this image?'
          />
        }
      </ImageContainer>
    </ErrorBoundary>
  )
}

export default Image
