import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import axios from 'axios'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { get, find, map, filter, values as ldvalues } from 'lodash'
import Credit from './components/Credit'
import TextField from '../../../../components/TextField'
import Autocomplete from '../../../../components/Autocomplete'
import { creditSchema } from '../../../../constants'
import ErrorBoundary from '../../../../components/ErrorBoundary'

import Sort from '@stylemepretty/smp-react-components/Icons/Sort'
import Add from '@stylemepretty/smp-react-components/Icons/Plus'

const CreditsContainer = styled.div`
  width: 100%;
  margin-top: 45px;
`
const CreditsHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: capitalize;
`

const CreditFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`

const CreditFields = styled.div`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
const CreditField = styled.div`
`

const CreditsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SingleCredit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DragHandle = sortableHandle(() => <Sort style={{height: 31, cursor: 'move'}} />)

const Credits = ({ submission, options, type, credits, onSortEnd, addCredit, deleteCredit, collection, isAdmin, isUserView }) => {
  const [vendors, setVendors] = React.useState([])
  const [current, setCurrent] = React.useState({})
  const [hasInsta, setHasInsta] = React.useState(false)
  const [hasEmail, setHasEmail] = React.useState(false)
  const SortableContainer = sortableContainer(({children}) => (
    <div>{children}</div>
  ))

  const updateOptions = (key) => {
    const opts = ldvalues(JSON.parse(find(options, ['option_name', key]).option_value))
    return map(opts, (o) => ({ text: o }))
  }
  
  const SortableItem = sortableElement(({value, submissionId, deleteCredit, isAdmin, isUserView}) => (
    <SingleCredit>
      <Credit
        sortIcon={<DragHandle />}
        credit={value}
        trashOnClick={(e) => {
          deleteCredit(value, submissionId, value.type.substring(2))
          setCurrent({...value, vendor: null})
          setHasInsta(get(value, 'instagram') ? true : false)
          setHasEmail(get(value, 'email') ? true : false)
        }}
        isAdmin={isAdmin}
        isUserView={isUserView}
      />
    </SingleCredit>
  ))

  const getVendorSuggestions = (value) => {
    if(value && value.length > 2) {
      axios
        .get(`https://www.stylemepretty.com/api/v2/submissions/vendors/search/${value}`)
        .then((res) => {
          setVendors(res.data.data.suggest.suggestions[0].options)
        })
    } else {
      setVendors([])
    }
  }

  return (
    <ErrorBoundary>
      <CreditsContainer>
        <CreditsHeader>{`${type} Credits`}</CreditsHeader>
        <Formik
          enableReinitialize
          validationSchema={creditSchema}
          validateOnChange={false}
          initialValues={{ type: current.type || '', name: current.name || '', url: current.url || '', vendorId: current.vendor || '', email: current.email || '', instagram: current.instagram || '' }}
          onSubmit={(values, { resetForm }) => {
            addCredit({
              archived: 0,
              is_unlinked: 0,
              submission: submission.id,
              category: `t:${type}`,
              type: values.type, 
              url: values.url, 
              name: values.name,
              vendor: values.vendorId,
              instagram: values.instagram,
              email: values.email
            })
            resetForm()
            setHasInsta(false)
            setHasEmail(false)
            setCurrent({})
          }}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur }) => (
            <form>
              <CreditFieldContainer>
                <CreditFields>
                  <CreditField>
                    <Autocomplete
                      name='type'
                      label={`${type} Type`}
                      onChange={(e) => setFieldValue('type', get(e, 'target.value', e))}
                      onSelect={(val) => setFieldValue('type', val)}
                      onBlur={handleBlur}
                      value={values.type}
                      style={{ width: 225 }}
                      options={updateOptions(`${type}_type`)}
                      showEmpty
                      showError={get(errors, 'type', false) && touched.type}
                    />
                  </CreditField>
                  <CreditField>
                    <Autocomplete
                      name='name'
                      label={`${type} Name`}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue('name', get(e, 'text', get(e, 'target.value')))
                        getVendorSuggestions(get(e, 'text', get(e, 'target.value')))
                      }}
                      onSelect={(val) => {
                        setFieldValue('name', val.text)
                        setFieldValue('url', val._source.website_url || '')
                        setFieldValue('vendorId', val._id)
                        setFieldValue('email', val._source.email || '')
                        setFieldValue('instagram', val._source.instagram || '')
                        setHasEmail(val._source.email ? true : false)
                        setHasInsta(val._source.instagram ? true : false)
                      }}
                      style={{ width: 225 }}
                      value={values.name}
                      options={vendors}
                      useObject
                      showError={get(errors, 'name', false) && touched.name}
                    />
                  </CreditField>
                  <CreditField>
                    <TextField
                      name='url'
                      label='URL'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      height={45}
                      style={{ width: 225 }}
                      value={values.url}
                      showError={get(errors, 'url', false) && touched.url}
                    />
                  </CreditField>
                  <CreditField>
                    <TextField
                      name='email'
                      label={`${type} Email`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      height={45}
                      style={{ width: 349.5, marginTop: 20 }}
                      value={values.email}
                      disabled={hasEmail}
                    />
                  </CreditField>
                  <CreditField>
                    <TextField
                      name='instagram'
                      label='Instagram Handle'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      height={45}
                      style={{ width: 349.5, marginTop: 20 }}
                      value={values.instagram}
                      disabled={hasInsta}
                    />
                  </CreditField>
                </CreditFields>
                <Add
                  onClick={() => handleSubmit()}
                  style={{cursor: 'pointer', color: 'rgb(236,182,151)'}}
                />
              </CreditFieldContainer>
            </form>
          )}
        </Formik>
        <CreditsSection>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {map(filter(credits, ['category', `t:${type}`]), (credit, index) => (
              <SortableItem isAdmin={isAdmin} isUserView={isUserView} key={`item-${index}`} collection={collection} index={index} deleteCredit={deleteCredit} submissionId={submission.id} value={credit} />
            ))}
          </SortableContainer>
        </CreditsSection>
      </CreditsContainer>
    </ErrorBoundary>
  )
}

export default Credits
