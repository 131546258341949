import React from 'react'

function scaleProps (n) {
  return {
    width: n * 10,
    height: n * 10
  }
}

const GridView = ({ color, scale, style, onClick, className }) => (
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard---Portfolio-Grid" transform="translate(-1250.000000, -324.000000)" fill={color}>
        <g id="Group-8" transform="translate(1250.000000, 324.000000)">
          <g id="Group-7">
            <g id="Group-3-Copy-2">
              <rect id="Rectangle-Copy-10" x="0" y="0" width="13" height="13" rx="1"></rect>
            </g>
            <g id="Group-3-Copy-4" transform="translate(0.000000, 17.000000)">
              <rect id="Rectangle-Copy-10" x="0" y="0" width="13" height="13" rx="1"></rect>
            </g>
            <g id="Group-3-Copy-3" transform="translate(17.000000, 0.000000)">
              <rect id="Rectangle-Copy-10" x="0" y="0" width="13" height="13" rx="1"></rect>
            </g>
            <g id="Group-3-Copy-5" transform="translate(17.000000, 17.000000)">
              <rect id="Rectangle-Copy-10" x="0" y="0" width="13" height="13" rx="1"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default GridView