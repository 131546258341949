import React from 'react'

function scaleProps (n) {
  return {
    width: n * 17.33,
    height: n * 9
  }
}

const Arrow = ({ color, scale, style, onClick, className }) => (
  <svg viewBox="0 0 52 27" xmlns="http://www.w3.org/2000/svg" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-174.000000, -932.000000)" stroke={color}>
        <g transform="translate(200.000000, 932.500000) rotate(270.000000) translate(-200.000000, -932.500000) translate(174.000000, 907.000000)">
          <polyline transform="translate(25.754477, 25.500000) rotate(-225.000000) translate(-25.754477, -25.500000) " points="43.289199 7.61805556 43.6364213 43.3819444 7.87253237 43.0347222"></polyline>
        </g>
      </g>
    </g>
  </svg>
)

export default Arrow