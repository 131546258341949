import React from 'react'

const StarEmpty = ({ color, size, style, onClick, className, variant }) => (
  <svg
    className={className}
    style={style}
    onClick={onClick}
    size={size}
    color={color}
    variant={variant}
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
  >
    <path
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
      id="icon-/-star-/-half-3"
      d="M 5.9316406,0 C 5.783481,0.00218713 5.6378816,0.0827234 5.5664062,0.24023438 L 3.9804688,3.7324219 0.34179688,4.34375 C 0.01284,4.3991114 -0.11604307,4.8044499 0.12109375,5.0390625 L 2.8105469,7.6992188 2.2402344,11.529297 c -0.050347,0.337844 0.3107335,0.58528 0.6074218,0.416015 l 3.2324219,-1.84375 3.2832031,1.740235 c 0.3014714,0.159765 0.6538348,-0.09774 0.59375,-0.433594 L 9.2753906,7.5976562 11.886719,4.8515625 c 0.230076,-0.2418869 0.08861,-0.642562 -0.242188,-0.6875 L 7.9902344,3.6679688 6.3066406,0.22851562 C 6.230547,0.07318307 6.0798003,-0.00218713 5.9316406,0 Z m 0.021484,1.3691406 1.3984375,2.8554688 c 0.060036,0.1225532 0.1772733,0.2062389 0.3125,0.2246094 L 10.742188,4.8671875 8.5390625,7.1835938 C 8.4492788,7.2779862 8.410652,7.4088727 8.4335938,7.5371094 L 9.0039062,10.724609 6.0680683,9.2268396 C 4.9365929,9.9213349 4.1219169,10.429448 3.0441265,10.993135 c 0,0 0.4423069,-3.4769517 0.4422016,-3.5361038 C 3.4860314,7.3011417 3.4982564,7.3136445 2.3007812,6.0546875 l -1.1152343,-1.171875 0.078125,-0.011719 c 0.043473,-0.00642 0.7490267,-0.1019483 1.5664062,-0.2128907 1.4370486,-0.1950495 1.487452,-0.2036947 1.5625,-0.2460937 0.042686,-0.024116 0.096654,-0.067628 0.1191407,-0.095703 0.6589417,-1.2880826 1.4414058,-2.9472655 1.4414058,-2.9472655 0,0 0.027444,-0.53356176 0,0 z"
    />
  </svg>
)

export default StarEmpty
