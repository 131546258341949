import React, { useState } from 'react'
import styled from 'styled-components'
import { Delete } from '../../../../../../components/Icons'
import DeleteModal from '../../../DeleteModal'

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 25px 15px;
  position: relative;
  width: 168.75px;
  height: 94.75px;
`
const VideoInner = styled.div`
  width: 168.75px;
  height: 94.75px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 10px;
`



const Video = ({style, onClick, deleteVideo}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <VideoContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <VideoInner style={style} onClick={onClick} />
      {isHovered && <Delete onClick={() => setOpen(true)} style={{ cursor: 'pointer', position: 'absolute', right: -12, top: -12}} scale={2} color='#ECB595' />}
      {open && 
        <DeleteModal 
          handleClose={() => {
            setOpen(false)
            setIsHovered(false)
          }}
          setIsHovered={setIsHovered}
          open={open}
          deleteItem={deleteVideo}
          headerText='Remove Video'
          bodyText='Are you sure you wish to remove this video?'
        />
      }
    </VideoContainer>
  )
}

export default Video