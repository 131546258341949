import React from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../../../constants'
import ImageContainer from '../ImageContainer'
import canUseDom from 'can-use-dom'
import { get } from 'lodash'
import { Checkbox } from '@material-ui/core'

if (canUseDom) require('./style.scss')

function Placement(props) {
  const { sectionIndex, imageIndex, gallery, saveSubmission, additionalInfo, lead } = props
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.IMAGE,
    drop: () => ({
      placementObject: lead ? 'lead' : [sectionIndex, imageIndex]
    }),
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  }), [imageIndex, lead])
  const handleChange = (additionalInfo, sectionIndex, imageIndex, saveSubmission) => {
    let info = additionalInfo
    const carousel = get(info, `sections[${sectionIndex}].images[${imageIndex}].carousel`, true)
    info.sections[sectionIndex].images[imageIndex].carousel = !carousel
    saveSubmission(info)
  }
  return (
    <div className='placement-container' ref={drop}>
      <div className='header-container'>
        <div className='header'>{lead ? 'Lead Image' : get(additionalInfo, `sections[${sectionIndex}].images[${imageIndex}].name`, '')}</div>
        {!lead && (
          <div className='checkbox-container'>
            <Checkbox
              name='slider'
              checked={get(additionalInfo, `sections[${sectionIndex}].images[${imageIndex}].carousel`, true)}
              onChange={() => handleChange(additionalInfo, sectionIndex, imageIndex, saveSubmission)}
            />
            <div className='checkbox-text'>Carousel</div>
          </div>
        )}
      </div>
      <div className='images-container'>
        {isOver && (
          <div className='dropzone'>
            <div className='dropzone-text'>{`Drag Here To Add To ${lead ? 'Lead Image' : get(additionalInfo, `sections[${sectionIndex}].images[${imageIndex}].name`, '')}`}</div>
          </div>
        )}
        <ImageContainer
          lead={lead}
          additionalInfo={additionalInfo}
          saveSubmission={saveSubmission}
          sectionIndex={sectionIndex}
          imageIndex={imageIndex}
          gallery={gallery}
        />
      </div>
    </div>
  )
}

export default Placement


