import React, { Component, Fragment } from 'react'
import canUseDom from 'can-use-dom'
import { Header, GeneralInfo, Credits, Images, Videos, AdditionalInfo } from './components'
import { map, isEmpty, get } from 'lodash'
import { AdminSidebar, UserSidebar } from './components/Sidebar'
import { isValid, lastSaved } from './utils'
import ErrorBoundary from '../../components/ErrorBoundary'
import { PostPreview } from './components/PostPreview'
import Statistics from './components/Sidebar/components/Statistics'

if (canUseDom) require('./single-submission.scss')

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isUserView: !this.props.isAdmin,
      postPreview: false
    }
    this.changeView = this.changeView.bind(this)
  }

  changeView () {
    this.setState({ isUserView: !this.state.isUserView })
  }

  render () {
    const { statistics, user, saveSubmission, saveSubmissionDetails, isLoading, submission, galleryImages, additionalInfo, credits, images, videos, options, deleteCredit, onSortEnd, isAdmin, downloadSubmission, emailSubmitter, submitForReview, galleryVendor, isLbb, isProfile, videoError, post, acceptSocial, declineSocial, nextSubmission, refreshPreview, workshopData } = this.props
    const { postPreview } = this.state
    const creditSections = ['vendor', 'venue']

    return (
      <ErrorBoundary>
        <div className='single-submission'>
          {!isLoading ? (
            <div>
              <div className='single-submission-header-container'>
                <Header
                  submission={submission}
                  isValid={isValid(submission, credits, images, videos, additionalInfo)}
                  lastSaved={lastSaved(submission.updated)}
                  isAdmin={isAdmin}
                  isUserView={this.state.isUserView}
                  userViewClick={this.changeView}
                  deleteSubmission={this.props.deleteSubmission}
                  downloadClick={() => downloadSubmission(submission.id)}
                  images={images}
                  user={user}
                  progress={this.props.loadingValue}
                  submitClick={submitForReview}
                  isLbb={isLbb}
                  isProfile={isProfile}
                  nextSubmission={nextSubmission}
                  nextSubmissionType={window.location.search === '?review=review' ? 'review' : (window.location.search === '?review=quick' ? 'quick' : null)}
                />
              </div>
              <div className='single-submission-body'>
                <div className='single-submission-body-container'>
                  <div className='single-submission-general-info-container'>
                    <GeneralInfo
                      submission={submission}
                      workshopData={workshopData}
                      saveSubmission={saveSubmission}
                      isUserView={this.state.isUserView}
                      isAdmin={isAdmin}
                    />
                  </div>
                  <div className='single-submission-credits-container'>
                    {map(creditSections, (section, index) => (
                      <Credits
                        key={`credits-${index}`}
                        submission={submission}
                        type={section}
                        credits={credits} 
                        options={options}
                        deleteCredit={deleteCredit}
                        addCredit={this.props.addCredit}
                        onSortEnd={onSortEnd}
                        collection={index}
                        isAdmin={isAdmin}
                        isUserView={this.state.isUserView}
                      />
                    ))}
                  </div>
                  {submission.id > 358705 && submission.status !== 'quick' && (
                    <div className='single-submission-additional-info-container'>
                      <AdditionalInfo
                        submission={submission}
                        additionalInfo={additionalInfo.data}
                        saveSubmission={saveSubmissionDetails}
                        isUserView={this.state.isUserView}
                        isAdmin={isAdmin}
                        credits={credits}
                      />
                    </div>
                  )}
                  {submission.status !== 'quick' && (
                    <>
                      <div className='single-submission-images-section'>
                        <Images
                          upload={this.props.uploadImageToS3}
                          text="Drag and drop to upload or click to select files"
                          onDrop={this.props.onDrop}
                          files={this.props.acceptedFiles}
                          denied={this.props.deniedFiles}
                          images={this.props.images}
                          submissionId={submission.id}
                          deleteImage={this.props.deleteImage}
                          deleteAllImages={this.props.deleteAllImages}
                          updateImageOrder={this.props.updateImageOrder}
                          user={user}
                          path={this.props.path}
                          isLoading={this.props.imagesLoading}
                          updateImageCredit={this.props.updateImageCredit}
                        />
                      </div>
                      {this.props.submission.status !== 'collection' && (
                        <Videos
                          videos={videos}
                          addVideo={this.props.addVideo}
                          deleteVideo={this.props.deleteVideo}
                          videoError={videoError}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className='single-submission-sidebar'>
                  {!isEmpty(statistics) && isAdmin && !this.state.isUserView && <Statistics statistics={statistics} userEmail={get(submission, 'submit_email')} />}
                  {isAdmin && !this.state.isUserView ? (
                    <AdminSidebar 
                      submission={submission}
                      options={options}
                      saveSubmission={saveSubmission}
                      notes={this.props.notes}
                      addNote={this.props.addNote}
                      colors={this.props.colors}
                      styles={this.props.styles}
                      settings={this.props.settings}
                      fieldHeight={46}
                      importOnClick={this.props.importSubmission}
                      emailSubmitter={emailSubmitter}
                      statistics={statistics}
                    />
                  ) : (
                    <Fragment>
                      <UserSidebar
                        submission={submission}
                        credits={credits}
                        videos={videos}
                        images={images}
                        fieldHeight={46}
                        submitClick={submitForReview}
                        isValid={isValid(submission, credits, images, videos, additionalInfo)}
                        vendor={galleryVendor}
                        post={post}
                        acceptSocial={acceptSocial}
                        declineSocial={declineSocial}
                        additionalInfo={additionalInfo}
                      />
                    </Fragment>
                  )}
                  {isAdmin && !this.state.isUserView && submission.id > 358705 && (
                    <div className='post-preview-container'>
                      {postPreview ? (
                        <div className='post-preview-buttons-container'>
                          <div onClick={() => this.setState({ postPreview : !postPreview })} className='post-preview-button-container'>
                            <div className='post-preview-button-text'>Hide Preview</div>
                          </div>
                          <div onClick={refreshPreview} className='post-preview-button-container'>
                            <div className='post-preview-button-text'>Refresh Preview</div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ margin: '0 auto' }} onClick={() => this.setState({ postPreview : !postPreview })} className='post-preview-button-container'>
                          <div className='post-preview-button-text'>Show Preview</div>
                        </div>
                      )}
                      {isAdmin && !this.state.isUserView && postPreview && (
                        <PostPreview
                          vendors={credits}
                          additionalInfo={additionalInfo.data}
                          submission={submission}
                          images={galleryImages}
                          user={user}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </ErrorBoundary>
    )
  }
}


