import { format, isValid } from 'date-fns'
import { includes } from 'lodash'
import ConfigResolver from '../config/ConfigResolver'

const config = ConfigResolver.config()

export function formatDate (date, dateFormat) {
  if(isValid(date) || !date) {
  	return format(date ? date : new Date(), dateFormat ? dateFormat : `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`)
  }
}

export function isDevelopment () {
  return config.env === 'dev'
}

export function isAdmin (user) {
  return includes(user.roles, 'administrator') || includes(user.roles, 'editor')
}

export function getExtension (path) {
  return path.split('.').pop()
}

export function resizeImage (path, width = '', height = '') {
  if(path) {
    const extension = path.split('.').pop()
    let name = path.split('.').slice(0, -1).join('.')
    return `${name}$!${width}x${height}.${extension}`
  }
}

export function imageUrl (path='', width = '', height = '', ep = '') {
  if(path) {
    const endpoint = ep ? ep : config.imageUrl
    const extension = path.split('.').pop()
    let name = path.split('.').slice(0, -1).join('.')
    name = name.charAt(0) === '/' ? name.substr(1) : name
    return `${endpoint}/${name}$!${width}x${height}.${extension}`
  }
}

export function getImageWidth(url) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img.naturalWidth)
    img.onerror = reject
    img.src = url
  })
}

export function sendDiscordError(error) {
  return true
  /*if (process.env.NODE_ENV === 'development') return true

  const apiError = get(error, 'message') || get(error, 'response.data.detail')
  const hook = new webhook.Webhook('https://discordapp.com/api/webhooks/629342829675544616/CPvu-FT4IV0_BbaRlwc3pTgSuyq1DAtRtd9_zivB2NOp17WcvKUKxpEIn41kUBf_VbNP')
  const msg = new webhook.MessageBuilder()
    .setName(name)
    .addField('', error.toString(), true)
    .addField('', `Axios Error: ${apiError}`)
    .addField('', `Failing Route: ${route}`)
    .addField('', `URL: ${window.location.href}`)
    .addField('', `User ID: ${user.id}, Username: ${user.username}, User Email: ${user.email}`)
    .setTime()
  hook.send(msg)*/
}
