import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import Button from '../../../../components/Button'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import TextField from '../../../../components/TextField'
// import { MagnifyingGlass } from '../../../../components/Icons'
import HeaderText from '../../../../components/HeaderText'
import Select from '../../../../components/Select'

const HeaderContainer = styled.div`
  height: 325px;
  width: 100%;
  background-color: #FAFAFA;
  padding: 48px 0 52px;
`;

const HeaderInner = styled.div`
  max-width: 1220px;
  margin: 0 auto;
;`

const ButtonsContainer = styled.div`
  width: 1220px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 20px 0 42px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #979797;
`;

const FiltersContainer = styled.div`
  margin: 43px 70px 50px 70px;
`;

const FiltersLabel = styled.div`
  font-family: Hind;
  font-size: 20px;
  line-height: 16px;
  padding-top: 18px;
  display: inline-block;
  margin-right: 53px;
`;

const Header = ({ buttons, label, filters, setFilters, options, setFieldValue, searchSubmissions, searchValue }) => {
  return (      
    <HeaderContainer>
      <HeaderInner>
        <HeaderText text='Submissions Admin' />
        <ButtonsContainer>
          {map(buttons, (button, index) => (
            <Button
              key={index}
              disabled={button.disabled}
              text={button.text}
              onClick={(e) => {
                e.preventDefault()
                button.onClick()
              }}
              style={button.style}
            />
          ))}
          {/* <TextField
            id='admin-search'
            value={searchValue}
            name='admin-search'
            style={{ marginTop: 0, width: 550 }}
            label='Search'
            onChange={(e) => setFieldValue('search', e.target.value)}
            height={45}
            endAdornment={<InputAdornment position="end"><MagnifyingGlass scale={4} color='#221F1F' /></InputAdornment>}
          /> */}
        </ButtonsContainer>
        <Divider />
        <FiltersContainer>
          <FiltersLabel>{label}</FiltersLabel>
          {map(options, (option, index) => (
            <Select
              addBlank={option.blank}
              options={option.options}
              labelWidth={option.labelWidth}
              name={option.name}
              value={option.value}
              onChange={(e) => {
                setFieldValue(option.name, e.target.value)
                setFilters({ ...filters, [option.name]: e.target.value, page: 1, size: option.name === 'status' && e.target.value === 'review' ? 200 : 15 })
              }}
              input={option.input}
              label={option.label}
              style={option.style}
              fullWidth
              key={index}
            />
          ))}
        </FiltersContainer>
      </HeaderInner>
    </HeaderContainer>
  )
}

export default Header
