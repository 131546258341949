import React from 'react'
import styled from 'styled-components'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../../../constants'

function Image(props) {
  const ImageContainer = styled.div`
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
  `

  const Image = styled.img`
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    display: block;
  `

  const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const ImageOverlayText = styled.div`
    font-family: Hind;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  `

  const onDrop = (item, dropResult, additionalInfo, saveSubmission, createBoard) => {
    let info = additionalInfo
    if(dropResult.placementObject === 'lead') info.leadImage = item.image.id
    else info.sections[dropResult.placementObject[0]].images[dropResult.placementObject[1]].images.push(item.image.id)
    saveSubmission(info)
    createBoard(item.image.id)
  }

  const { isUsed, saveSubmission, createBoard, additionalInfo, image, src } = props

  const [{isDragging}, drag] = useDrag(() => ({
    type: ItemTypes.IMAGE,
    item: { image },
    end(item, monitor) {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        onDrop(item, dropResult, additionalInfo, saveSubmission, createBoard)
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    })
  }), [image, saveSubmission, additionalInfo])

  return (
    <ImageContainer>
      <Image
        ref={drag}
        style={{ opacity: isDragging ? 0.2 : 1, cursor: isDragging ? 'move' : 'initial' }}
        src={src}
      />
      {isUsed && <ImageOverlay><ImageOverlayText>Used</ImageOverlayText></ImageOverlay>}
    </ImageContainer>
  )
}

export default Image


