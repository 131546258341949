import React, { useState } from 'react'
import { isValid } from 'date-fns'
import styled from 'styled-components'
import { map, slice, filter, includes, orderBy, trim, find, get, pick } from 'lodash'
import { Formik } from 'formik'
import moment from 'moment'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import Button from '../../../../../components/Button'
import SelectSelect from '../../../../../components/Select'
import Checkbox from '../../../../../components/Checkbox'
import ChipSelect from '../../../../../components/ChipSelect'
import TextField from '../../../../../components/TextField'
import Note from '../../../../../components/Note'
import EmailModal from '../../../../../components/EmailModal'
import { Arrow } from '../../../../../components/Icons'
import { formatDate } from '../../../../../utils/index'

import { submissionStatus, submissionUse, submissionPostType } from '../../../../../constants'
import ErrorBoundary from '../../../../../components/ErrorBoundary'

const SidebarContainer = styled.div`
  width: 370px;
  height: auto;
  background-color: #FAFAFA;
  padding: 45px 30px 20px;
  float: right;
`

const EditLinks = styled.div`
  display: flex;
	flex-direction: column;
	margin: -5px 0 5px;
`

const EditLink = styled.a`
  text-decoration: none;
	font-family: Hind;
	font-size: 16px;
	margin-bottom: 5px;
	color: #ECB595;
`

const ButtonContainer = styled.div`
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;
`

const ImportButton = styled.div`
  display: flex;
	flex-direction: column;
	align-items: center;
`

const CheckboxContainer = styled.div`
  display: flex;
	flex-direction: rowGap;
	align-items: center;
`

const Message = styled.div`
  font-size: 16px;
	font-family: Hind;
`

const useStyles = makeStyles({
  label: {
    top: -3,
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    color: '#9F9E9B',
    '&$focused' : {
      color: '#F3DBC7',
      top: 0
    }
  },
  toolbar: {
    color: '#9F9E9B'
  },
  outlinedInput: {
    height: fieldHeight => fieldHeight ? fieldHeight : 'auto',
    backgroundColor: '#FFFFFF',
    '&$focused $notchedOutline': {
      borderColor: '#F3DBC7'
    }
  },

  textField: {
    height: fieldHeight => fieldHeight ? fieldHeight : 'auto',
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300
  },

  focused: {},

  filled: {
    top: 0
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.23)'
  }
})

const AdminSidebar = ({ style, submission, saveSubmission, addNote, colors, styles, settings, options, notes, fieldHeight, importOnClick, emailSubmitter}) => {
  const classes = useStyles(fieldHeight)
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)

  const getChips = (type) => {
    let chipSelect = chipSelects.find(select => select.type === type).options
    return orderBy(filter(chipSelect, function(t) { return includes(submission[type], `${t.id}`) }, false), ['name'], ['asc'])
  }

  const handleExpand = () => {
  	setExpanded(!expanded)
  }

  const handleDateChange = (date) => {
  	if(isValid(date)) saveSubmission('publication_date', formatDate(date))
  	if(!date) saveSubmission('publication_date', null)
  }
	
  const chipSelects = [
    { name: 'Styles', type: 'styles', options: styles },
    { name: 'Settings', type: 'settings', options: settings },
    { name: 'Colors', type: 'colors', options: colors }
  ]

  const selects = [
    { name: 'Status', type: 'status', options: submissionStatus, labelWidth: 45},
    { name: 'Use', type: 'use', options: submissionUse, labelWidth: 27},
    { name: 'Post Type', type: 'post_type', options: submissionPostType, labelWidth: 68},
  ]

  return (
    <ErrorBoundary>
      <SidebarContainer style={style}>
        <Formik
          initialValues={{ create: true, status: submission.status || '', use: submission.use || '', post_type: submission.post_type || '', 'colors': getChips('colors'), note: '', date: submission.publication_date ? moment(submission.publication_date.replace('Z', '')).format('YYYY-MM-DD HH:mm:ss') : null }}
          onSubmit={(values, { setFieldValue }) => {
            addNote(values.note)
            setFieldValue('note', '')
          }}>
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <form>
              {map(selects, (select, index) => (
                <SelectSelect
                  key={index}
                  options={select.options}
                  onChange={(e) => {
                    saveSubmission(e.target.name, e.target.value)
                    setFieldValue(e.target.name, e.target.value)
                  }}
                  name={select.type}
                  style={{marginBottom: 18}}
                  fullWidth
                  value={values[select.type]}
                  label={select.name}
                  labelWidth={select.labelWidth}
                />
              ))}
              {map(chipSelects, (chipSelect, index) => (
                <ChipSelect
                  key={index}
                  options={chipSelect.options}
                  fullWidth
                  name={chipSelect.type}
                  label={chipSelect.name}
                  onAdd={saveSubmission}
                  initialvalue={map(getChips(chipSelect.type), function(c) { return pick(c, ['id', 'name'])})}
                />
              ))}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  style={{ marginTop: 0, marginBottom: 18 }}
                  inputVariant="outlined"
                  format="MM/dd/yyyy hh:mm aa"
                  margin="normal"
                  id="date"
                  name='date'
                  autoComplete='off'
                  onChange={e => {
                    handleDateChange(e)
                    setFieldValue('date', e)
                    if (isValid(e)) setFieldValue('status', 'scheduled')
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                      shrink: classes.filled
                    },
                  }}
                  InputProps={{
                    autoComplete: 'off',
                    classes: {
                      input: classes.textField,
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  fullWidth
                  label="Publication Date"
                  value={values.date}
                />
              </MuiPickersUtilsProvider>
              <EditLinks>
                {submission.gallery_id && submission.id > 358705 && <EditLink href={`/submissions/post-builder/${submission.id}`}>Build Post</EditLink>}
                {submission.post && submission.id > 358705 && <EditLink href={`/submissions/shop-builder/${submission.id}`}>Build Shop Items</EditLink>}
                {submission.post && <EditLink target='_blank' href={`https://www.stylemepretty.com/wp-admin/post.php?post=${submission.post}&action=edit`}>Edit Post</EditLink>}
                {submission.gallery_id && <EditLink target='_blank' href={`https://www.stylemepretty.com/wp-admin/admin.php?page=smpgallery-manage-galleries&gid=${submission.gallery_id}`}>Edit Gallery</EditLink>}
              </EditLinks>
              <ButtonContainer>
						  <ImportButton>
                  <Button
                    text={'Import'}
                    showLoader={get(submission, 'isImporting')}
                    onClick={() => get(submission, 'isImporting') ? null : importOnClick(values.create)}
                  />
                  <CheckboxContainer>
                    <Checkbox 
                      name='create'
                      checked={values.create}
                      onChange={handleChange}
                    />
                    <Message>{submission.post ? 'Update Post' : 'Create Post'}</Message>
                  </CheckboxContainer>
                </ImportButton>
                <Button
                  text='Email Submitter'
                  onClick={() => setOpen(true)}
                />
              </ButtonContainer>
              <TextField
                id='note'
                name='note'
                style={{ width: 370 }}
                label='Notes'
                variant='outlined'
                onChange={handleChange}
                value={values.note}
                fullWidth
                multiline
                rows={7}
              />
              <Button
                text='Add Note'
                onClick={handleSubmit}
                disabled={!trim(values.note)}
                style={{ float: 'right', marginRight: 0, display: 'block', marginTop: 10 }}
              />
              <div style={{ clear: 'both' }} />
              {open && <EmailModal submissionId={submission.id} open={open} handleClose={() => setOpen(false)} templates={JSON.parse(find(options, ['option_name', 'submission_email']).option_value)} emailSubmitter={emailSubmitter} vendorEmail={submission.submit_email} fromEmail='submissions-help@stylemepretty.com' />}
            </form>
          )}
        </Formik>
        <div className='notes'>
          {map(slice(notes, 0, expanded ? 1000 : 4), (note, index) => (
            <Note key={index} note={note} index={index} />
          ))}
          {notes.length > 4 && <Arrow 
            color='#ECB595'
            scale={2} 
            style={{ cursor: 'pointer', margin: '0 auto', display: 'block', transform: `rotate(${expanded ? '180deg' : '0deg'})` }}
            onClick={handleExpand}
          />}
        </div>
      </SidebarContainer>
    </ErrorBoundary>
  )
}

export default AdminSidebar
