import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { map, partial, has } from 'lodash'
import styled from 'styled-components'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import Arrow from '@stylemepretty/smp-react-components/Icons/ArrowUp'
import { UploadError } from './components/UploadError'
import { imageUrl } from '../../../../utils'
import Image from './components/Image'
import ImageModal from './components/ImageModal'
import DeleteImagesModal from './components/DeleteImagesModal'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import InstagramImage from './components/InstagramImage'

const ImagesContainer = styled.div`
	width: 100%;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
`
const HeaderText = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const DeleteAll = styled.div`
  font-family: Hind;
  font-size: 14px;
  color: #ECB595;
  margin-left: 5px;
  cursor: pointer;
`

const DeniedImage = styled.div`
  display: ${props => props.image ? 'block' : 'none'};
  background-image: ${props => props.image ? `url('${props.image}')` : 'none'};
  width: 75px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 15px;
`

const DeniedImages = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.count ? '30px' : 0}
`

const ImagesGrid = styled.div`
  flex-wrap: wrap;
  display: flex;
	flex-direction: row;
  width: calc(100% + 15px);
  margin-left: -15px;

`

const Uploader = styled.div`
  width: 186px;
  height: 116px;
  padding: 50px 265px 0;
  border-width: 2px;
  border-radius: 3px;
  border-color: #D9D9D9;
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const UploaderText = styled.span`
  display: block;
  font-family: Hind;
  line-height: 20px;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
`;

const Images = ({isLoading, path, description, text, denied, onDrop, deleteImage, images, updateImageOrder, user, instagram, showControls, updateSubmissionImageIsFavorite, updateSubmissionImageMetadata, updateSubmissionImageDescription, deleteAllImages, updateImageCredit}) => {
  const [deniedImage, setDeniedImage] = useState('')
  const [imageIndex, setImageIndex] = useState(-1)
  const [deleteClicked, setDeleteClicked] = useState(false)
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, accept: 'image/*', maxSize: 10485760 })
  const SortableContainer = sortableContainer(({children}) => ( <ImagesGrid>{children}</ImagesGrid> ))
  const SortableItem = sortableElement(({imageId, style, deleteImage, onClick, filename, withLoader, index, description, url, type, isFavorite, showControls}) => {
    if (instagram) {
      return (
        <InstagramImage 
          showControls={showControls}
          style={style}
          text={description}
          setText={(text) => {updateSubmissionImageDescription(imageId, text)}}
          type={type}
          setType={(type) => {updateSubmissionImageMetadata(imageId, type)}}
          isFavorite={isFavorite}
          setIsFavorite={() => {updateSubmissionImageIsFavorite(imageId, isFavorite)}}
          image={url}
          withLoader={withLoader}
          index={index}
          filename={filename}
          deleteImage={deleteImage}
          onClick={onClick}
        />
      )
    }

    return (
      <Image
        description={description}
        onClick={onClick}
        deleteImage={deleteImage}
        withLoader={withLoader}
        filename={filename}
        style={style}
        index={index}
      />
    )
  })

  const onSortEnd = ({oldIndex, newIndex}) => {
    updateImageOrder(oldIndex, newIndex)
  }

  const shouldCancelStart = (event) => {
    const interactiveElements = [
      'INPUT', 'TEXTAREA', 'SELECT', 'OPTION', 'BUTTON', 'path'
    ];

    if (interactiveElements.indexOf(event.target.tagName) !== -1) {
      return true;
    }

    // cancel sorting if target has aria role button
    if (event.target.getAttribute('role') === 'button') {
      return true;
    }

    return false;
  }

  return (
    <ErrorBoundary>
      {!isLoading ? (
        <ImagesContainer>
          <HeaderContainer>
            <HeaderText>Media Upload</HeaderText>
            {images.length > 0 && <HeaderText style={{marginLeft: 5}}>{` ( ${images.length} ) `}</HeaderText>}
            {images.length > 0 && <DeleteAll onClick={() => setDeleteClicked(true)}>{` - Delete All`}</DeleteAll>}
            <DeniedImage image={deniedImage} />
          </HeaderContainer>
          <DeniedImages count={denied.count}>
            {map(denied, (file, index) => (
              <UploadError 
                key={index} 
                text='The file ' 
                error={file.reason} 
                image={file}
                onHover={setDeniedImage}
              />
            ))}
          </DeniedImages>
          <Uploader {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
            <input {...getInputProps()} />
            <Arrow />
            <UploaderText>{text}</UploaderText>
          </Uploader>
          <SortableContainer pressDelay={100} axis='xy' onSortEnd={onSortEnd} shouldCancelStart={shouldCancelStart}>
            {map(images, (image, index) => {
              const url = has(image, 'uploaded') && !image.uploaded ? image.preview : imageUrl(image.path, 555, '', path);

              return (
                <SortableItem
                  onClick={() => setImageIndex(index)}
                  filename={image.original_filename}
                  key={`image-${index}`} 
                  index={index}
                  disabled={has(image, 'uploaded') && !image.uploaded}
                  withLoader={has(image, 'uploaded') && !image.uploaded}
                  deleteImage={partial(deleteImage, image)}
                  style={{ cursor: 'pointer', backgroundImage: `url(${url})` }}
                  url={url}
                  description={image.description}
                  type={image.igType}
                  isFavorite={image.isFavorite}
                  showControls={showControls}
                  imageId={image.id}
                />
              )})}
          </SortableContainer>
          {imageIndex > -1 && <ImageModal updateImageCredit={updateImageCredit} user={user} handleClose={() => setImageIndex(-1)} open={imageIndex > -1} initialIndex={imageIndex} images={images} />}
          {deleteClicked && <DeleteImagesModal open={deleteClicked && images.length > 0} handleClose={() => setDeleteClicked(false)} deleteAllImages={deleteAllImages} />}
        </ImagesContainer>
      ) : <div />}
    </ErrorBoundary>
  )
}

export default Images
