import { formatDistanceStrict } from 'date-fns'
import { isEmpty, map, find, isUndefined } from 'lodash'
import { getExtension } from '../../../utils'
import { imageRequirements } from '../../../constants'

export function isValid (submission, credits, images, videos, additionalInfo) {
  let additionalInfoValid = true;
  let numImages = find(imageRequirements, ['type', submission.type])
  if (isUndefined(numImages)) numImages = find(imageRequirements, ['type', 'default'])
  if (!isEmpty(additionalInfo)) {
    map(additionalInfo.data.sections, (section) => {
      map(section.fields, (dataPoint) => {
        if (dataPoint.type === 'Required' && dataPoint.text.length === 0) additionalInfoValid = false
      })
    })
  }

  return submission.title &&
    (
      submission.description ||
      additionalInfoValid
    ) &&   
    credits && 
    credits.length &&
    images &&
    images.length >= numImages.count &&
    images.length <= 300
}

export function lastSaved (time = new Date()) {
  return formatDistanceStrict(new Date(time), new Date())
}

export function verifyImage (file, isAdmin) {
  if (file.width < 1024 && !isAdmin) return Object.assign(file, { valid: false, reason: 'is too small. Images must be at least 1024px in width.' })
  else if(file.size > 10485760 && !isAdmin) return Object.assign(file, { valid: false, reason: 'is too large. Images must be less than 10 MB in file size.' })
  else return Object.assign(file, { valid: true, uploaded: false })
}

export function uniqid (prefix, moreEntropy) {
  if (typeof prefix === 'undefined') prefix = ''
  let retId
  let _formatSeed = function (seed, reqWidth) {
    seed = parseInt(seed, 10).toString(16)
    if (reqWidth < seed.length) return seed.slice(seed.length - reqWidth)
    if (reqWidth > seed.length) return Array(1 + (reqWidth - seed.length)).join('0') + seed
    return seed
  }
  let uniqidSeed = Math.floor(Math.random() * 0x75bcd15)
  uniqidSeed++
  retId = prefix
  retId += _formatSeed(parseInt(new Date().getTime() / 1000, 10), 8)
  retId += _formatSeed(uniqidSeed, 5)
  if (moreEntropy) retId += (Math.random() * 10).toFixed(8).toString()
  return retId
}

export function uniqueFilename (filename) {
  return `${uniqid()}.${getExtension(filename)}`
}
