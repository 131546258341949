import React from 'react'
import { get, deburr } from 'lodash'
import Downshift from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import ErrorBoundary from '../ErrorBoundary'

function renderInput(inputProps) {
  const { InputProps, classes, ref, showError, ...other } = inputProps;

  return (
    <TextField
      error={showError}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          shrink: classes.filled
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem, useObject } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (useObject ? (get(selectedItem, 'text') || '') : (selectedItem || '')).indexOf(suggestion.text) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 300,
        height: 50
      }}
    >
      {suggestion.text}
    </MenuItem>
  );
}

function getSuggestions(value, { showEmpty = false, suggestions } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
      const keep =
          suggestion.text.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count = count + 1;
      }
      return keep;
    })
}

const useStyles = makeStyles(theme => ({

  label: {
    top: -3,
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    color: '#9F9E9B',
    textTransform: 'capitalize',

    '&$focused' : {
      color: '#F3DBC7',
      top: 0
    }
  },
  
  focused: {},

  filled: {
    top: 0
  },

  root: {
    flexGrow: 1,
    height: 45,
    display: 'inline-block'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    borderRadius: 3,
    transition: 'unset',
    maxHeight: 250,
    overflow: 'auto'
  },
  inputRoot: {
    height: 45,
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    height: 45,
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    borderRadius: 3,
    flexGrow: 1,
    padding: '0 14px'
  }
}));

export default function Autocomplete(props) {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <Downshift id={props.id} onChange={props.onChange} onBlur={props.onBlur} onSelect={props.onSelect}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            openMenu,
            selectedItem,
          }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onChange: props.onChange,
              onBlur: props.onBlur,
              onFocus: openMenu,
              placeholder: '',
              value: props.useObject ? get(props, 'value.text', get(props, 'value', '')) : props.value
            });

            return (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: true,
                  classes,
                  showError: props.showError,
                  label: props.label,
                  name: props.name,
                  value: props.useObject ? get(props, 'value.text', get(props, 'value', '')) : props.value,
                  variant: 'outlined',
                  style: props.style,
                  InputProps: { onBlur, onChange, onFocus },
                  inputProps,
                })}

                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions(props.useObject ? get(props, 'value.text', get(props, 'value', '')) : props.value, { showEmpty: props.showEmpty, suggestions: props.options }).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: props.useObject ? suggestion : suggestion.text }),
                          highlightedIndex,
                          selectedItem,
                          useObject: props.useObject
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    </ErrorBoundary>
  );
}