import React from 'react'
import styled from 'styled-components'
import { Logo } from '../Icons'
import ErrorBoundary from '../ErrorBoundary'


const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderTextTitle = styled.span`
  font-size: 43px;
  font-family: 'Hind';
  color: #000000;
  letter-spacing: 4.78px;
  text-transform: uppercase;
  line-height: 43px;
`
const HeaderText = ({ text }) => (
  <ErrorBoundary>
    <HeaderTextContainer>
      <Logo scale={2} fill='#FFFFFF' color='#1A1919' />
      <HeaderTextTitle>{text}</HeaderTextTitle>
    </HeaderTextContainer>
  </ErrorBoundary>
)

export default HeaderText