import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import AvatarMenu from '../AvatarMenu'
import { resizeImage } from '../../utils'


const HeaderContainer = styled.div`
  height: 65px;
  width: calc(100% - 40px);
  line-height: 65px;
  position: relative;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
`

const HeaderText = styled(Link)`
  cursor: pointer;
  color: #ecb697;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Hind';
  text-decoration: none;
`

const Header = ({ headerText, headerUrl, menuItems, image  }) => {
  return (
    <ErrorBoundary>
      <HeaderContainer>
        <HeaderText to={headerUrl}>{headerText}</HeaderText>
        <AvatarMenu image={resizeImage(image, 40)} menuItems={menuItems} />
      </HeaderContainer>
    </ErrorBoundary>
  )
}

export default Header
