import React from 'react'
import canUseDom from 'can-use-dom'
import { Formik } from 'formik'
import styled from 'styled-components'
import { map, pick, each, startCase, min } from 'lodash'
import moment from 'moment'

import Table from './components/Table'
import AdminHeader from './components/Header'

import { submissionStatus, submissionTypes, submissionUse, submissionPostType } from '../../constants'

if (canUseDom) require('./submissions-admin.scss')

const AdminContainer = styled.div`
	width: 100%;
}
`

const NoResults = styled.div`
  margin-top: 30px;
  font-family: Hind;
  text-align: center;
  font-size: 24px;
  color: #9F9E9B;
`

const Headers = () => {
  return [
    { id: 'id', label: 'ID', width: 80 },
    { id: 'title', label: 'Title', width: 230 },
    { id: 'use', label: 'Use', width: 110 },
    { id: 'type', label: 'Type', width: 130 },
    { id: 'post_type', label: 'Post Type', width: 130 },
    { id: 'status', label: 'Status', width: 140 },
    { id: 'publication_date', label: 'Scheduled', width: 160 },
    { id: 'updated', label: 'Updated', width: 130 },
    { id: 'location', label: 'Location', width: 110 }
  ]
}

const SubmissionsAdmin = ({ isLoading, submissions, totalSubmissions, filters, setFilters, perPage, history, user }) => {
  
  const getRows = (submissions) => (
    map(submissions, (submission) => (
      pick(submission, ['id', 'title', 'use', 'type', 'post_type', 'status', 'publication_date', 'updated', 'location'])
    ))
  )

  const formatRows = (rows) => (
    map(rows, (row) => {
      const untouched = ['id', 'title', 'location']
      const dates = ['publication_date', 'updated']
      const sCase = ['use', 'type', 'post_type', 'status']
      let formattedRow = {id: '', title: '', use: '', type: '', post_type: '', status: '', publication_date: '', updated: '', location: ''}
      
      each(untouched, (u) => {
        formattedRow[u] = row[u]
      })    
      each(dates, (date) => {
        formattedRow[date] = moment(row[date], moment.ISO_8601, true).isValid() ? moment(row[date], moment.ISO_8601, true).format('ll') : row[date]
      })
      each(sCase, (sc) => {
        formattedRow[sc] = startCase(row[sc])
      })

      return formattedRow
    })
  )

  const toSubmissionsDashboard = () => {
    history.push(`/submissions/dashboard/${user.email}`)
  }

  const reviewSubmissions = (submissions, status) => {
    const lowest = min(map(submissions, function (s) { return s.id }))
    if(lowest) history.push(`/submissions/submission/${lowest}?review=${status === 'quick' ? 'quick' : 'review'}`)
  }

  return (
    <AdminContainer>
      {!isLoading ? (
        <React.Fragment>
          <Formik 
            initialValues={{ status: '', use: '', type: '', post_type: '', search: '' }}
          >
            {({ values, setFieldValue }) => (
              <form>
                <AdminHeader
                  text='Submissions Admin'
                  buttons={[
                    {text: 'My Submissions', onClick: toSubmissionsDashboard, style: {marginRight: 25}, disabled: false}, 
                    {text: `Review ${values.status === 'quick' ? ' Quick' : 'Submissions'}`, onClick: () => reviewSubmissions(submissions, values.status), disabled: values.status !== 'review' && values.status !== 'quick'},
                    //{text: 'Manage Emails', style: {marginRight: 80}, disabled: true}
                  ]}
                  label='Sort Submissions By'
                  searchValue={values.search}
                  setFieldValue={setFieldValue}
                  filters={filters}
                  setFilters={setFilters}
                  options={[
                    {options: submissionStatus, labelWidth: 42, blank: 'All Statuses', label: 'Status', name: 'status', value: values.status, style: {width: 180}},
                    {options: submissionUse, labelWidth: 25, blank: 'All Uses', label: 'Use', name: 'use', value: values.use, style: {marginLeft: 35, width: 180}},
                    {options: submissionTypes, labelWidth: 115, blank: 'All Types', label: 'Submission Type', name: 'type', value: values.type, style: {marginLeft: 35, width: 210}},
                    {options: submissionPostType, labelWidth: 70, blank: 'All Post Types', label: 'Post Type', name: 'post_type', value: values.post_type, style: {marginLeft: 35, width: 180}}
                  ]}
                />
                {totalSubmissions ? (
                  <Table 
                    rowsPerPage={perPage}
                    rows={formatRows(getRows(submissions))}
                    columns={Headers()}
                    total={totalSubmissions}
                    filters={filters}
                    setFilters={setFilters}
                  />
                ) : (
                  <NoResults>There Are No Results For This Search</NoResults>
                )}
              </form>
            )}
          </Formik>
        </React.Fragment>
      ) : (
        <div />
      )}
    </AdminContainer>
  )
}

export default SubmissionsAdmin
