import React from 'react'
import { map } from 'lodash'
import { default as MUISelect } from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/styles'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { Dropdown } from '../Icons'
import ErrorBoundary from '../ErrorBoundary'

const useStyles = makeStyles({
  formControl: {
    margin: 0
  },
  select: {
    backgroundColor: '#FFFFFF',
    padding: props => props.isInstagram ? '7px 14px 0' : '14.5px 14px 12.5px',
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    borderRadius: 3,
    '&:focus': {
      backgroundColor: '#FFFFFF'
    }
  },
  label: {
    top: -2,
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    color: '#9F9E9B',
  },
  cssFocused: {
    top: 0,
    backgroundColor: '#FFFFFF'
  },
  filled: {
    top: 0
  },
  menuItem: {
    height: props => props.isInstagram ? 30 : 50
  }
})

const Select = (props) => {
  const { options, onBlur, value, onChange, name, labelWidth, label, labelId, width, style, addBlank, fullWidth } = props
  const classes = useStyles(props)
  return (
    <ErrorBoundary>
      <FormControl fullWidth={fullWidth} style={style} variant='outlined' className={classes.formControl}>
        <InputLabel 
          classes={{
            root: classes.label,
            focused: classes.cssFocused,
            shrink: classes.filled
          }} 
          htmlFor={labelId}
        >
          {label}
        </InputLabel>
        <MUISelect
          value={value}
          onChange={onChange}
          input={<OutlinedInput labelWidth={labelWidth} name={name} />}
          IconComponent={props => (
            <Dropdown fill='#9F9E9B' stroke='#D9D9D9' scale={1} style={{ marginRight: 8 }} className='MuiSvgIcon-root MuiSelect-icon' />
          )}
          onBlur={onBlur}
          inputProps={{
            classes: {
              root: classes.select,
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            width: width
          }}
        >
          {addBlank && <MenuItem className={classes.menuItem} key='blank' value=''>{addBlank}</MenuItem>}
          {map(options, (option, index) => (
            <MenuItem className={classes.menuItem} key={index} value={option.value}>{option.name}</MenuItem>
          ))}
        </MUISelect>
      </FormControl>
    </ErrorBoundary>
  )
}

export default Select