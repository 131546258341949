import React, { useState } from 'react'
import styled from 'styled-components'
import { get, isArray, includes } from 'lodash'
import moment from 'moment'
import { Arrow } from '../../../../../../components/Icons'

const StatisticsContainer = styled.div`
  width: 370px;
  height: auto;
  background-color: #FAFAFA;
  padding: 45px 30px 20px;
  float: right;
  margin-bottom: 30px;
  border: ${(props) => props.isPremierOrElite ? '2px solid #c6a757' : 'unset'};
`

const StatisticsHeader = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: ${(props) => props.isPremierOrElite ? '#c6a757' : 'rgb(236,182,151)'};
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-bottom: 20px;
`

const StatisticsEntryColumns = styled.div`
  margin-left: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StatisticsEntryRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StatisticsEntryColumn = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: bold;
  width: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
`

const StatisticsEntryRow = styled.div`
  margin-top: 20px;
  font-family: Hind;
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: bold;
  width: 33%;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
`

const StatisticsEntryHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: Hind;
  font-size: 20px;
  color: ${(props) => props.isPremierOrElite ? '#c6a757' : 'rgb(236,182,151)'};
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
`

const StatisticsEntryValue = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: black;
  letter-spacing: 0;
  line-height: 16px;
  width: 33%;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  margin-top: 20px;
  color: ${(props) => props.isPremierOrElite ? '#c6a757' : 'black'};
  font-weight: ${(props) => props.isPremierOrElite ? 'bold' : 'normal'};
`

const Statistics = ({statistics, userEmail}) => {
  const [open, setOpen] = useState(false)
  const isPremierOrElite = isArray(get(statistics, 'plans')) && includes(['Premier', 'Elite'], get(statistics, 'plans[0]'))
  
  return (
    <StatisticsContainer isPremierOrElite={isPremierOrElite}>
      <StatisticsHeader isPremierOrElite={isPremierOrElite}>Submission Statistics</StatisticsHeader>
      {open && (
        <>  
          <StatisticsEntryRows>
            <StatisticsEntryRow style={{ textAlign: 'left', width: '38%' }}>{get(statistics, 'vendor') ? 'Vendor Name' : 'User Email'}</StatisticsEntryRow>
            <StatisticsEntryValue style={{ width: '62%' }}>{get(statistics, 'vendor') || userEmail}</StatisticsEntryValue>
          </StatisticsEntryRows>
          {get(statistics, 'vendor') ? (
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left', width: '38%' }}>Vendor Status</StatisticsEntryRow>
              <StatisticsEntryValue style={{ width: '62%' }}>{get(statistics, 'lbb') ? 'Active LBB' : (get(statistics, 'profile') ? 'Active Profile' : 'Inactive')}</StatisticsEntryValue>
            </StatisticsEntryRows>
          ) : null}
          {get(statistics, 'vendor') && get(statistics, 'lbb') && isArray(get(statistics, 'plans')) ? (
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left', width: '38%' }}>Vendor Plan</StatisticsEntryRow>
              <StatisticsEntryValue isPremierOrElite={isPremierOrElite} style={{ width: '62%' }}>{get(statistics, 'plans[0]')}</StatisticsEntryValue>
            </StatisticsEntryRows>
          ) : null}
          {get(statistics, 'date') ? (
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left', width: '38%' }}>Date Last Renewed</StatisticsEntryRow>
              <StatisticsEntryValue style={{ width: '62%' }}>{moment(get(statistics, 'date')).format('LL')}</StatisticsEntryValue>
            </StatisticsEntryRows>
          ) : null}
          <>
            <StatisticsEntryHeader isPremierOrElite={isPremierOrElite}>Submissions</StatisticsEntryHeader>
            <StatisticsEntryColumns>
              <StatisticsEntryColumn>Renewal</StatisticsEntryColumn>
              <StatisticsEntryColumn>All Time</StatisticsEntryColumn>
            </StatisticsEntryColumns>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Total</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'submissionsRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'submissionCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Accepted</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'acceptedRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'acceptedCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Denied</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'deniedRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'deniedCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
            <StatisticsEntryHeader isPremierOrElite={isPremierOrElite}>Credits</StatisticsEntryHeader>
            <StatisticsEntryColumns>
              <StatisticsEntryColumn>Renewal</StatisticsEntryColumn>
              <StatisticsEntryColumn>All Time</StatisticsEntryColumn>
            </StatisticsEntryColumns>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Total</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'creditedRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'creditCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Accepted</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'creditedAcceptedRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'creditedAcceptedCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
            <StatisticsEntryRows>
              <StatisticsEntryRow style={{ textAlign: 'left' }}>Denied</StatisticsEntryRow>
              <StatisticsEntryValue>{get(statistics, 'date') ? get(statistics, 'creditedDeniedRenewalCount') : 'NA'}</StatisticsEntryValue>
              <StatisticsEntryValue>{get(statistics, 'creditedDeniedCount')}</StatisticsEntryValue>
            </StatisticsEntryRows>
          </>
        </>
      )}
      <Arrow 
        color={isPremierOrElite ? '#c6a757' : '#ECB595'}
        scale={2} 
        style={{ cursor: 'pointer', margin: '30 auto 0', display: 'block', transform: `rotate(${open ? '180deg' : '0deg'})` }}
        onClick={() => setOpen(!open)}
      />
    </StatisticsContainer>
  )
}

export default Statistics
