import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { imageUrl } from '../../../../../../utils'
import { defaultImage } from '../../../../../../constants' 

const SubmissionContainer = styled(Link)`
  width: 380px;
  height: 470px;
  background-color: #FAFAFA;
  margin-bottom: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`
const SubmissionImage = styled.div`
  position: relative;
  background-image: ${props => props.image || 'none'};
  width: 100%;
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const SubmissionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(144,144,144, .5)
`

const SubmissionOverlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  margin: 35px 0;
`

const SubmissionOverlayText = styled.div`
  text-align: center;
`
const Name = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
`
const Value = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
  text-transform: capitalize;
`
const SubmissionTitle = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #404040;
  letter-spacing: 0;
  text-align: center;
  line-height: 28px;
  padding: 20px 35px;
`


const SubmissionGrid = ({ submission, view, index }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <SubmissionContainer to={`/submissions/submission/${submission.id}`} view={view} index={index}>
      <SubmissionImage 
        onMouseEnter={() => setHovered(true)} 
        onMouseLeave={() => setHovered(false)} 
        image={submission.image ? `url(${imageUrl(submission.image.path, 555)})` : `url(${defaultImage})`}
        view={view}
      >
        {hovered && <SubmissionOverlay />}
        {hovered && (
          <SubmissionOverlayTextContainer view={view}>
            { /* {!isNull(submission.order) && get(submission, 'order', []).length > 0 && (
              <SubmissionOverlayText>
                <Name>No. Photos</Name>
                <Value>{submission.order.length}</Value>
              </SubmissionOverlayText>
            )} */ }
            <SubmissionOverlayText>
              <Name>Publication</Name>
              <Value>{submission.type === 'living' ? 'SMP Living' : 'SMP Weddings'}</Value>
            </SubmissionOverlayText>
            <SubmissionOverlayText>
              <Name>Status</Name>
              <Value>{submission.status}</Value>
            </SubmissionOverlayText>
          </SubmissionOverlayTextContainer>
        )}
      </SubmissionImage>
      <SubmissionTitle>{submission.title}</SubmissionTitle>
    </SubmissionContainer>
  )
}

export default SubmissionGrid