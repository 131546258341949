import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import canUseDom from 'can-use-dom'
import SingleSubmissionContainer from '../../routes/single-submission/single-submission-container'
import SubmissionsAdminContainer from '../../routes/submissions-admin/submissions-admin-container'
import SubmissionsDashboardContainer from '../../routes/submissions-dashboard/submissions-dashboard-container'
import PostBuilderContainer from '../../routes/post-builder/post-builder-container'
import { ShopBuilder } from '../../routes/shop-builder/shop-builder'
import Header from '../Header'
import { adminHeaderOptions, userHeaderOptions } from '../../constants'
import { isAdmin, isDevelopment } from '../../utils'
import ErrorBoundary from '../../components/ErrorBoundary'
import ConfigResolver from '../../config/ConfigResolver'

const config = ConfigResolver.config()
if (canUseDom) require('./style.scss')

const AppContainer = styled.div`
  width: 100%;
`

const App = () => {
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : (isDevelopment() ? params.get('token') || config.token : '')

  useEffect(() => {
    const fetchData = async () => {
      const auth = token ? { headers: {'Authorization': `Bearer ${token}`} } : ''
		  await axios
        .get(`${config.siteUrl}/api/v2/user`, auth)
        .then((result) => {
          sessionStorage.setItem('token', result.data.data.token)
          setUser(result.data.data)
          setIsLoading(false)
        })
        .catch(() => {
          const publicUrl = /^https:\/\/www\.stylemepretty.com\/submissions\/submission\/\d+\/.+/
          if (publicUrl.test(window.location.href)) setIsLoading(false)
          else window.location.href = `${config.siteUrl}/login?redirect_to=/submissions/&msg=You+must+have+an+account+to+create+a+submission.`
        })
    }

    fetchData()
  }, [token])
	
	
  return (
    <ErrorBoundary>
      <AppContainer>
        {!isLoading && (
          <React.Fragment>
            <Header
              headerText={isAdmin(user) ? 'Submissions Admin Dashboard' : 'Submissions Dashboard'}
              headerUrl={isAdmin(user) ? '/submissions/admin' : `/submissions/dashboard/${user.email}`}
              menuItems={isAdmin(user) ? adminHeaderOptions(user) : userHeaderOptions(user)}
              image={user.avatar}
            />
            <Switch>
              <Route exact path="/submissions/admin" render={(props) => <SubmissionsAdminContainer {...props} user={user} />} />
              <Route path="/submissions/dashboard/:email" render={(props) => <SubmissionsDashboardContainer {...props} user={user} />} />
              <Route path="/submissions/user/:username" render={(props) => <Redirect to={`/submissions/dashboard/${user.email}`} />} />
              <Route path="/submissions/submission/:id/:publicHash" render={(props) => <SingleSubmissionContainer {...props} user={user} />} />
              <Route path="/submissions/submission/:id" render={(props) => <SingleSubmissionContainer {...props} user={user} />} />
              <Route path="/submissions/post-builder/:id" render={(props) => <PostBuilderContainer {...props} user={user} />} />
              <Route path="/submissions/shop-builder/:id" render={(props) => <ShopBuilder {...props} user={user} />} />
              <Route render={() => <Redirect to={isAdmin(user) ? '/submissions/admin' : `/submissions/dashboard/${user.email}`} />} />
            </Switch>
          </React.Fragment>
        )}
      </AppContainer>
    </ErrorBoundary>
  )
}

export default App