import React from 'react'

function scaleProps (n) {
  return {
    width: n * 23,
    height: n * 26
  }
}

const Dropdown = ({ stroke, fill, scale, style, onClick, className }) => (
  <svg viewBox="0 0 23 26" xmlns="http://www.w3.org/2000/svg" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
	    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-1294.000000, -587.000000)">
	            <g transform="translate(1294.000000, 584.000000)">
	                <path d="M0.5,3.5 L0.5,28" stroke={stroke} strokeWidth="1" strokeLinecap="square"></path>
	                <polygon fill={fill} fillRule="evenodd" transform="translate(17.500000, 16.000000) rotate(-180.000000) translate(-17.500000, -16.000000) " points="17.5 13 23 19 12 19"></polygon>
	            </g>
	        </g>
	    </g>
  </svg>
)

export default Dropdown