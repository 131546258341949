import React, { Component } from 'react'
import axios from 'axios'
import PostBuilder from './post-builder'
import { isAdmin } from '../../utils'
import axiosInstance from '../../config/axiosResolver'

class PostBuilderContainer extends Component {
  constructor(props) {
    super()

    this.state = {
      isLoading: true,
      submission: {},
      gallery: {},
      isAdmin: isAdmin(props.user),
      additionalInfo: {}
    }

    this.id = props.match.params.id
    this.saveSubmission = this.saveSubmission.bind(this)
    this.createBoard = this.createBoard.bind(this)
  }

  saveSubmission (data) {
    axios.post(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/save-additional-info`, { data: data })
      .then(res => {
        this.setState({ additionalInfo: res.data.data.data})
      })
  }

  createBoard (imageId) {
    axios
      .post(`https://www.stylemepretty.com/wp-admin/admin-ajax.php?action=smp_create_board&imageId=${imageId}&userId=${this.props.user.id}&submissionId=${this.id}`)
      .catch((err) => this.createBoard(imageId))
  }

  componentDidMount () {
    if(!this.state.isAdmin) this.props.history.push(`/submissions/dashboard/${this.props.user.email}`)
    Promise.all([
      axiosInstance.get(`/submissions/${this.id}/`),
      axios.get(`https://www.stylemepretty.com/api/v2/submissions/${this.id}/get-additional-info`)
    ])
      .then(([submission, additionalInfo]) => {
        if(submission.data.gallery_id) {
          axios.get(`https://www.stylemepretty.com/api/v2/gallery/${submission.data.gallery_id}/post-builder`)
            .then((res) => {
              this.setState({ 
                submission: submission.data,
                additionalInfo: additionalInfo.data.data.data,
                gallery: res.data.data,
                isLoading: false
              })
            })
        }
      })
  }

  render () {
    return (
      <PostBuilder
        {...this.state}
        saveSubmission={this.saveSubmission}
        createBoard={this.createBoard}
      />
    )
  }
}

export default PostBuilderContainer

