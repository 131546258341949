import React from 'react'
import styled from 'styled-components'
import { Header } from '../Header'


const HeroContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #F8EFEB;
`

const HeroContent = styled.div`
  padding: 15px;
`


const Hero = ({ children, header, style }) => {
  return (
    <HeroContainer style={{ ...style }}>
      {header ? (
        <Header text={header} style={{ position: 'absolute', top: -20 }} />
      ) : null}
      <HeroContent>{children}</HeroContent>
    </HeroContainer>
  )
}

export default Hero
