import React from 'react'

function scaleProps (n) {
  return {
    width: n * 11,
    height: n * 14
  }
}

const Trash = ({ color, scale, style, onClick, className }) => (
  <svg viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg" className={className} style={{...style, ...scaleProps(scale)}} onClick={onClick}>
    <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Media-Upload-Option1" transform="translate(-137.000000, -1097.000000)" fill={color}>
        <g id="Group-6" transform="translate(137.000000, 1097.000000)">
          <path d="M1,4 L10,4 L10,13 C10,13.5522847 9.55228475,14 9,14 L2,14 C1.44771525,14 1,13.5522847 1,13 L1,4 L1,4 Z" id="Rectangle"></path>
          <rect id="Rectangle" x="0" y="1" width="11" height="2"></rect>
          <rect id="Rectangle" x="3" y="0" width="5" height="1"></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default Trash