import React, { Component } from 'react'
import canUseDom from 'can-use-dom'
import { DndProvider } from 'react-dnd'
import { values, flatten, get, map } from 'lodash'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ImageGrid from './components/ImageGrid'
import ImagePlacements from './components/ImagePlacements'

if (canUseDom) require('./post-builder.scss')

class PostBuilder extends Component {
  getUsedImages (additionalInfo) {
    const sections = get(additionalInfo, 'sections')
    let images = map(sections, (section) => get(section, 'images'))
    images = map(images, (section) => map(section, (imageSection) => get(imageSection, 'images')))
    return flatten(flatten(images))
  }

  render () {
    const { gallery, isLoading, additionalInfo, saveSubmission, createBoard, submission } = this.props
    const leadImage = get(additionalInfo, 'leadImage')
    const images = this.getUsedImages(additionalInfo)
    let usedImages = values(flatten(images))
    if(leadImage) usedImages.push(leadImage)
    if(!isLoading) {
      return (
        <DndProvider backend={HTML5Backend}>
          <div className='post-builder'>
            <div className='header'>
              <a href={`/submissions/submission/${submission.id}`} className='button'>
                <div className='text'>Back To Submission</div>
              </a>
            </div>
            <div className='container'>
              <ImageGrid
                gallery={gallery}
                additionalInfo={additionalInfo}
                usedImages={usedImages}
                saveSubmission={saveSubmission}
                images={gallery.images}
                createBoard={createBoard}
              />
              <div className='divider' />
              <ImagePlacements
                additionalInfo={additionalInfo}
                gallery={gallery}
                saveSubmission={saveSubmission}
              />
            </div>
          </div>
        </DndProvider>
      )
    } else {
      return <div />
    }
  }
}

export default PostBuilder


