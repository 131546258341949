import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { filter } from 'lodash'
import Checkbox from '../../../../components/Checkbox'
import { Formik } from 'formik'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import TextField from '../../../../components/TextField'
import Select from '../../../../components/Select'
import { submissionTypes } from '../../../../constants'


const HeaderText = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 30px;
`

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 40px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 400px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const TOS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Message = styled.div`
  font-family: Hind;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
`

const Link = styled.a`
  font-family: Hind;
  font-size: 14px;
  text-decoration: none;
  color: rgb(236, 182, 151);
`
const NewSubmissionModal = ({ user, createSubmission, createQuickSubmission, open, setOpen }) => {
  const [clicked, setClicked] = useState(false)
  let types = filter(submissionTypes, function (t) { return t.name !== 'Living' })

  return (      
    <Modal open={open} handleClose={() => setOpen(false)}>
      <ModalInner>
        <HeaderText>{`New ${open === 'quick' ? 'Quick Submission' : open === 'guaranteed' ? 'Guaranteed Post Submission' : 'Submission'}`}</HeaderText>
        <Formik
          initialValues={{ 
            title: '',
            type: 'real-wedding',
            gallery: '',
            password: '',
            agree: false,
            diversity: false,
            removalPolicy: false,
            workshopHost: ''
          }}
          onSubmit={(values) => {
            if (open === 'quick') {
              const date = new Date();
              createQuickSubmission({
                title: `Quick Submission ${moment(date).format('YYYY-MM-DD')}`,
                gallery: values.gallery,
                password: values.password,
                type: values.type,
                workshopHost: values.workshopHost
              })
            } else {
              createSubmission({
                title: values.title,
                type: values.type,
                status: open === 'guaranteed' ? 'purchased' : 'started',
                workshopHost: values.workshopHost
              })
            }
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form style={{ height: values.type === 'workshop' ? 475 : 450 }}>
              {(open === 'new' || open === 'guaranteed') && (
                <TextField
                  name='title'
                  label='title'
                  onChange={handleChange}
                  height={45}
                  value={values.title}
                  fullWidth
                />
              )}
              {open === 'quick' && (
                <React.Fragment>
                  <TextField
                    name='gallery'
                    label='External Gallery Link'
                    onChange={handleChange}
                    height={45}
                    value={values.gallery}
                    fullWidth
                  />
                  <TextField
                    name='password'
                    label='Gallery Password'
                    onChange={handleChange}
                    height={45}
                    value={values.password}
                    fullWidth
                  />
                </React.Fragment>
              )}
              <Select
                name='type'
                label='Type'
                onChange={handleChange}
                height={45}
                value={values.type}
                options={types}
                fullWidth
                labelWidth={33}
              />
              {values.type === 'workshop' && (
                <TextField
                  name='workshopHost'
                  label='Who Hosted The Workshop?'
                  onChange={handleChange}
                  height={45}
                  value={values.workshopHost}
                  fullWidth
                />
              )}
              <TOS>
                <Checkbox 
                  name='agree'
                  checked={values.agree}
                  onChange={handleChange}
                />
                <Message>I am authorized by the photographer of this event to submit these images.</Message>
              </TOS>
              <TOS>
                <Checkbox 
                  name='diversity'
                  checked={values.diversity}
                  onChange={handleChange}
                />
                <Message>Per our <Link target='_blank' rel='noopener noreferrer' href='http://www.stylemepretty.com/style-me-prettys-diversity-policy/'>diversity policy</Link>, we are no longer publishing plantation weddings on SMP. Please check this box to confirm that these images were not taken at a plantation venue.</Message>
              </TOS>
              <TOS>
                <Checkbox 
                  name='removalPolicy'
                  checked={values.removalPolicy}
                  onChange={handleChange}
                />
                <Message style={{ fontSize: 9 }}>I confirm that I have obtained all relevant permissions from the parties involved in the event described in this article for publication on StyleMePretty.com and or its Sub-brands, Partners, or Affiliates (the "Publication"). I understand that failure to obtain proper permission to publish from any party involved in this event may result in the permanent removal of the article. If at any time a removal request is rendered to the Publication, for any reason, for any part of, or an entire published content piece, I understand that that content will be removed in a timely fashion by the Publication as per the Publication's <Link style={{ fontSize: 9 }} rel='noopener noreferrer' href='https://stylemepretty.reamaze.com/articles/content-removal-policy' target='_blank'>Content Removal Policy</Link>. I understand that it is my responsibility to obtain permission in writing before submitting and maintain written copies to provide to the Publication upon request. By clicking this checkbox, I affirm that I have taken the necessary steps to submit this article for Publication.</Message>
              </TOS>
              <Button 
                disabled={open === 'quick' ? (!values.gallery || !values.agree || !values.diversity || !values.removalPolicy || clicked || (values.type === 'workshop' && !values.workshopHost.trim())) : (!values.title.trim() || !values.agree || !values.diversity || !values.removalPolicy || clicked || (values.type === 'workshop' && !values.workshopHost.trim()))}
                width={180}
                style={{ alignSelf: 'center' }}
                text='Create' 
                onClick={() => {
                  setClicked(true)
                  handleSubmit()
                }} />
            </Form>
          )}
        </Formik>
      </ModalInner>
    </Modal>
  )
}

export default NewSubmissionModal
