import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { resizeImage } from '../../../../../../utils'


const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  height: 30px;
  opacity: 0.4;
  background-color: #FEF5F8;
  top: 0;
`



const Image = ({ image, style, overlay }) => {
  return (
    <ImageContainer style={{ ...style }}>
      <Img src={resizeImage(get(image, 'path', ''), 440)} alt='' />
      {overlay && <ImageOverlay />}
    </ImageContainer>
  )
}

export default Image
