import React from 'react'
import { default as EB } from 'react-error-boundary'
import styled from 'styled-components'
// import { sendDiscordError } from '../../utils'

const ErrorContainer = styled.div`
  width: 100%;
  font-family: hind;
  font-weight: 300;
  color: #f44336;
  font-size: 16px;
  margin-bottom: 20px;
`

const ErrorBoundary = ({children}) => {
  const errorHandler = (error, componentStack) => {
    console.log(error, componentStack)
    // sendDiscordError('Submissions Server', error, componentStack)
  }

  const fallbackComponent = ({ componentsStack, error }) => (
    <ErrorContainer>There seems to be an issue with this component. Our tech staff has been notified.</ErrorContainer>
  )

  return (
    <EB onError={errorHandler} FallbackComponent={fallbackComponent}>
      {children}
    </EB>
  )
}

export default ErrorBoundary
