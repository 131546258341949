import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { SubmissionGrid, SubmissionList } from './components/Submission'

const SubmissionsContainer = styled.div`
  width: ${props => props.view === 'grid' ? '1220px' : '100%'};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${props => props.view === 'grid' ? '0 auto' : '0'}
`

const TableHeaders = styled.div`
  display: ${props => props.view === 'list' ? 'flex' : 'none'};
  flex-direction: row;
  margin-bottom: 20px;
  margin: 0 auto 20px;
  width: 1220px;
`

const Header = styled.div`
  width: ${props => props.width};
  font-family: Hind;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const ListHeaders = () => (
  <React.Fragment>
    <Header width='150px' />
    <Header width='600px'>Title</Header>
    {/* <Header width='125px'>No. Photos</Header> */}
    <Header width='175px'>Publication</Header>
    <Header width='165px'>Status</Header>
  </React.Fragment>
)

const Submissions = ({ submissions, view }) => (
  <React.Fragment>
    <TableHeaders view={view}>
      <ListHeaders />
    </TableHeaders>
    <SubmissionsContainer view={view}>
      {map(submissions, (submission, index) => (
        <React.Fragment key={index}>
          {view === 'grid' && <SubmissionGrid submission={submission} />}
          {view === 'list' && <SubmissionList index={index} submission={submission} />}
        </React.Fragment>
      ))}
    </SubmissionsContainer>
  </React.Fragment>
)

export default Submissions
