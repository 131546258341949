import React, { useState } from 'react'
import canUseDom from 'can-use-dom'
import styled from 'styled-components'
import { filter } from 'lodash'
import { Header, Submissions, NewSubmissionModal, Filters } from './components'
import Pagination from '../../components/Pagination'

if (canUseDom) require('./submissions-dashboard.scss')

const SubmissionsDashboardContainer = styled.div`
  width: 100%;
`
const SubmissionsDashboardHeaderContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: #FAFAFA;
  padding: 55px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SubmissionDashboardFiltersContainer = styled.div`
  width: 1220px;
  margin: 45px auto;
`

const PaginationContainer = styled.div`
  width: 1220px;
  margin: 30px auto 45px;
  height: 20px;
`

const SubmissionsDashboardSubmissionsContainer = styled.div`
  width: 100%;
  margin: 40px auto;
`


const SubmissionsDashboard = ({ user, submissions, isLoading, createSubmission, page, setPage, setFilters, setNotFilters, filters, notFilters, totalSubmissions, vendorUser, createQuickSubmission, history, canQuickSubmit, totalQuickSubmissions }) => {
  const [view, setView] = useState('grid')
  const [open, setOpen] = useState(false)
  const totalPages = Math.ceil(totalSubmissions / 15)

  return (
    <SubmissionsDashboardContainer>
      {!isLoading ? (
        <React.Fragment>
          <SubmissionsDashboardHeaderContainer>
            <Header 
              user={user}
              setOpen={setOpen}
              vendorUser={vendorUser}
              history={history}
              canQuickSubmit={canQuickSubmit}
              totalQuickSubmissions={totalQuickSubmissions}
            />
          </SubmissionsDashboardHeaderContainer>
          <SubmissionDashboardFiltersContainer>
            <Filters 
              changeView={setView}
              view={view}
              setFilters={setFilters}
              setNotFilters={setNotFilters}
              filters={filters}
              notFilters={notFilters}
            />
          </SubmissionDashboardFiltersContainer>
          {totalPages > 1 && (
            <PaginationContainer>
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                prevOnClick={() => {
                  if (page !== 1) {
                    setPage(page - 1)
                    setFilters({...filters, page: page - 1})
                  }
                }}
                nextOnClick={() => {
                  if (page !== Math.ceil(totalSubmissions / 15)) {
                    setPage(page + 1)
                    setFilters({...filters, page: page + 1})
                  }  
                }}
                viewAllOnClick={null}
                style={{float: 'right'}}
              />
            </PaginationContainer>
          )}
          <SubmissionsDashboardSubmissionsContainer>
            <Submissions
              submissions={filter(submissions, (s) => { return s.status !== 'trash' })}
              view={view}
            />
          </SubmissionsDashboardSubmissionsContainer>
        </React.Fragment>
      ) : (
        <div />
      )}
      <NewSubmissionModal
        open={open}
        type={open}
        user={user} 
        setOpen={setOpen}
        createSubmission={createSubmission}
        createQuickSubmission={createQuickSubmission}
      />
    </SubmissionsDashboardContainer>
  )
}

export default SubmissionsDashboard

  
    
  
            
