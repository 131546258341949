import React from 'react'
import styled from 'styled-components'

import Image from '../Image'
import Select from '../../../../../../components/Select'
import TextField from '../../../../../../components/TextField'
import { Formik } from 'formik'
import { StarFull, StarEmpty } from '../../../../../../components/Icons'

const ImageWrapper = styled.div`
  && {
    height: 200px;
    width: 100%;
    margin: 0 auto;
  }
`;

const SmallSelect = styled(Select)`
  && {
    width: 100px;
    margin: 0;

    div div[role='button'] {
      padding: 8px 12px;
    }
  }
`;

const ControlsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: auto;
  width: 100%;
  justify-content: space-between;
`;

const ImageAndTextareaWrapper = styled.div`
  width: calc(100% / 3 - 20px);
  margin: 10px;
`;

const InstagramControls = ({
  isFavorite,
  type,
  showControls,
  setIsFavorite,
  setType,
  isLoading,
}) => {
  if (isLoading) {
    return null
  }

  return (
    <ControlsWrapper>
      {showControls ? (isFavorite ? <StarFull onClick={(e) => { e.preventDefault(); setIsFavorite(!isFavorite)}} /> : <StarEmpty onClick={() => setIsFavorite(!isFavorite)} />) : null}
      <Formik
        initialValues={{ type: type || '' }}
      >
        {({ values, handleChange }) => (
          <form>
            <SmallSelect
              options={[
                { name: 'Grid', value: 'grid' },
                { name: 'Story', value: 'story' },
              ]}
              value={values.type}
              isInstagram
              onChange={handleChange}
              onBlur={e => { setType(e.target.value) }}
              label={null}
              InputProps={{
                labelWidth: 0,
                notched: false,
              }}
              style={{ width: 100, margin: '0 12px 0 0' }}
              name="type"
            />
          </form>
        )}
      </Formik>
    </ControlsWrapper>
  );
};

const InstagramImage = ({
  showControls,
  image,
  isFavorite,
  text,
  type,
  setType,
  setIsFavorite,
  setText,
  isLoading,
  index,
  onClick,
  style,
  filename,
  withLoader,
  deleteImage
}) => {
  return (
    <ImageAndTextareaWrapper>
      <ImageWrapper>
        <Image
          withLoader={withLoader}
          index={index}
          image={image}
          style={style}
          fileName={filename}
          onClick={onClick}
          description={text}
          deleteImage={deleteImage}
          Controls={() => (
            <InstagramControls
              isLoading={isLoading}
              isFavorite={isFavorite}
              type={type}
              showControls={showControls}
              setIsFavorite={setIsFavorite}
              setType={setType}
            />
          )}
        />
      </ImageWrapper>
      {isFavorite && !isLoading && (
        <Formik
          initialValues={{ text: text || '' }}
        >
          {({ values, handleChange }) => (
            <form style={{ marginTop: 40, marginLeft: 15}}>
              <TextField
                InputLabelProps={{ shrink: true }}
                id='text'
                multiline
                fontSize={14}
                rows={8}
                name='text'
                label={values.text ? 'Instagram Handles' : '' }
                value={values.text}
                style={{ width: 225 }}
                onChange={handleChange}
                onBlur={(e) => { setText(e.target.value)}}
                placeholder={`Please insert Instagram Credits. Include Yourself. One per line.

Example:

Photography: @stylemepretty
Florals: @stylemepretty`} 
              />
            </form>
          )}
        </Formik>
      )}
    </ImageAndTextareaWrapper>
  )
}

export default InstagramImage
