import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import Button from '../../../../components/Button'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import TextField from '../../../../components/TextField'
// , MagnifyingGlass
import { GridView, ListView } from '../../../../components/Icons'
import Select from '../../../../components/Select'
import { userStatus, publications } from '../../../../constants'

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const FiltersTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.showFilters ? '35px' : 0};
`
const ViewIcons = styled.div`
`

const SubmissionFilters = styled.div`
  border-top: 1px solid #000;
  width: 100%;
  padding-top: 35px;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DropdownFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 600px;
`

const FiltersText = styled.div`
  font-family: Hind;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
`

const Filters = ({ changeView, view, setFilters, setNotFilters, filters, notFilters }) => {
  const [showFilters, setShowFilters] = useState(false)

  return (      
    <FiltersContainer>
      <FiltersTop showFilters={showFilters}>
        <Button 
          text={showFilters ? 'Hide Filters' : 'Show Filters'}
          onClick={() => setShowFilters(!showFilters)}
        />
        <ViewIcons>
          <GridView 
            style={{cursor: 'pointer'}}
            color={view === 'grid' ? '#F3DBC7' : '#EAEAEA'}
            onClick={() => changeView('grid')} scale={3}
          />
          <ListView
            style={{cursor: 'pointer', marginLeft: 20}}
            color={view === 'list' ? '#F3DBC7' : '#EAEAEA'}
            onClick={() => changeView('list')} scale={3}
          />
        </ViewIcons>
      </FiltersTop>
      {showFilters && (
        <SubmissionFilters>
          <Formik
            initialValues={{ 
              status: filters.status || '',
              publication: filters.publication || '',
              search: filters.search || ''
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <DropdownFilters>
                  <FiltersText>Sort Submissions By</FiltersText>
                  <Select
                    options={userStatus}
                    onChange={(e) => {
                      setFieldValue('status', e.target.value)
                      setFilters({...filters, status: e.target.value})
                    }}
                    label='Status'
                    name='status'
                    fullWidth
                    width={180}
                    labelWidth={42}
                    value={values.status}
                    style={{width: 180}}
                    addBlank='All Statuses'
                  />
                  <Select
                    options={publications}
                    onChange={(e) => {
                      setFieldValue('publication', e.target.value)
                      if (e.target.value) {
                        if (e.target.value === 'living') {
                          setFilters({...filters, type: 'living'})
                          setNotFilters({...notFilters, type: ''})
                        } else {
                          setNotFilters({...notFilters, type: 'living'})
                          setFilters({...filters, type: ''})
                        }
                      } else {
                        setFilters({...filters, type: ''})
                        setNotFilters({...notFilters, type: ''})
                      }
                    }}
                    label='Publication'
                    name='publication'
                    fullWidth
                    labelWidth={77}
                    
                    value={values.publication}
                    style={{width: 180}}
                    addBlank='All Publications'
                  />
                </DropdownFilters>
                {/* <TextField
                  name='search'
                  value={values.search}
                  placeholder='Search by Title'
                  onChange={(e) => {
                    setFieldValue('search', e.target.value)
                    debouncedSetFilters({ ...filters, id: e.target.value})
                  }}
                  style={{ marginTop: 0, width: 550}}
                  label='Search'
                  height={45}
                  endAdornment={<InputAdornment position="end"><MagnifyingGlass scale={4} color='#221F1F' /></InputAdornment>}
                /> */}
              </Form>
            )}
          </Formik>
        </SubmissionFilters>
      )}
    </FiltersContainer>
  )
}

export default Filters
