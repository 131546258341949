import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { get, map } from 'lodash'
import canUseDom from 'can-use-dom'
import 'swiper/swiper-bundle.min.css'
import { FreeMode, Navigation, Thumbs } from 'swiper'

if(canUseDom) require('./style.scss')

const Carousel = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className='carousel-main'
      >
        {map(images, (image, i) => (
          <SwiperSlide key={i}>
            <img src={get(image, 'path')} alt={`${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className='carousel-small'
      >
        {map(images, (image, i) => (
          <SwiperSlide key={i}>
            <img src={get(image, 'path')} alt={`${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default Carousel
