import React, { useState, useEffect } from 'react'
import axiosInstance from '../../config/axiosResolver'
import axios from 'axios'
import { pickBy, get, find } from 'lodash'
import { isAdmin } from '../../utils'
import { defaultSectionData } from '../single-submission/components/AdditionalInfo/constants'

import SubmissionsDashboard from './submissions-dashboard'

const SubmissionsDashboardContainer = (props) => {
  const { user } = props
  const [submissions, setSubmissions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({ordering: '-id', submit_email: props.match.params.email, page: page, status: props.location.search ? 'collection' : '' })
  const [notFilters, setNotFilters] = useState({ status: 'collection' })
  const [totalSubmissions, setTotalSubmissions] = useState(0)
  const [vendorUser, setVendorUser] = useState({})
  const isLbb = get(vendorUser, 'is_active', '0') !== '0'
  const isProfile = get(vendorUser, 'vendor_profile.is_active', '0') !== '0'
  const quickSubmissions = get(vendorUser, 'quick_submissions', 0)
  const canQuickSubmit = (isLbb || isProfile) && quickSubmissions > 0
  const totalQuickSubmissions = get(vendorUser, 'quick_submissions', 0)

  if (!isAdmin(user) && props.match.params.email !== user.email) props.history.push(`/submissions/dashboard/${user.email}`)

  useEffect(() => {
    const fetchData = () => {	
      const query = Object.keys(pickBy(filters)).map(key => key + '=' + filters[key]).join('&');
      const notQuery = Object.keys(pickBy(notFilters)).map(key => `${key}!=${notFilters[key]}`).join('&');
      axiosInstance
        .get(`/submissions/?${query}&${notQuery}`)
        .then((result) => {
          setSubmissions(result.data.results)
          setIsLoading(false)
          setTotalSubmissions(result.data.count)
          axios
            .get(`https://www.stylemepretty.com/api/v2/vendor/user/${props.match.params.email}`)
            .then((res) => {
              setVendorUser(res.data.data)
            })
        })
    }

    fetchData()
  }, [filters, notFilters, props.match.params.email])

  const createSubmission = (data) => {
    let id
    axiosInstance
      .post(`/submissions/`, {
        title: data.title,
        type: data.type,
        status: data.status
      })
      .then((result) => {
        id = result.data.id
        if (!isAdmin(user) && result.data.id > 358705 && data.type !== 'other') {
          axios
            .post(`https://www.stylemepretty.com/api/v2/submissions/${result.data.id}/save-additional-info`, {
              data: {
                type: 'default',
                leadImage: null,
                sections: get(find(defaultSectionData, ['type', get(data, 'type')]), 'sections', [])
              }
            })
        }
        if (data.workshopHost) {
          axios.post(`https://www.stylemepretty.com/api/v2/submissions/${result.data.id}/set-workshop-data`, { host: data.workshopHost })
        }
      })
      .finally(() => {
        props.history.push(`/submissions/submission/${id}`)
      })
  }

  const createQuickSubmission = (data) => {
    let id
    axiosInstance
      .post(`/submissions/`, {
        title: data.title,
        type: data.type,
        description: `${data.gallery}${data.password ? ': ' + data.password : ''}`,
        status: 'quick'
      })
      .then((result) => {
        id = result.data.id
        if (result.data.id > 358705 && data.type !== 'other') {
          axios
            .post(`https://www.stylemepretty.com/api/v2/submissions/${result.data.id}/save-additional-info`, {
              data: {
                type: 'default',
                leadImage: null,
                sections: get(find(defaultSectionData, ['type', get(data, 'type')]), 'sections', [])
              }
            })
        }
        if (data.workshopHost) {
          axios.post(`https://www.stylemepretty.com/api/v2/submissions/${result.data.id}/set-workshop-data`, { host: data.workshopHost })
        }
        axios.post(`https://www.stylemepretty.com/api/v2/submissions/quick/decrement`, { id: get(vendorUser, 'id') })
      })
      .finally(() => {
        props.history.push(`/submissions/submission/${id}`)
      })
  }

  return (
    <SubmissionsDashboard
      submissions={submissions}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      user={user}
      setFilters={setFilters}
      setNotFilters={setNotFilters}
      filters={filters}
      notFilters={notFilters}
      totalSubmissions={totalSubmissions}
      createSubmission={createSubmission}
      createQuickSubmission={createQuickSubmission}
      vendorUser={vendorUser}
      history={props.history}
      canQuickSubmit={canQuickSubmit}
      totalQuickSubmissions={totalQuickSubmissions}
    />
  )
}

export default SubmissionsDashboardContainer

