import React from 'react'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'
import { map, indexOf } from 'lodash'
import ErrorBoundary from '../ErrorBoundary'

function renderInput (inputProps) {
  const { value, onChange, chips, ref, renderInput, ...other } = inputProps

  return (
    <ChipInput
      clearInputValueOnChange
      variant='outlined'
      blurBehavior='ignore'
      onUpdateInput={onChange}
      value={map(chips, 'name')}
      renderinput={renderInput}
      inputRef={ref}
      {...other}
      fullWidth
    />
  )
}

function renderSuggestion (suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)

  return (
    <MenuItem
      selected={isHighlighted}
      component='div'
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>
              {part.text}
            </span>
          )
        })}
      </div>
    </MenuItem>
  )
}

function renderSuggestionsContainer (options) {
  const { containerProps, children } = options

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

function getSuggestionValue (suggestion) {
  return { name: suggestion.name, id: suggestion.id }
}

function getSuggestions (value, options) {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  
  return inputLength === 0
    ? []
    : options.filter(suggestion => {
      const keep = suggestion.name.toLowerCase().slice(0, inputLength) === inputValue

      return keep
    })
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    marginBottom: 20,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 3,
    maxHeight: 250,
    overflowY: 'auto',
    borderRadius: 3,
    marginLeft: -1
  },
  suggestion: {
    display: 'block',
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Hind'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%',
    fontFamily: 'Hind',
    fontSize: 16,
    fontWeight: 300,
    backgroundColor: '#FFFFFF',
  }
})

class ChipSelect extends React.Component {
  state = {
    value: this.props.initialvalue || [],
    suggestions: [],
    options: this.props.options,
    textFieldInput: ''
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.state.options)
    })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  };

  handletextFieldInputChange = (event, { newValue }) => {
    const val = typeof newValue === 'object' ? newValue.name : newValue

    this.setState({
      textFieldInput: val
    })
  };

  handleAddChip (chip) {
    if (this.props.allowDuplicates || indexOf(map(this.state.value, 'id'), chip.id) < 0) {
      this.setState({
        value: [...this.state.value, chip], 
        textFieldInput: ''
      }, () => {
        this.props.onAdd(this.props.name, map(this.state.value, 'id'))
      })
    } else {
      this.setState({
        textFieldInput: ''
      })
    }
  }

  handleDeleteChip (chip, index) {
    this.setState(({ value }) => {
      const temp = value.slice()
      temp.splice(index, 1)
      return {
        value: temp
      }
    }, () => {
      this.props.onAdd(this.props.name, map(this.state.value, 'id'))
    })
  };

  render () {
    const { classes, options, style, ...other } = this.props
    return (
      <ErrorBoundary>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            input: classes.textField

          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={(e, { suggestionValue }) => { this.handleAddChip(suggestionValue); e.preventDefault() }}
          focusInputOnSuggestionClick={false}
          inputProps={{
            chips: this.state.value,
            value: this.state.textFieldInput,
            onChange: this.handletextFieldInputChange,
            onAdd: (chip) => this.handleAddChip(chip),
            onDelete: (chip, index) => this.handleDeleteChip(chip, index),
            ...other
          }}
          style={style}
        />
      </ErrorBoundary>
    )
  }
}

export default withStyles(styles)(ChipSelect)