import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from '../../../../../../components/Modal'
import { Arrow } from '../../../../../../components/Icons'
import { imageUrl, getImageWidth } from '../../../../../../utils'
import ErrorBoundary from '../../../../../../components/ErrorBoundary'
import Button from '../../../../../../components/Button'
import TextField from '../../../../../../components/TextField'
import ConfigResolver from '../../../../../../config/ConfigResolver'
import { Formik } from 'formik'

const config = ConfigResolver.config()

const ImageModalContainer = styled.div`
  width: 1120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px 20px;
`

const ImageSliderContainer = styled.div`
  width: 670px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const SidebarContainer = styled.div`
  width: 380px;
  align-self: flex-start;
`

const SidebarHeader = styled.div`
  font-family: Hind;
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 40px;
`

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`

const DownloadType = styled.span`
  font-family: Hind;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;
  font-weight: 300;
  margin-top: 15px;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Image = styled.div`
  width: 555px;
  height: 555px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const Filename = styled.div`
  font-family: Hind;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;
  font-weight: 300;
  margin-top: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
`


const downloadImage = (image, type) => {
  const imageUrlPrefix = config.s3Url
  let downloadUrl
  if (type === 'social') {
    getImageWidth(`${imageUrlPrefix}/${image.path}`).then((res) => {
      if (res <= 1024) downloadUrl = `${imageUrlPrefix}/${image.path}`
      else downloadUrl = `${imageUrl(image.path, res >= 2048 ? 2048 : 1024)}?download=true`
      let a = document.createElement('a')
      a.href = downloadUrl
      a.target = '_blank'
      a.setAttribute('download', true)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a);
    })
  } else {
    downloadUrl = `${imageUrlPrefix}/${image.path}`
    let a = document.createElement('a')
    a.href = downloadUrl
    a.target = '_blank'
    a.setAttribute('download', true)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a);
  }
}

const ImageModal = ({ images, initialIndex, open, handleClose, user, updateImageCredit }) => {
  const [imageIndex, setImageIndex] = useState(initialIndex)
  const [image, setImage] = useState({
    vendorName: images[initialIndex].credit_name || '',
    vendorUrl: images[initialIndex].credit_url || ''
  })
  const firstImage = imageIndex === 0
  const lastImage = imageIndex === images.length - 1
  const handleArrowKeys = (e) => {
    if (e.keyCode === 37 && !firstImage) {
      setImageIndex(imageIndex - 1)
    } else if (e.keyCode === 39 && !lastImage) {
      setImageIndex(imageIndex + 1)
    }
  }

  if(!open) {
    return ( 
      <div />
    )
  }

  return (
    <ErrorBoundary>
      <Modal 
        open={open}
        handleClose={handleClose}
        onKeyDown={handleArrowKeys}
      >
        <ImageModalContainer>
          <ImageSliderContainer>
            <Arrow 
              style={{ transform: 'rotate(90deg)', cursor: firstImage ? 'initial' : 'pointer' }}
              color={firstImage ? '#FFFFFF' : '#000000'}
              scale={3}
              onClick={() => {
                setImageIndex(firstImage ? imageIndex : imageIndex - 1)
                setImage({
                  vendorName: images[firstImage ? imageIndex : imageIndex - 1].credit_name || '',
                  vendorUrl: images[firstImage ? imageIndex : imageIndex - 1].credit_url || ''
                })
              }}
            />
            <ImageContainer>
              <Image style={{ backgroundImage: `url(${imageUrl(images[imageIndex].path, 555)})`}} />
              <Filename>{images[imageIndex].original_filename}</Filename>
            </ImageContainer>
            <Arrow 
              style={{ transform: 'rotate(270deg)', cursor: lastImage ? 'initial' : 'pointer' }}
              color={lastImage ? '#FFFFFF' : '#000000'}
              scale={3}
              onClick={() => {
                setImageIndex(lastImage ? imageIndex : imageIndex + 1)
                setImage({
                  vendorName: images[lastImage ? imageIndex : imageIndex + 1].credit_name || '',
                  vendorUrl: images[lastImage ? imageIndex : imageIndex + 1].credit_url || ''
                })
              }}
            />
          </ImageSliderContainer>
          <SidebarContainer>
            <SidebarHeader>Download Image</SidebarHeader>
            <DownloadContainer>
              <DownloadType>Social:</DownloadType>
              <Button
                text='Download'
                onClick={() => downloadImage(images[imageIndex], 'social')}
              />
            </DownloadContainer>
            <DownloadContainer>
              <DownloadType>Original:</DownloadType>
              <Button
                text='Download'
                onClick={() => downloadImage(images[imageIndex], 'original')}
              />
            </DownloadContainer>
            <CreditContainer>
              <SidebarHeader>Photographer Credit</SidebarHeader>
              <Formik
                enableReinitialize={true}
                initialValues={image}
                onSubmit={(values) => updateImageCredit(values, images[imageIndex].id)}>
                {({ values, handleChange, handleSubmit }) => (
                  <form>
                    <TextField
                      id='vendorName'
                      name='vendorName'
                      style={{ width: 380, marginBottom: 20 }}
                      label='Vendor Name'
                      variant='outlined'
                      onChange={handleChange}
                      value={values.vendorName}
                      fullWidth
                      height={46}
                    />
                    <TextField
                      id='vendorUrl'
                      name='vendorUrl'
                      style={{ width: 380, marginBottom: 10 }}
                      label='Vendor Url'
                      variant='outlined'
                      onChange={handleChange}
                      value={values.vendorUrl}
                      fullWidth
                      height={46}
                    />
                    <Button
                      text={images[imageIndex].credit_name === values.vendorName && images[imageIndex].credit_url === values.vendorUrl ? 'Saved!' : 'Save Credit'}
                      onClick={handleSubmit}
                      style={{ float: 'right', marginRight: 0, display: 'block', marginTop: 10 }}
                      disabled={images[imageIndex].credit_name === values.vendorName && images[imageIndex].credit_url === values.vendorUrl}
                    />
                  </form>
                )}
              </Formik>
            </CreditContainer>
          </SidebarContainer>
        </ImageModalContainer>
      </Modal>
    </ErrorBoundary>
  )
}

export default ImageModal