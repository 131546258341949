import { get, map } from 'lodash'
import React from 'react'
import Placement from './components/Placement'
import canUseDom from 'can-use-dom'

if (canUseDom) require('./style.scss')

function ImagePlacements (props) {

  const { additionalInfo, gallery, saveSubmission } = props;

  return (
    <div className='image-placements'>
      <Placement
        lead
        additionalInfo={additionalInfo}
        saveSubmission={saveSubmission}
        gallery={gallery}
      />
      {map(get(additionalInfo, 'sections'), (section, index) => map(get(section, 'images'), (imageSection, i) => (
        <Placement
          additionalInfo={additionalInfo}
          saveSubmission={saveSubmission}
          key={`${index}-${i}`}
          sectionIndex={index}
          imageIndex={i}
          gallery={gallery}
        />
      )))}
    </div>
  )
}

export default ImagePlacements
